import React, { useState } from "react";
import "../../css/form.css";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { USERCHECK, CHECKADMIN } from "../../../function/auth";
import { useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import walletimg from "../../images/walletimg.png";
import { toast } from "react-toastify";

import {
  GETNETTOTALAF,
  GETTOTALCARDPURCHASEAF,
  GETTOTALPAYPALPURCHASEAF,
  GETTOTALBANKPURCHASE,
  GETTOTALPAYPALPURCHASE,
  GETWITHOLDINGBALANCE,
  GETCASHOUTFEESBALANCE,
  GETTRANSACTIONFEESBALANCE,
  GETPAYPALFEESBALANCE,
  getcardfeesbalance,
  GETBANKTRANSFERFEESBALANCE,
  GETPAYPALBALANCE,
  getbanktrasnferbalance,
  getcreditibalance,
  getcryptobalance,
} from "../../../function/orders";
import { Checkbox } from "antd";
import {
  CREATEBANKTRANSACTION,
  CREATECREDITTRANSACTION,
  createCryptoTransAction,
  createPaypalTransAction,
} from "../../../function/admintransaction";
import { CREATEADMINCASHOUT } from "../../../function/admincashout";
const AdminWallet = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [balance, setBalance] = useState();
  const [totalBankPurchase, setTotalBankPurchase] = useState();
  const [totalCardPurchase, setTotalCardPurchase] = useState();
  const [totalPaypalPurchase, setTotalPaypalPurchase] = useState();
  const [pPAF, setPPAF] = useState();
  const [netTotal, setNettotal] = useState();
  const [withholdingbalance, setWithholdingbalance] = useState();
  const [cashoutfeesbalance, setCashoutfeesbalance] = useState();
  const [transactionfeesbalance, setTransactionfeesbalance] = useState();
  const [paypalfeesbalance, setPaypalfeesbalance] = useState();
  const [cardfeesbalance, setCardfeesbalance] = useState();
  const [banktransferfeesbalance, setBanktransferfeesbalance] = useState();
  const [transfrom, setTransfrom] = useState("creditbalance");
  const [transto, setTransto] = useState("select");
  const [paypalbalance, setPaypalbalance] = useState();
  const [creditbalance, setCreditbalance] = useState();
  const [banktransferbalance, setBanktransferbalance] = useState();
  const [transactionamount, setTransactionamount] = useState();
  const [transactionfees, setTransactionfees] = useState();
  const [cryptocurrencybalance, setCryptocurrencybalance] = useState();
  const [cashfrom, setCashfrom] = useState("creditbalance");
  const [cashoutamount, setCashoutamount] = useState();
  const [bankbalance, setBankbalance] = useState();
  const [transactionfinalamount, setTransactionfinalamount] = useState();
  const [spInternalEur, setSpInternalEur] = useState();
  const [adminbalance, setAdminbalance] = useState();
  useEffect(() => {
    // we rpleace CHECKUSER(user.email) to CHECKADMIN(user.token)
    CHECKADMIN(user.token).then((res) => {
      setBalance(res.data.balance.toFixed(2));
      setAdminbalance(res.data.adminbalance.toFixed(2));
      setSpInternalEur(res.data.eurinternalfees.toFixed(2));
    });
    load();
  }, [adminbalance]);

  const load = () => {
    // we rpleace CHECKUSER(user.email) to CHECKADMIN(user.token)
    CHECKADMIN(user.token).then((res) => {
      setBalance(res.data.balance.toFixed(2));
      setAdminbalance(res.data.adminbalance.toFixed(2));
    });

    getcryptobalance(user.token).then((res) => {
      setCryptocurrencybalance(res.data.cryptocurrencybalance.toFixed(2));
    });
    getcreditibalance(user.token).then((res) => {
      setCreditbalance(res.data.creditbalance.toFixed(2));
    });
    getbanktrasnferbalance(user.token).then((res) => {
      setBankbalance(res.data.bankbalance.toFixed(2));
    });
    GETPAYPALBALANCE(user.token).then((res) => {
      setPaypalbalance(res.data.paypalbalance.toFixed(2));
    });
    GETBANKTRANSFERFEESBALANCE(user.token).then((res) => {
      setBanktransferfeesbalance(res.data.banktransferbalance.toFixed(2));
    });
    getcardfeesbalance(user.token).then((res) => {
      setCardfeesbalance(res.data.cardfeesbalance.toFixed(2));
    });
    GETPAYPALFEESBALANCE(user.token).then((res) => {
      setPaypalfeesbalance(res.data.paypalfeesbalance.toFixed(2));
    });
    GETTRANSACTIONFEESBALANCE(user.token).then((res) => {
      setTransactionfeesbalance(res.data.transactionfeesbalance.toFixed(2));
    });

    GETCASHOUTFEESBALANCE(user.token).then((res) => {
      setCashoutfeesbalance(res.data.cashoutfeesbalance.toFixed(2));
    });

    GETWITHOLDINGBALANCE(user.token).then((res) => {
      setWithholdingbalance(res.data.withholdingbalance.toFixed(2));
    });
    GETNETTOTALAF(user.token).then((res) => {
      setNettotal(res.data.toFixed(2));
    });
    GETTOTALCARDPURCHASEAF(user.token).then((res) => {
      setTotalCardPurchase(res.data.toFixed(2));
    });
    GETTOTALPAYPALPURCHASEAF(user.token).then((res) => {
      setPPAF(res.data.toFixed(2));
    });
    GETTOTALBANKPURCHASE(user.token).then((res) => {
      setTotalBankPurchase(res.data.toFixed(2));
      // console.log("totalBankPurchase",totalBankPurchase)
    });
    GETTOTALPAYPALPURCHASE(user.token).then((res) => {
      setTotalPaypalPurchase(res.data.toFixed(2));
      //  console.log("totalBankPurchase",res.data)
    });
  };

  const hadnleCheckcashout = (e) => {
    //   if(cashfrom==="creditbalance"){
    //     if(Number(cashoutamount)>creditbalance){
    //        toast.error("the amount is bigger than Your Credit Balance")
    //          return
    //     }else{
    //       CREATEADMINCASHOUT(user.token,cashfrom,cashoutamount).then((res)=>{
    //         if(res.data.ok===true){
    //     toast.success(`Cashout ${cashoutamount} from Credit  successeedd`)
    //           load()
    //         }
    //       })

    //     }
    //   }
    //   if(cashfrom==="paypalbalance"){
    //     if(Number(cashoutamount)>paypalbalance){
    //        toast.error("the amount is bigger than Your paypal Balance")
    //          return
    //     }else{
    //       CREATEADMINCASHOUT(user.token,cashfrom,cashoutamount).then((res)=>{
    //         if(res.data.ok===true){
    //     toast.success(`Cashout ${cashoutamount} from Paypal  successeedd`)
    //           load()
    //         }
    //       })
    //     }
    //   }
    //     if(cashfrom==="bankbalance"){
    //     if(Number(cashoutamount)>bankbalance){
    //        toast.error("the amount is bigger than Your banktransfer Balance")
    //          return
    //     }else{
    //       CREATEADMINCASHOUT(user.token,cashfrom,cashoutamount).then((res)=>{
    //         if(res.data.ok===true){
    //     toast.success(`Cashout ${cashoutamount} from Bank  successeedd`)
    //           load()
    //         }
    //       })
    //     }
    //   }
    // if(cashfrom==="cryptocurrencybalance"){
    //   if(Number(cashoutamount)>cryptocurrencybalance){
    //      toast.error("the amount is bigger than Your Crypto Balance")
    //        return
    //   }else{
    //     CREATEADMINCASHOUT(user.token,cashfrom,cashoutamount).then((res)=>{
    //       if(res.data.ok===true){
    //   toast.success(`Cashout €{cashoutamount} from Crypto  successeedd`)
    //         load()
    //       }
    //     })
    //   }
    // }

    CREATEADMINCASHOUT(user.token, cashoutamount).then((res) => {
      if (res.data.ok === true) {
        toast.success(`Cashout  successeedd`);
        // we rpleace CHECKUSER(user.email) to CHECKADMIN(user.token)
        CHECKADMIN(user.token).then((res) => {
          setBalance(res.data.balance.toFixed(2));
          setAdminbalance(res.data.adminbalance.toFixed(2));
        });
      }
    });
  };

  const hadnleCashoutAmount = (e) => {
    setCashoutamount(e.target.value);
  };

  const handleFromTrans = (e) => {
    setTransfrom(e.target.value);
  };
  const handleToTrans = (e) => {
    setTransto(e.target.value);
  };
  const handleFromCashout = (e) => {
    setCashfrom(e.target.value);
  };

  const handleCheckTransaction = () => {
    if (transfrom === "creditbalance") {
      if (Number(transactionamount) > Number(creditbalance)) {
        toast.error("the amount is bigger than Your Credit Balance");
        return;
      } else {
        CREATECREDITTRANSACTION(
          user.token,
          transto,
          transactionamount,
          transactionfees
        ).then((res) => {
          if (res.data.ok === true) {
            toast.success("TransAction Successeedd!");
            setTransto("select");
            load();
          } else {
            toast.error("Somthing went wrong");
          }
        });
      }
    }
    if (transfrom === "paypalbalance") {
      if (Number(transactionamount) > Number(paypalbalance)) {
        toast.error("the amount is bigger than Your Paypal Balance");
        return;
      } else {
        createPaypalTransAction(
          user.token,
          transto,
          transactionamount,
          transactionfees
        ).then((res) => {
          if (res.data.ok === true) {
            toast.success("TransAction Successeedd!");
            load();
            setTransto("select");
          } else {
            toast.error("Somthing went wrong");
          }
        });
      }
    }
    if (transfrom === "bankbalance") {
      if (Number(transactionamount) > bankbalance) {
        toast.error("the amount is bigger than Your Bank transfer Balance");
        return;
      } else {
        CREATEBANKTRANSACTION(
          user.token,
          transto,
          transactionamount,
          transactionfees
        ).then((res) => {
          if (res.data.ok === true) {
            toast.success("TransAction Successeedd!");
            load();
            setTransto("select");
          } else {
            toast.error("Somthing went wrong");
          }
        });
      }
    }
    if (transfrom === "cryptocurrencybalance") {
      if (Number(transactionamount) > cryptocurrencybalance) {
        toast.error("the amount is bigger than Your Crypto Balance");
        return;
      } else {
        createCryptoTransAction(
          user.token,
          transto,
          transactionamount,
          transactionfees
        ).then((res) => {
          if (res.data.ok === true) {
            toast.success("TransAction Successeedd!");
            load();
            setTransto("select");
          } else {
            toast.error("Somthing went wrong");
          }
        });
      }
    }
  };

  const handleTransactionamount = (e) => {
    setTransactionamount(e.target.value);
  };
  const handleTransactionfees = (e) => {
    setTransactionfees(e.target.value);
  };
  const totalAmount =
    Number(paypalbalance) + Number(creditbalance) + Number(bankbalance);
  return (
    <div className="container-fluid ">
      <div
        class="modal fade"
        id="confirm"
        tabindex="-1"
        aria-labelledby="role"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="role">
                Admin Transaction confimation
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <label>
                Are You Sure You Want to Transfer{" "}
                <b className="text-primary"> €{transactionamount}</b> from
                <b className="text-dark"> {transfrom}</b> to{" "}
                <b className="text-dark"> {transto}</b> with
                <b className="text-warning"> €{transactionfees}</b> fees{" "}
              </label>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
              >
                No{" "}
              </button>
              <button
                type="button"
                class="btn btn-success"
                data-bs-dismiss="modal"
                onClick={handleCheckTransaction}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <h1 className="mt-5 ">wallet</h1>
        <div className=" text-capitalize h3">
          Total Net Balance:
          {/* <b className="text-primary">€{withholdingbalance?netTotal-withholdingbalance:netTotal||0}</b> */}
          <b className="text-primary">€{totalAmount.toFixed(2) || 0}</b>
          <div className="float-start">
            <img width={"70px"} src={walletimg} />
          </div>
        </div>
        {/* <div className=" col-10 col-sm-8  col-md-3   mt-5"> */}

        {/* <Card 
           className="headCards wcrypto"
            style={{ textAlign:"",marginTop:"15px" }}
          >
            <Card.Body>
                 
              <Card.Title > <h5 className="cryptocolor text-center">cryptocurrency Balance</h5></Card.Title>
            
              <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
              <Card.Text > 
              
                  
                <div className="container ">
               
                 <h3 className="cryptocolor text-center">  €{cryptocurrencybalance||0}</h3>
            
                </div>
            </Card.Text>
          
            </Card.Body>
           
          </Card> */}
        {/* </div> */}

        <div className=" col-10 col-sm-8  col-md-4   mt-5">
          <Card
            className="headCards wcredit"
            style={{ textAlign: "", marginTop: "15px" }}
          >
            <Card.Body>
              <Card.Title>
                {" "}
                <h5 className="text-dark text-center">Credit Balance</h5>
              </Card.Title>

              <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
              <Card.Text>
                <div className="container ">
                  <h3 className="text-center"> €{creditbalance || 0}</h3>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className=" col-10 col-sm-8  col-md-4   mt-5">
          <Card
            className="headCards wbank"
            style={{ textAlign: "", marginTop: "15px" }}
          >
            <Card.Body>
              <Card.Title>
                <h5 className="text-success text-center">Bank Balance</h5>{" "}
              </Card.Title>

              <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
              <Card.Text>
                <div className="container ">
                  <h3 className="text-success text-center">
                    €{bankbalance || 0}
                  </h3>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div className=" col-10 col-sm-8  col-md-4   mt-5">
          <Card
            className="headCards wpaypal"
            style={{ textAlign: "", marginTop: "15px" }}
          >
            <Card.Body>
              <Card.Title>
                {" "}
                <h5 className="text-primary text-center"> Paypal Balance</h5>
              </Card.Title>

              <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
              <Card.Text>
                <div className="container ">
                  <h3 className="text-center">€{paypalbalance || 0}</h3>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-md-5">
          <Card
            className="OrdersSummary2 "
            style={{ textAlign: "", marginTop: "15px" }}
          >
            <Card.Body>
              <Card.Title>
                <div className=" text-capitalize text-center">
                  {" "}
                  <b className="text-danger">Fees</b> balances{" "}
                  <div className="float-end"></div>
                </div>{" "}
              </Card.Title>

              <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
              <Card.Text>
                <div className="container ">
                  <h5 className=" mt-1"></h5>

                  <div className=" mt-2">
                    <h3 className=" text-capitalize">
                      {" "}
                      Total Fees Balance:
                      <b className="text-primary">
                        €{balance ? balance : "No data for now"}
                      </b>
                    </h3>
                    <h5>
                      cashout fees balance :
                      <b className="text-primary">€{cashoutfeesbalance || 0}</b>
                    </h5>
                    <h5>
                      transaction fees balance :
                      <b className="text-primary">
                        €{transactionfeesbalance || 0}
                      </b>
                    </h5>
                    <h5>
                      paypal fees balance :
                      <b className="text-primary">€{paypalfeesbalance || 0}</b>
                    </h5>
                    <h5>
                      card fees balance :
                      <b className="text-primary">€{cardfeesbalance || 0}</b>
                    </h5>
                    <h5>
                      banktransfer fees balance :
                      <b className="text-primary">
                        €{banktransferfeesbalance || 0}
                      </b>
                    </h5>
                    <h5>
                      sp Internal Fee Eur :
                      <b className="text-primary">€{spInternalEur || 0}</b>
                    </h5>
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-5">
          <Card
            className="OrdersSummary3 "
            style={{ textAlign: "", marginTop: "15px" }}
          >
            <Card.Body>
              <Card.Title>
                <div className=" text-capitalize text-center h3">
                  <b className="text-success">Purchase</b> Balances{" "}
                  <div className="float-end"></div>
                </div>{" "}
              </Card.Title>

              <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
              <Card.Text>
                <div className="container ">
                  <h5 className=" mt-1"></h5>

                  <div className=" mt-2">
                    <h4>
                      net total payments purchase :
                      <b className="text-primary">€{netTotal || 0}</b>
                    </h4>
                    <h4>
                      holding balance :
                      <b className="text-primary">€{withholdingbalance || 0}</b>
                    </h4>

                    <h4>
                      Total BankTransfre purchase :
                      <b className="text-primary">€{totalBankPurchase || 0}</b>
                    </h4>
                    <h4>
                      Total card purchase :
                      <b className="text-primary"> €{totalCardPurchase || 0}</b>
                    </h4>
                    <h4>
                      Total Paypal purchase :
                      <b className="text-primary"> €{pPAF || 0}</b>
                    </h4>
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className=" col-10 col-sm-8  col-md-4   mt-5">
          <div className="headCards p-5 ">
            <h3 className="text-center">Cashout</h3>
            <div>
              balance <b>{adminbalance}</b>
              {/* <select class="form-select form-select-sm" aria-label=".form-select-sm example"
             onChange={handleFromCashout} value={cashfrom} >
  <option  selected value={"creditbalance"}>Credit</option>
  <option value={"paypalbalance"}>Paypal</option>
  <option value={"bankbalance"}>Bank Transfer</option>
 <option value={"cryptocurrencybalance"}>Crypto</option>
</select> */}
            </div>
            <div className="mt-3">
              Amount
              <input
                type="number"
                className="form-control"
                value={cashoutamount}
                onChange={hadnleCashoutAmount}
              />
            </div>

            <div className="mt-3">
              <button
                onClick={hadnleCheckcashout}
                disabled={cashoutamount <= 0}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        {/* down for trans */}
        <div className=" col-10 col-sm-8  col-md-4   mt-5">
          <div className="headCards p-5 ">
            <h3 className="text-center">TransAction</h3>
            <div>
              From
              <select
                class="form-select form-select-sm"
                aria-label=".form-select-sm example"
                onChange={handleFromTrans}
                value={transfrom}
              >
                <option selected value={"creditbalance"}>
                  Credit
                </option>
                <option value={"paypalbalance"}>Paypal</option>
                <option value={"bankbalance"}>Bank Transfer</option>
                {/* <option value={"cryptocurrencybalance"}>Crypto</option> */}
              </select>
            </div>
            <div className="mt-3">
              Amount
              <input
                type="number"
                className="form-control"
                value={transactionamount}
                onChange={handleTransactionamount}
              />
            </div>
            <div className="mt-3">
              Fees
              <input
                type="number"
                className="form-control"
                value={transactionfees}
                onChange={handleTransactionfees}
              />
            </div>
            <div>
              To
              <select
                value={transto}
                onChange={handleToTrans}
                class="form-select form-select-sm"
                aria-label=".form-select-sm example"
              >
                <option disabled value="select">
                  Select
                </option>

                {/* {transfrom==="cryptocurrencybalance" ?"":(  <option value={"cryptocurrencybalance"}>Crypto</option>)
}  */}
                {transfrom === "paypalbalance" ? (
                  ""
                ) : (
                  <option value={"paypalbalance"}>Paypal</option>
                )}
                {transfrom === "creditbalance" ? (
                  ""
                ) : (
                  <option selected value={"creditbalance"}>
                    Credit
                  </option>
                )}
                {transfrom === "bankbalance" ? (
                  ""
                ) : (
                  <option value={"bankbalance"}>Bank Transfer</option>
                )}
              </select>
            </div>
            <div className="mt-3">
              <button
                data-bs-toggle="modal"
                data-bs-target="#confirm"
                disabled={
                  transto === "select" ||
                  transactionamount <= 0 ||
                  Number(transactionfees) > Number(transactionamount)
                }
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <h5 className="text-dark pt-1  ms-5 text-start">
        see your account information in here!<Link to="/profile">profile</Link>
      </h5>
    </div>
  );
};
export default AdminWallet;
