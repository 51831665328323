



import { Link } from "react-router-dom";
import { AudioOutlined } from '@ant-design/icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { Input, Space } from 'antd';
import React from 'react';
import Button from "react-bootstrap/esm/Button";
import { useEffect } from "react";
import { Pagination } from 'antd';
import { GETCASHOUTREQUEST, UPDATESTATUS,CANCELEDCASHOUTREQUEST, CREATECAHSOUTREQCRYPTO,
   UPDATESTATUSCRYPTO, ProcessingCASHOUTREQUEST, createandupdaterejectreason, cashoutrequesttotal, GETCASHOUTREQUESTcrypto, cashoutrequesttotalcrypto } from "../../../function/cashout";
import { useSelector } from "react-redux";
import { useState } from "react";
import moment from "moment";
import { BiHandicap } from "react-icons/bi";
import { toast } from "react-toastify";
import '../../css/cashout.css'
import Table from 'react-bootstrap/Table';
import LocalSearch from "../../search/localsearch";
import crypto from '../../images/cryptocurrency.png'
 import cashoutimg from '../../images/cashoutimg.png'
  import {CloudDownloadOutlined,EyeOutlined}from '@ant-design/icons';
import { GETBANKTRANSFERFEESBALANCE, getbanktrasnferbalance, getcryptobalance } from "../../../function/orders";
const AdminCacheOutcrypto = () =>{
  const [requestStatus,setRequestStatus]=useState('')
const [requests,setRequests]=useState([])
    const [page, setPage] = useState(1)
const [rB,setRB]=useState([])
let {user}=useSelector((state)=>({...state}))
  const[rejectreason,setRejectreason]=useState('')
    const[rejectId,setRejectId]=useState()
const [searchText,setSearchText]=useState('')
const[cryptocurrencybalance,setCryptocurrencybalance]=useState()
const[bankbalance,setBankbalance]=useState()
const[count,setCount]=useState()
 const { Search } = Input;


 const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
    }}
  />
);


  const handleRejectreasonf=()=>{

    console.log(rejectId)
      console.log(rejectreason)
  createandupdaterejectreason(user.token,rejectId,rejectreason).then((res)=>{
   toast.success("Reason Created Success")
    })
  }
// const onSearch = (value) => console.log(value);

 const handlerejectreason=(e)=>{
  setRejectreason(e.target.value)
 }

useEffect(()=>{
loadAllRequests()

  },[page])
 
 const loadAllRequests=()=>{
  cashoutrequesttotalcrypto().then((res)=>{
    setCount(res.data)
  })
  getcryptobalance(user.token).then((res)=>{
       setCryptocurrencybalance(res.data.cryptocurrencybalance.toFixed(2))
    })
 getbanktrasnferbalance(user.token).then((res)=>{
      setBankbalance(res.data.bankbalance.toFixed(2))
    })
  GETCASHOUTREQUESTcrypto(user.token,'createdAt', 'desc', page).then((res)=>{
//  console.log("res.data.reqb",res.data.requestedBy)
setRequests(res.data)
{res.data.map((rd)=>{
   setRB(rd.requestedBy)
  // console.log("res.data.map",rd.requestedBy)
})}
  })
 }


 const handleStatusChange = (
   _id,
   requestStatus,
   cashoutcryptonumber,
   cashoutamount,
 
 ) => {
   //  console.log(cashoutamount)

   if (requestStatus === "Processing") {
     ProcessingCASHOUTREQUEST(user.token, _id).then((res) => {
       if (res.data.ok === true) {
         toast.success("status updated!");
         loadAllRequests();
       }
     });
   }
   if (requestStatus === "Cancelled") {
     CANCELEDCASHOUTREQUEST(user.token, _id).then((res) => {
       toast.success("status updated!");
       loadAllRequests();
     });
   }

   if (requestStatus === "Completed" && cashoutcryptonumber) {
     if (Number(cashoutamount) > Number(cryptocurrencybalance)) {
       toast.error("cashout amount is bigger than your crypto  balance");
     } else {
       CREATECAHSOUTREQCRYPTO(
         user.token,
         cashoutcryptonumber,
         cashoutamount,
   
       ).then((res) => {
         // console.log(res.data.ok)
         if (res.data.ok === true) {
           UPDATESTATUSCRYPTO(
             user.token,
             _id,
             requestStatus,
             cashoutamount,
       
           ).then((res) => {
             // console.log(res.data)
             toast.success("status updated!");
             loadAllRequests();
           });
         } else {
           toast.error("Binance ERROR");
         }
       });
     }
   } else {
     if (requestStatus === "Completed" && !cashoutcryptonumber) {
       if (Number(cashoutamount) > Number(bankbalance)) {
         toast.error("Cshout amount is bigger than your bank balance");
       } else {
         UPDATESTATUS(user.token, _id, requestStatus, cashoutamount).then(
           (res) => {
             // console.log(res.data)
             toast.success("status updated!");
             loadAllRequests();
           }
         );
       }
     }
   }
 };
const handleChangeText=(e)=>{
  setSearchText(e.target.value)
}
   const [keyword, setKeyword] = useState("")
const search = (keyword) => (c) => c.toLowerCase().includes(keyword)
  return (
   
       <div className="row mt-5 p-2">
  {/* <OldTable/> */}
  <div class="modal fade" id="reject" tabindex="-1" aria-labelledby="role" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="role">Create Reject reason</h5>
        <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <label>Reject reason</label>
     <input type='text' className="form-control" value={rejectreason} onChange={handlerejectreason}  />
    
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" onClick={handleRejectreasonf} 
          data-bs-dismiss="modal" >Save changes</button>
      </div>
    </div>
  </div>
</div>
 <div className="container">
        <div clasName="row">
          <div className="col-12">
            <div className="card servicceProvidersCard table-responsive">
              <div className="card-header">
                <div className="row">
                  <div className="col-6">
<div ><img width={'70px'}  src={cashoutimg} />  
                <b className="h4 ms-2">Cashout crypto   <img className="w-25"  src={crypto} /> </b> 

              
                </div>
                  </div>
                  <div className="col-4">
<div className="mt-2"><LocalSearch placeholder="search By Name"  keyword={keyword} setKeyword={setKeyword} /></div>
                  </div>
                  <div className="col-2">
 <div className="float-end me-auto"> <button className=" btn btn-light-info h5">
                <CloudDownloadOutlined style={{ fontSize: '20px'}} />  Export</button></div>
                  </div>
                </div>
                <div className="row d-flex justify-content-end ">
                  {/* <div className= "  col-2 bg-light">
                    <b className="text-info">Bank Balance</b>: ${bankbalance}
                  </div> */}
                
                  <div className="  col-2 bg-light">
                    <b className="text-danger">Crypto Balance</b>: ${cryptocurrencybalance}
                  </div>
                </div>
                
              </div>
            
              <table className="serviceTable table  ">
                 <thead className="text-center">
        <tr>
            <th> Requested By</th>
            <th>Requested Date </th>
            <th className="border-info text-info"> $Total Amount</th>
            <th className="border-info text-info">Cashout Fees</th>
            <th className="border-info text-info">$Fees Amount </th>
            <th className="border-info text-info">$Cash Out Amount</th>

            {/* now in dollar */}
             {/* <th className="border-success text-success"> $Total Amount</th>
            <th className="border-success text-success">Crrency Fees</th>
            <th className="border-success text-success"
             data-toggle="tooltip" data-placement="top" title="Currency Fees Amount">$CFA </th>
                  <th className="border-success text-success"
                    data-toggle="tooltip" data-placement="top" title="Amount After Currency Fees Amount">$AACFA</th>
            <th className="border-success text-success">$Cash Out Amount</th> */}

             {/* <th >Cash Out method</th> */}
             <th>Cash Out WalletAdress</th>
              {/* <th>IBAN</th>
             
            <th>name</th> */}
       
    
     
            <th>Status</th>
              <th className="text-danger">Action</th>
        </tr>
        </thead>
         <tbody >
             {requests.map((req,index)=>{
                
                 
return (
  <>
    {req.cashouttype === "cryptocurrency" && (
      <tr className="text-center">
        <td>
          {" "}
          <Link
            key={req.requestedBy.publicid}
            to={`/user/${req.requestedBy.publicid}`}
          >
            {req.requestedBy.name}
          </Link>
        </td>
        <td>
          {" "}
          <b className="text-dark">
            {moment(req.createdAt).format("D/M/Y h:mm:ss A")}
          </b>{" "}
        </td>
        <td className=" text-info">${req.totalamount}</td>
        <td className=" text-info">{req.cashoutfees}</td>
        {req.cashoutamontfees ? (
          <td className=" text-info">${req.cashoutamontfees.toFixed(2)}</td>
        ) : (
          <td>-</td>
        )}
        <td className=" text-info">${req.cashoutamount.toFixed(2)}</td>
        {/* now in dollar */}
        {/* {req.totallamountdollar && req.cashouttype === "cryptocurrency" ? (
          <td className=" text-success">
            ${req.totallamountdollar.toFixed(2)}
          </td>
        ) : (
          <td>-</td>
        )}
        {req.currencyfees && req.cashouttype === "cryptocurrency" ? (
          <td className=" text-success">{req.currencyfees.toFixed(2)}</td>
        ) : (
          <td>-</td>
        )}
        {req.cashoutcurrencyfees && req.cashouttype === "cryptocurrency" ? (
          <td className=" text-success">
            ${req.cashoutcurrencyfees.toFixed(2)}
          </td>
        ) : (
          <td>-</td>
        )}
        {req.cashoutamountdollar && req.cashouttype === "cryptocurrency" ? (
          <td className=" text-success">
            ${req.cashoutamountdollar.toFixed(2)}
          </td>
        ) : (
          <td>-</td>
        )}
        {req.coaafid && req.cashouttype === "cryptocurrency" ? (
          <td className=" text-success">${req.coaafid.toFixed(2)}</td>
        ) : (
          <td>-</td>
        )} */}

        {/* <td>{req.cashouttype}</td> */}
        <td>
          {req.cashoutcryptonumber ? req.cashoutcryptonumber : "not Cyrpto"}
        </td>
        {/* <td>{req.cashoutbankiban?req.cashoutbankiban:"No Bank info"}</td>
            <td>{req.cashoutbankname?req.cashoutbankname:"no name"}</td> */}
        <td>
          <select
            // disabled={
            //   req.requeststatus === "Completed" ||
            //   req.requeststatus === "Cancelled"
            // }
            class="form-select form-select-sm"
            aria-label=".form-select-sm example"
            onChange={(e) =>
              handleStatusChange(
                req._id,
                e.target.value,
                req.cashoutcryptonumber,
                req.cashoutamount,
              
              )
            }
            value={requestStatus}
          >
            <option selected>{req.requeststatus}</option>
            {/* { req.requeststatus==="Not Processed"?'':(<option value={'Not Processed'}>Not Processed</option>)} */}
            {req.requeststatus === "Processing" ? (
              ""
            ) : (
              <option value={"Processing"}>Processing</option>
            )}
            {/* <option value={"Cancelled"}>Cancelled</option>  */}
            {req.requeststatus === "Cancelled" ? (
              ""
            ) : (
              <option value={"Cancelled"}>Cancelled</option>
            )}
            {req.requeststatus === "Completed" ? (
              ""
            ) : (
              <option value={"Completed"}>Completed</option>
            )}
          </select>
        </td>
        <td>
          <Dropdown>
            <Dropdown.Toggle className="monthsdropdown"></Dropdown.Toggle>

            <Dropdown.Menu className="w-100">
              {req.requeststatus === "Cancelled" ? (
                <Dropdown.Item
                  data-bs-toggle="modal"
                  data-bs-target="#reject"
                  onClick={() => setRejectId(req._id)}
                >
                  {" "}
                  Reject reason
                </Dropdown.Item>
              ) : (
                ""
              )}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )}
  </>
);
 
                   })}
       
        </tbody>
              </table>
               <div className="d-flex justify-content-center">
                        <Pagination current={page} total={Math.round((count / 12) * 10)} onChange={(value) => setPage(value)} />

            </div>
            </div>
          </div>
        </div>
      </div>
</div>
       
       
       ) 
}


export default AdminCacheOutcrypto;