import axios from "axios";

export const GETDEFAULTFEES = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/defaultfees`, {
    headers: {
      authtoken,
    },
  });
};

export const CRUDPAYPAL = async (authtoken, paypal) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/payment/paypal`,
    { paypal },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const CRUDUSBANK = async (authtoken, usbank) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/payment/usbank`,
    { usbank },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const CRUDTRBANK = async (authtoken, trbank) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/payment/trbank`,
    { trbank },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const CRUDBEBANK = async (authtoken, bebank) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/payment/bebank`,
    { bebank },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const CRUDUKBANK = async (authtoken, ukbank) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/payment/ukbank`,
    { ukbank },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const GETPAYPALINFO = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/payment/paypal`, {
    headers: {
      authtoken,
    },
  });
};
export const GETUSBANKINFO = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/payment/usbank`, {
    headers: {
      authtoken,
    },
  });
};

export const GETTRBANKINFO = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/payment/trbank`, {
    headers: {
      authtoken,
    },
  });
};
export const GETBEBANKINFO = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/payment/bebank`, {
    headers: {
      authtoken,
    },
  });
};
export const GETUKBANKINFO = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/payment/ukbank`, {
    headers: {
      authtoken,
    },
  });
};

export const GETALL = async () => {
  return await axios.get(`${process.env.REACT_APP_API}/payment`);
};

export const getclientid = async () =>
  await axios.get(`${process.env.REACT_APP_API}/getclientid`, {});
