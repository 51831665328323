import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GETAllINTERNALS } from "../../../function/internal";
import Table from "react-bootstrap/Table";

const SpEnternal = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [internals, setInternals] = useState([]);

  useEffect(() => {
    loadInternals();
  }, []);

  const loadInternals = () => {
    GETAllINTERNALS(user.token).then((res) => {
      setInternals(res.data);
    });
  };

  return (
    <div className="container">
      <h2 className="mt-5 text-center">
        service providers Internal Transactions
      </h2>
      <Table striped bordered hover className="mt-5">
        <thead>
          <tr>
            <th>#</th>
            <th>Sent Amount</th>
            <th>Converted Amount </th>
            <th>Fees</th>
            <th>Recived</th>
            <th>Direction</th>
            <th>Service Provider</th>
            <th>Created At</th>
            {/* <th>Updated At</th> */}
          </tr>
        </thead>
        <tbody>
          {internals.map((i, index) => (
            <tr key={i._id}>
              <td>{index + 1}</td>
              <td>
                {i.direction === "usd-to-eur" ? "$" : "€"}{" "}
                {i.sentamount.toFixed(2)}
              </td>
              <td>
                {i.direction === "usd-to-eur" ? "€" : "$"}{" "}
                {i.amountafterconvert.toFixed(2)}
              </td>
              <td>{i.fees.toFixed(2)}</td>
              <td>
                {i.direction === "usd-to-eur" ? "€" : "$"}{" "}
                {i.finalamount.toFixed(2)}
              </td>
              <td>{i.direction}</td>
              <td>{i.serviceprovider.name}</td>
              <td>{new Date(i.createdAt).toLocaleString()}</td>
              {/* <td>{new Date(i.updatedAt).toLocaleString()}</td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SpEnternal;
