import React from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GETUSPORDERS } from "../../../function/orders";
import { useState } from "react";
import { Card } from "antd";
import { Switch, Table } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import { Button } from "antd";
import OrdersCards from "./CustomerOrders";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const ServiceProviderOrders = () => {
  const [orders, setOrders] = useState([]);
  const { user } = useSelector((state) => ({ ...state }));
  const [loadings, setLoadings] = useState([]);
  const navigate = useNavigate();
  let { publicid } = useParams();

  useEffect(() => {
    // 5-nov updated GETUSPORDERS(user.token, publicId) to GETUSPORDERS(user.token)
    GETUSPORDERS(user.token).then((res) => {
      setOrders(res.data);
    });
  }, []);

  const handleCards = (card) => {};

  const menu = (
    <Menu
      items={[
        {
          label: `show details`,
          key: "1",
        },
      ]}
    />
  );

  return (
    <>
      <div className="container customerspage">
        <div className="row">
          <h1 className="text-dark text-center"> Service Provider Orders</h1>
          <h5 className="text-warning text-center mt-5">
            <u>{orders.length}</u> ORDERS
          </h5>
          <div class="table-responsive ">
            <table
              className=" table  table-responsive responsive"
              striped
              bordered
              hover
            >
              <thead>
                <tr className="text-white bg-dark text-center">
                  <th scope="col">Order Number</th>
                  <th scope="col">Customer</th>
                  <th scope="col">SubScription</th>
                  <th scope="col">Cards Number</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Type OF Payment</th>
                  <th scope="col">payment status</th>
                  <th scope="col">Date of order</th>
                </tr>
              </thead>
              <tbody>
                {orders.length ? (
                  orders.map((order, index) => {
                    return (
                      <>
                        <tr className="text-center">
                          <th scope="row">#{index + 1}</th>
                          <td>
                            {" "}
                            <Dropdown.Button
                              icon={<DownOutlined />}
                              overlay={
                                <Link
                                  className="bg-light p-2"
                                  to={`/admin/Customerorders/${order.email}`}
                                >
                                  {" "}
                                  show details
                                </Link>
                              }
                              onClick={handleCards}
                            >
                              {order.email}
                            </Dropdown.Button>
                          </td>
                          <td>{order.subScriptionType}</td>
                          <td>{order.cards.length}</td>

                          {order.paymentIntent.map((op) => (
                            <td>{op.amount}</td>
                          ))}
                          {order.paymentIntent.map((op) => (
                            <td>{op.paymenttype}</td>
                          ))}
                          <td>{order.status}</td>
                          {/* {opd.map((order)=>{
      return(<>
      {order?(<td className='text-success'>{JSON.stringify(order.order.status)}</td>):"NO"}
      </>)
    })} */}

                          <td>
                            {moment(order.createdAt).format("D/M/Y h:mm A")}
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <div className="text-center text-danger h1">
                    no data for now
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceProviderOrders;
