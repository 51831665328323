import React from 'react';


const TermsConditions=()=>{
    return (
      <>
        <div className="container">
          <dive className="row mt-5">
            <h1 className="text-center fw-bolder">Terms & Conditions</h1>
            <div className="conatainer mt-5">
              <div className="row mt-4">
                <div className="h3">Introduction</div>
                <p>
                  Welcome to{" "}
                  <a href="https://www.tathkeer.net/" target="_blank">
                    https://www.tathkeer.net/
                  </a>{" "}
                  (the "Website"). By accessing or using the Website, you agree
                  to be bound by the terms and conditions set forth in this
                  agreement (the "Agreement"). If you do not wish to be bound by
                  this Agreement, you may not access or use the Website. As a
                  reseller, you have the opportunity to be part of our team and
                  promote our application, available on
                  <a
                    href=" https://play.google.com/store/apps/details?id=net.tathkeer&pli=1"
                    target="_blank"
                  >
                    {" "}
                    https://play.google.com/store/apps/details?id=net.tathkeer&pli=1
                  </a>
                  , with the goal of activating it for customers. Our website is
                  designed to provide the easiest way to activate our
                  application and start your journey to success with our support
                  and community with no limits or borders.
                </p>
              </div>
              <div className="row mt-5 ">
                <h3>Use of the Website</h3>
                <p>
                  The Website is for commercial use. By accessing or using the
                  Website, you agree to use it solely for the purpose of
                  promoting and activating Tathkeer's application and selling
                  activation codes to customers. You may not use the Website for
                  any unlawful purpose or for the promotion of illegal
                  activities. You also may not use the Website to promote or
                  solicit any goods or services not related to Tathkeer's
                  application.
                </p>
              </div>
              <div className="row mt-5">
                <h3>Intellectual Property</h3>
                <p>
                  The Website and all of its content, including but not limited
                  to text, graphics, logos, images, and software, are the
                  property of Tathkeer and are protected by copyright and
                  trademark laws. You may not use any content from the Website
                  without the express written consent of Tathkeer. As a
                  reseller, you are authorized to use Tathkeer's branding and
                  promotional materials solely for the purpose of promoting and
                  activating Tathkeer's application.
                </p>
              </div>
              <div className="row mt-5">
                <h3>Disclaimer of Warranties</h3>
                <p>
                  The Website is provided on an "as is" and "as available"
                  basis. Tathkeer makes no representations or warranties of any
                  kind, express or implied, as to the operation of the Website
                  or the information, content, materials, or products included
                  on the Website. Tathkeer will not be liable for any damages of
                  any kind arising from the use of the Website, including but
                  not limited to direct, indirect, incidental, punitive, and
                  consequential damages.
                </p>
              </div>
              <div className="row mt-5">
                <h3>Limitation of Liability</h3>
                <p>
                  Tathkeer will not be liable for any damages whatsoever,
                  including but not limited to any direct, indirect, special,
                  incidental, or consequential damages, arising out of or in
                  connection with the use or inability to use the Website. As a
                  reseller, you are solely responsible for ensuring that the
                  activation codes you sell are valid and for any issues that
                  may arise from the sale of such codes.
                </p>
              </div>
              <div className="row mt-5">
                <h3>Indemnification</h3>
                <p>
                  You agree to indemnify and hold Tathkeer and its affiliates,
                  officers, agents, and employees harmless from any claim or
                  demand, including reasonable attorneys' fees, made by any
                  third party due to or arising out of your use of the Website,
                  your violation of this Agreement, or your violation of any
                  rights of another person or entity.
                </p>
              </div>
              <div className="row mt-5">
                <h3>Governing Law</h3>
                <p>
                  This Agreement shall be governed by and construed in
                  accordance with the laws of JORDAN, without giving effect to
                  any principles of conflicts of law.
                </p>
              </div>
              <div className="row mt-5">
                <h3>Changes to the Agreement</h3>
                <p>
                  Tathkeer reserves the right to make changes to this Agreement
                  at any time. Your continued use of the Website following the
                  posting of changes to this Agreement will mean you accept
                  those changes.
                </p>
              </div>
              <div className="row">
                <h3>Contact Information</h3>
                <p>
                  If you have any questions or concerns about this Agreement,
                  please contact us at{" "}
                  <a
                    href="mailto:info@thkeer.net?body=Hello%20Team%2C%0A%0A%0A%0A%20Sincerely%2C%0A[Your%20Name]"
                    target="_blank"
                  >
                    info@thkeer.net
                  </a>{" "}
                </p>
              </div>
            </div>
          </dive>
        </div>
      </>
    );
}
export default TermsConditions