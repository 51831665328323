import "../../css/form.css";
import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { CREATEINTERNALTRANSFER } from "../../../function/internal";
import Axios from "axios";
import { ArrowRightOutlined } from "@ant-design/icons";
import Button from "react-bootstrap/esm/Button";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { USERCHECK, CHECKSERVICEPROVIDER } from "../../../function/auth";
import { toast } from "react-toastify";
import { CREATECAHSOUTREQ } from "../../../function/cashout";
import { CHECKPUBLICID } from "../../../function/customer";
import { CREATETRANSACTION } from "../../../function/transaction";
import Card from "react-bootstrap/Card";
import walletimg from "../../images/walletimg.png";
import Credit from "../credit";
import { Radio, Input } from "antd";

const SPWallet = () => {
  const [publicid, setPublicid] = useState();
  const [transActionAmount, setTransActionAmount] = useState();
  const { user } = useSelector((state) => ({ ...state }));
  const [balance, setBalance] = useState();
  const [disabled, setDisabled] = useState(false);
  const [usdbalance, setUsdbalance] = useState();
  const [currency, setCurrency] = useState("eur");

  const handleCurrency = (e) => {
    setCurrency(e.target.value);
  };
  const loaduser = async () => {
    // we rplace USERCHEK(user.email) to CHECKSERVICEPROVIDER(user.token)
    CHECKSERVICEPROVIDER(user.token).then((res) => {
      try {
        console.log("checkServiceProvider", res);
        setBalance(res.data.balance.toFixed(2));
        setUsdbalance(res.data.usdbalance.toFixed(2));
        if (currency === "eur" && res.data.balance < 10) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
        if (currency === "usd" && res.data.usdbalance < 10) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      } catch (err) {
        console.log("error from check service provider", err);
      }
    });
    //     console.log(res);
    //   USERCHECK(user.email).then((res) => {
    //     setBalance(res.data.balance.toFixed(2));
    //     setUsdbalance(res.data.usdbalance.toFixed(2));
    //     if (currency === "eur" && res.data.balance < 10) {
    //       setDisabled(true);
    //     } else {
    //       setDisabled(false);
    //     }
    //     if (currency === "usd" && res.data.usdbalance < 10) {
    //       setDisabled(true);
    //     } else {
    //       setDisabled(false);
    //     }
    //   });
  };

  useEffect(() => {
    loaduser();
  }, []);
  // const HandleCreateTrans=()=>{
  //   CREATETRANSACTION(user.token,transActionAmount).then((res)=>{
  //     console.log(res)
  //   })
  // }
  let navigate = useNavigate();

  const handleAmount = (e) => {
    setTransActionAmount(e.target.value);
    setTimeout(() => {
      if (currency === "eur" && Number(transActionAmount) > Number(balance)) {
        toast.error("Trans Amount should be less or equal   Amount ");
        setDisabled(true);
      } else {
        if (e.target.value < Number(10)) {
          setDisabled(true);
          toast.error("min €10");
        } else {
          setDisabled(false);
        }
      }

      if (
        currency === "usd" &&
        Number(transActionAmount) > Number(usdbalance)
      ) {
        toast.error("Trans Amount should be less or equal Amount ");
      } else {
        if (e.target.value < Number(10)) {
          setDisabled(true);
          toast.error("min $10");
        } else {
          setDisabled(false);
        }
      }
    }, 2000);
  };

  let sender = user.pid;
  const handleSubmit = (e) => {
    e.preventDefault();
    CHECKPUBLICID(publicid).then((res) => {
      if (res.data.ok !== true) {
        toast.warning("PLease Enter Valid Reseller id");
        setDisabled(true);
        return;
      } else {
        if (sender == publicid) {
          toast.error("YOU CANT TRANSFER TO YOUR SELF!!");
          setDisabled(true);
          return;
        } else {
          try {
            CREATETRANSACTION(
              user.token,
              transActionAmount,
              publicid,
              sender,
              currency
            ).then((res) => {
              if (res.status === 200) {
                navigate("/serviceprovider/history");
                toast.success("TransAction Done Successfully");
              } else {
                toast.error("cannot complete transaction");
                // console.log(res.data)
                toast.error(res.data);
              }
              loaduser();
            });
          } catch (err) {
            toast.error(err);
          }
        }
      }
    });
  };

  const Internal = () => {
    const { user } = useSelector((state) => ({ ...state }));
    const [info, setInfo] = useState([]);
    const [from, setFrom] = useState("eur");
    const [to, setTo] = useState("USD");
    const [output, setOutput] = useState(0);
    const [direction, setDirection] = useState("eur-to-usd");
    const [amount, setAmount] = useState(0);
    const [usdbalance, setUsdbalance] = useState();
    const [eurbalance, setEurbalance] = useState();

    const handleDirection = (e) => {
      setDirection(e.target.value);
    };

    const handleAmount = (e) => {
      setAmount(e.target.value);
    };
    const load = () => {
      // we rplace USERCHEK(user.email) to CHECKSERVICEPROVIDER(user.token)
      CHECKSERVICEPROVIDER(user.token).then((res) => {
        setEurbalance(res.data.balance.toFixed(2));
        setUsdbalance(res.data.usdbalance.toFixed(2));
      });
    };

    useEffect(() => {
      load();
    }, []);

    const handleSubmit = () => {
      // Here, you can add your code to handle the transfer based on the selected direction and amount
      // console.log(`Transfer ${amount} ${direction}`);
      CREATEINTERNALTRANSFER(user.token, direction, amount, output).then(
        (res) => {
          if (res.data.success === true) {
            toast.success("Transfer Completed");
          } else {
            toast.error(res.data.error);
          }
          loaduser();
        }
      );
    };

    const isSubmitDisabled =
      amount <= 0 ||
      (direction === "eur-to-usd" && amount > Number(eurbalance)) ||
      (direction === "usd-to-eur" && amount > Number(usdbalance));
    useEffect(() => {
      if (direction === "eur-to-usd") {
        setFrom("eur");
        setTo("USD");
      } else {
        setFrom("usd");
        setTo("EUR");
      }
    }, [direction]);

    useEffect(() => {
      Axios.get(
        `https://v6.exchangerate-api.com/v6/${process.env.REACT_APP_EXCHANGERATEKey}/latest/${from}`
      ).then((res) => {
        console.log(res);
        setInfo(res.data.conversion_rates);
        var rate = res.data.conversion_rates[to];
        const convertFees = 0.02;

        const amountAfterConvert = Number(rate) * Number(amount);
        const fee = convertFees * amountAfterConvert;
        setOutput(amountAfterConvert - fee);
      });
    }, [amount, from, direction]);

    return (
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="card col-md-6">
            <div className="card-header mt-3">
              <h4 className="text-center">Internal Transfer</h4>
            </div>
            <div className="card-body">
              <h5 className="text-center">Transfer direction</h5>
              <div className="row">
                <div className="d-flex justify-content-center ">
                  <Radio.Group onChange={handleDirection} value={direction}>
                    <Radio.Button value={"eur-to-usd"} name="direction">
                      Transfer from € Euro Balance to $ Dollar Balance
                    </Radio.Button>
                    <Radio.Button value={"usd-to-eur"} name="direction">
                      Transfer from $ Dollar Balance to € Euro Balance
                    </Radio.Button>
                  </Radio.Group>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-8 offset-md-2">
                  <Input
                    type="number"
                    min={0}
                    placeholder="Amount"
                    value={amount}
                    onChange={handleAmount}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <h4 className="text-center text-primary">
                  {amount ? (
                    <>
                      <b>{to === "USD" ? "$" : "€"}</b>
                      <b> {output.toFixed(2)} </b>
                    </>
                  ) : (
                    ""
                  )}
                </h4>
              </div>
              <div className="row mt-3">
                <div className="col-md-8 offset-md-2">
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="container-fluid ">
      <div className="row ">
        <h1 className="mt-5 ">wallet</h1>
        <h5 className="text-dark pt-1  ms-5 text-start">
          see your account information in here!
          <Link to="/profile">profile</Link>
        </h5>
        <div className=" col-10 col-sm-8  col-md-6   mt-5">
          <Card
            className="headCards"
            style={{ textAlign: "", marginTop: "15px" }}
          >
            <Card.Body>
              <Card.Title>
                <div className="float-start">
                  <img width={"70px"} src={walletimg} />
                </div>
                <div className=" text-capitalize">
                  My Wallet / <br />
                  Eur Balance:
                  <b className="text-primary">€{balance}</b>
                </div>{" "}
                <div className=" text-capitalize">
                  USD Balance:
                  <b className="text-primary">${usdbalance}</b>
                </div>{" "}
              </Card.Title>

              <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
              {/* <Card.Text>
                <div className="container ">
                  <Credit />
                </div>
              </Card.Text> */}
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className=" col-10 col-sm-8 mt-5  col-md-6 ">
          <form id="" onSubmit={handleSubmit} className=" rounded w-100">
            {/* <h5 className="amount_wallet">Balance:<span className="dollar_amount">${balance}</span></h5> */}
            <Card
              className="headCards"
              style={{ textAlign: "", marginTop: "15px" }}
            >
              <Card.Body>
                <Card.Title>
                  <div className=" text-capitalize">
                    {" "}
                    <span className="note_wallet">
                      * Transfer to another user inside <b>Tathkeer</b>
                    </span>
                  </div>
                </Card.Title>

                <Card.Subtitle className=" mt-2 text-muted">
                  <div className="d-flex justify-content-center ">
                    <Radio.Group onChange={handleCurrency} value={currency}>
                      <Radio value={"eur"} name="from">
                        € Euro
                      </Radio>
                      <Radio value={"usd"} name="from">
                        $ Dollar
                      </Radio>
                    </Radio.Group>
                  </div>
                </Card.Subtitle>
                <Card.Text>
                  <div className="container ">
                    <fieldset className="mt-5 ">
                      <input
                        placeholder="Transfer Id"
                        maxLength={5}
                        value={publicid}
                        onChange={(e) => setPublicid(e.target.value)}
                        className="form-control"
                        type="Number"
                        tabindex="1"
                        required
                        autofocus
                      />
                    </fieldset>
                    <fieldset>
                      <input
                        placeholder="Amount"
                        maxLength={5}
                        value={transActionAmount}
                        onChange={handleAmount}
                        className="form-control"
                        type="Number"
                        tabindex="1"
                        required
                        autofocus
                      />
                    </fieldset>
                    <fieldset>
                      {/* <input name="TS1" value=" TS 1, " type="checkbox" id="checkbox1"/><label for="checkbox1" className="checkwallet">Dollar</label> */}
                    </fieldset>
                    <fieldset>
                      <input
                        type="submit"
                        className="btn btn-outline-dark w-100"
                        disabled={disabled}
                        value={"Send"}
                      />
                    </fieldset>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </form>
        </div>
      </div>
      <div className="row justify-conent-center ">
        <Internal className="col" />
      </div>

      <h5 className="mt-5">
        For cash out request
        <u>
          <Link className="" to="/serviceprovider/cashout">
            {" "}
            Cache Out
          </Link>
        </u>
      </h5>
      <h5>
        For Done transitions requests{" "}
        <u>
          <Link className="" to="/serviceprovider/history">
            History
          </Link>
        </u>
      </h5>

      {/* <h5>To request Refound  <u><Link className="" to="/serviceprovider/history">
              
              Refound
              </Link></u></h5> */}
    </div>
  );
};
export default SPWallet;
