import React from "react";
import ReactDOM from "react-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RedirectLoading } from "../pages/redirectloading";
// import UpdateSubCategory from '../AdminPages/SubCategory/update';
import AdminSetting from "../pages/adminpages/setting";
import Orders from "../pages/adminpages/orders";
import { useState } from "react";
// import AdminDashboard from '../AdminPages/DashBoard/DashBoard';
// import ManegeOrders from '../AdminPages/orders';
// import Create from '../AdminPages/category/create';
// import UpdateCategory from '../AdminPages/category/update';
// import SubCategory from '../AdminPages/SubCategory/Create';
// import ProductCreate from '../AdminPages/product/create';
// import AllProducts from '../AdminPages/product/Allproducts';
// import ProductUpdate from '../AdminPages/product/ProductUpdate';
// import CreateCoupons from '../AdminPages/coupon/createCoupon'
// import CreateColor from '../AdminPages/colors/create';
// import CreateBrand from '../AdminPages/brand/create';
// import CreateTrader from '../AdminPages/trader/create';
import Dashboard from "../pages/adminpages/dashboards";
import { RedirectLoadingAdmin } from "../pages/redirectloading";
import { CURRENTADMIN } from "../../function/auth";
import Analysis from "../pages/adminpages/analysis";
import Users from "../pages/adminpages/users";
import ServiceProvider from "../pages/adminpages/serviceProvider";
import ServiceProviderOrders from "../pages/adminpages/ServiceProviderOrders";
import Customers from "../pages/adminpages/customers";
import CustmerDetials from "../pages/adminpages/custmerdetails";
import CustomersOrdersManage from "../pages/adminpages/customerordermange";
import AdminCacheOut from "../pages/adminpages/CacheOut";
import Transactions from "../pages/adminpages/transactions";
import AdminWallet from "../pages/adminpages/wallet";
import Refund from "../pages/serviceproviderpages/refund";
import Codes from "../pages/adminpages/codes";
import Financialproceduers from "../pages/adminpages/financialprocedures";
import BlockedServiceProvider from "../pages/adminpages/blockedsp";
import OrderInvoice from "../pages/adminpages/invoice";
import AdminCacheOutcrypto from "../pages/adminpages/cashoutcrypto";
import Oospb from "../pages/adminpages/oospb";
import AdmindollarWallet from "../pages/adminpages/dollarwallet";
import External from "../pages/adminpages/external";
import SpEnternal from "../pages/adminpages/spenternal";
import Logs from "../pages/adminpages/logs";

const AdminDash = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          console.log("current admin response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Dashboard /> : <RedirectLoadingAdmin />;
};
const AdminAnlasis = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          console.log("current admin response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Analysis /> : <RedirectLoadingAdmin />;
};

const AdminOrders = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          console.log("current admin response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Orders /> : <RedirectLoadingAdmin />;
};
const SpOrders = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          console.log("current admin response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <ServiceProviderOrders /> : <RedirectLoadingAdmin />;
};

const COrdersM = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          console.log("current admin response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <CustomersOrdersManage /> : <RedirectLoadingAdmin />;
};

const AdminUsersPage = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Users /> : <RedirectLoadingAdmin />;
};
const AdminServiceProvider = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <ServiceProvider /> : <RedirectLoadingAdmin />;
};

const AdminCustomersRoute = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Customers /> : <RedirectLoadingAdmin />;
};

const AdminSettingRoute = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <AdminSetting /> : <RedirectLoadingAdmin />;
};

const AdminCustomerDetailRoute = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <CustmerDetials /> : <RedirectLoadingAdmin />;
};
const AdminCashout = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <AdminCacheOut /> : <RedirectLoadingAdmin />;
};

const AdminCashoutcrypto = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <AdminCacheOutcrypto /> : <RedirectLoadingAdmin />;
};

const AdminTransActions = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Transactions /> : <RedirectLoadingAdmin />;
};
const AdminWalletPage = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <AdminWallet /> : <RedirectLoadingAdmin />;
};
const AdminDollarWalletPage = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <AdmindollarWallet /> : <RedirectLoadingAdmin />;
};

const AdminCodes = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Codes /> : <RedirectLoadingAdmin />;
};

const AdminFinancialprocedures = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Financialproceduers /> : <RedirectLoadingAdmin />;
};

const AdminBlockedSp = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <BlockedServiceProvider /> : <RedirectLoadingAdmin />;
};
const AdminInvoce = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <OrderInvoice /> : <RedirectLoadingAdmin />;
};

const AdminOospb = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Oospb /> : <RedirectLoadingAdmin />;
};
const AdminExternal = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <External /> : <RedirectLoadingAdmin />;
};

const AdminSpEternal = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <SpEnternal /> : <RedirectLoadingAdmin />;
}
const AdminLogsPage = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      CURRENTADMIN(user.token)
        .then((res) => {
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Logs /> : <RedirectLoadingAdmin />;
}
export {
  AdminExternal,AdminLogsPage,
  AdminInvoce,
  AdminBlockedSp,
  AdminFinancialprocedures,
  AdminCodes,
  AdminTransActions,
  AdminCustomerDetailRoute,
  COrdersM,
  AdminCashout,
  AdminWalletPage,
  AdminCashoutcrypto,
  AdminDash,
  AdminAnlasis,
  AdminUsersPage,
  AdminServiceProvider,
  SpOrders,
  AdminSettingRoute,
  AdminCustomersRoute,
  AdminOospb,
  AdminDollarWalletPage,
  AdminSpEternal,
};

// , AdminOrders, AdminRouteCreate, AdminRouteupdate, AdminRouteSubCategory, AdminRouteupdateSub,
// AdminRouteupdateProduct, AdminRouteCreatePeoduct, AllProductsRoute,
// AdminRouteCreateCoupons, AdminRouteCreateColor, AdminRouteCreateBrand, AdminRouteCreateTrader
