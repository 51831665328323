import React, { useState } from "react";
import { Checkbox } from "antd";
import { useEffect } from "react";
import {
  CREATCASHOUTEFEES,
  CREATEFEES,
  CREATEORUPDATEPAYMENTSSTATUS,
  CREATTRANSEFEES,
  GETDEFAULTFEES,
  UPDATFEES,
} from "../../../function/settings";
import "../../css/form.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AiOutlineBank } from "react-icons/ai";
import {
  HomeOutlined,
  LoadingOutlined,
  SettingFilled,
  SmileOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { FaCcPaypal } from "react-icons/fa";
import {
  EyeOutlined,
  CrownOutlined,
  EditFilled,
  CopyOutlined,
  EditOutlined,
  UserSwitchOutlined,
  PhoneOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Input, Tooltip, Button } from "antd";
import { Space } from "antd";
import {
  CRUDBEBANK,
  CRUDPAYPAL,
  CRUDTRBANK,
  CRUDUKBANK,
  CRUDUSBANK,
  GETBEBANKINFO,
  GETPAYPALINFO,
  GETTRBANKINFO,
  GETUKBANKINFO,
  GETUSBANKINFO,
} from "../../../function/payment";

const AdminSetting = () => {
  const [transactionfees, setTransactionfees] = useState();
  const [defaultfees, setDefaltfees] = useState();
  const [loading, setLoading] = useState(false);
  const [cashoutfees, setCashoutfees] = useState();
  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    console.log(`checked = ${paypalvisible}`);
    loadDefaultFees();
  }, []);

  const CreateOrUpdatePaymentVisbility = () => {
    CREATEORUPDATEPAYMENTSSTATUS(
      user.token,
      paypalvisible,
      cardvisible,
      bankvisible
    ).then((res) => {
      console.log("res.datares.data", res.data);
      toast.success("updated Successfully");
    });
  };

  const loadDefaultFees = () => {
    GETDEFAULTFEES(user.token).then((res) => {
      {
        console.log('res',res);
        res.data.map((res) => {
          console.log('res',res);
          setDefaltfees(res.defaultfees);
          setCashoutfees(res.cashoutfees);
          setTransactionfees(res.transactionfees);
          setBankvisible(res.bankstatus);
          setCardvisible(res.cardstatus);
          setPaypalvisible(res.paypalstatus);
          if (res.paypalstatus === "invisible") {
            setPaypalcheck(false);
          }
          if (res.cardstatus === "invisible") {
            setCardcheck(false);
          }
          if (res.bankstatus === "invisible") {
            setBankcheck(false);
          }
        });
      }
    });
    GETPAYPALINFO(user.token).then((res) => {
      // console.log(res)
      {
        res.data.map((c) => {
          setPaypal(c.paypal);
        });
      }
    });

    GETUSBANKINFO(user.token).then((res) => {
      {
        res.data.map((c) => {
          {
            c.usbank.map((cc) => {
              setUsbankname(cc.name);
              setUsstatus(cc.status);

              if (cc.status === "invisible") {
                setUsstatusChecked(false);
              }
              setUsaccountholder(cc.usaccountholder);

              setUsroutingnumber(cc.usroutingnumber);
              setUsaccounttype(cc.usaccounttype);
              setUsaccountnumber(cc.usaccountnumber);
              setUswisesaddress(cc.uswisesaddress);
              setUscountry(cc.uscountry);
              setUscity(cc.uscity);
              setUscurrency(cc.uscurrency);
            });
          }
        });
      }
    });

    GETBEBANKINFO(user.token).then((res) => {
      {
        res.data.map((c) => {
          {
            c.belgiumbank.map((cc) => {
              setBebankname(cc.name);
              setBestatus(cc.status);
              if (cc.status === "invisible") {
                setBestatusChecked(false);
              }
              setBeaccountholder(cc.beaccountholder);
              setBeiban(cc.beiban);
              setBecountry(cc.becountry);
              setBecity(cc.becity);
              setBecurrency(cc.becurrency);
              setBeBic(cc.beBic);
              setBewisesaddress(cc.bewisesaddress);
            });
          }
        });
      }
    });

    //////////////

    GETTRBANKINFO(user.token).then((res) => {
      {
        res.data.map((c) => {
          {
            c.trybank.map((cc) => {
              setTraccountholder(cc.traccountholder);
              setTriban(cc.triban);
              setTrcountry(cc.trcountry);
              setTrcity(cc.trcity);
              setTrcurrency(cc.trcurrency);
              setTrbankname(cc.name);
              setTrstatus(cc.status);
              if (cc.status === "invisible") {
                setTrstatusChecked(false);
              }
            });
          }
        });
      }
    });

    GETUKBANKINFO(user.token).then((res) => {
      {
        res.data.map((c) => {
          {
            c.ukbank.map((cc) => {
              setUkbankname(cc.name);
              setUkstatus(cc.status);
              if (cc.status === "invisible") {
                setUkstatusChecked(false);
              }
              setUkaccountholder(cc.ukaccountholder);
              setUkaccountnumber(cc.ukaccountnumber);
              setUksortcode(cc.uksortcode);
              setUkiban(cc.ukiban);
              setUkcountry(cc.ukcountry);
              setUkcity(cc.ukcity);
              setUkcurrency(cc.ukcurrency);
              setUkwisesaddress(cc.ukwisesaddress);
            });
          }
        });
      }
    });
  };

  const UpdateFees = (e) => {
    e.preventDefault();
    setLoading(true);
    UPDATFEES(user.token, setDefaltfees).then((res) => {
      toast.success("DefaultFees Updated Successfully");
      setLoading(false);
    });
  };
  const handleCashoutChange = (e) => {
    setCashoutfees(e.target.value);
  };
  const handleDefaultFees = (e) => {
    setDefaltfees(e.target.value);
  };
  const handleTransactionfees = (e) => {
    setTransactionfees(e.target.value);
  };
  const CreateDefaultFees = (e) => {
    e.preventDefault();
    // console.log(defaultFees)
    CREATEFEES(user.token, defaultfees).then((res) => {
      setDefaltfees(res.data);
      toast.success("updated succesfully");
      loadDefaultFees();
    });
  };
  const CreatetransActoinFees = (e) => {
    e.preventDefault();
    CREATTRANSEFEES(user.token, transactionfees).then((res) => {
      toast.success("updated succesfully");
      loadDefaultFees();
    });
  };

  const handleCashoutFees = (e) => {
    e.preventDefault();
    CREATCASHOUTEFEES(user.token, cashoutfees).then((res) => {
      setCashoutfees(res.data.cashoutfees);
      console.log('res.data.cashoutfees',res.data.cashoutfees);
      toast.success("updated succesfully");
      loadDefaultFees();
    });
  };
  const [paypal, setPaypal] = useState();

  const [bank2, setBank2] = useState();
  const [bank3, setBank3] = useState();
  const [bank4, setBank4] = useState();
  const handleChangeusbank = () => {};
  const handleChangeBank2 = () => {};
  const handleChangeBank3 = () => {};
  const handleChangeBank4 = () => {};
  const handleChangePaypal = (e) => {
    setPaypal(e.target.value);
  };
  const handleSubmitPaypal = () => {
    if (!paypal) {
      toast.error("please Enter Paypal");
    } else {
      CRUDPAYPAL(user.token, paypal).then((res) => {
        toast.success("new Paypal account created");
      });
    }
  };
  const [usbank, setUsbank] = useState([]);
  const [usVisible, setUsVisible] = useState(false);
  const [usaccountholder, setUsaccountholder] = useState("");
  const [usroutingnumber, setUsroutingnumber] = useState();
  const [usaccountnumber, setUsaccountnumber] = useState();
  const [usaccounttype, setUsaccounttype] = useState("");
  const [uswisesaddress, setUswisesaddress] = useState("");
  const [uscountry, setUscountry] = useState("");
  const [uscity, setUscity] = useState("");
  const [uscurrency, setUscurrency] = useState("");
  const [paypalvisible, setPaypalvisible] = useState("visible");
  const [cardvisible, setCardvisible] = useState("visible");
  const [bankvisible, setBankvisible] = useState("visible");
  const [paypalcheck, setPaypalcheck] = useState(true);
  const [cardcheck, setCardcheck] = useState(true);
  const [bankcheck, setBankcheck] = useState(true);
  const [usstatus, setUsstatus] = useState("visible");
  const [usstatusChecked, setUsstatusChecked] = useState(true);
  const [usbankname, setUsbankname] = useState("");

  const handleusbanknameChange = (e) => {
    setUsbankname(e.target.value);
  };

  const handleUsbankstatus = (e) => {
    setUsstatusChecked(!usstatusChecked);
    if (usstatus === "visible") {
      setUsstatus("invisible");
    } else {
      setUsstatus("visible");
    }
  };

  const handleUsbankInputs = () => {
    setUsVisible(!usVisible);
  };

  const handleTrbankInputs = () => {
    setTrVisible(!trVisible);
  };
  const handleBebankInputs = () => {
    setBeVisible(!beVisible);
  };
  const handleUkbankInputs = () => {
    setUkVisible(!ukVisible);
  };

  const handleusaccountholder = (e) => {
    setUsaccountholder(e.target.value);
  };
  const handletraccountholder = (e) => {
    setTraccountholder(e.target.value);
  };
  const handlebeaccountholder = (e) => {
    setBeaccountholder(e.target.value);
  };
  const handleukaccountnumber = (e) => {
    setUkaccountnumber(e.target.value);
  };
  const handleukaccountholder = (e) => {
    setUkaccountholder(e.target.value);
  };

  const handleusroutingnumber = (e) => {
    setUsroutingnumber(e.target.value);
  };
  const handleusaccounttype = (e) => {
    setUsaccounttype(e.target.value);
  };
  const handleusaccountnumber = (e) => {
    setUsaccountnumber(e.target.value);
  };

  const handleIban = (e) => {
    setTriban(e.target.value);
  };
  const handlebeIban = (e) => {
    setBeiban(e.target.value);
  };
  const handleukIban = (e) => {
    setUkiban(e.target.value);
  };
  const handlebeBic = (e) => {
    setBeBic(e.target.value);
  };
  const handleukSortCode = (e) => {
    setUksortcode(e.target.value);
  };
  const handleuswisesaddress = (e) => {
    setUswisesaddress(e.target.value);
  };
  const handleuscountry = (e) => {
    setUscountry(e.target.value);
  };

  const handleTrcountry = (e) => {
    setTrcountry(e.target.value);
  };
  const handlebecountry = (e) => {
    setBecountry(e.target.value);
  };
  const handleukcountry = (e) => {
    setUkcountry(e.target.value);
  };
  const handleuscity = (e) => {
    setUscity(e.target.value);
  };
  const handleTrcity = (e) => {
    setTrcity(e.target.value);
  };
  const handlebecity = (e) => {
    setBecity(e.target.value);
  };
  const handleukcity = (e) => {
    setUkcity(e.target.value);
  };
  const handleuscurrency = (e) => {
    setUscurrency(e.target.value);
  };
  const handleTrcurrency = (e) => {
    setTrcurrency(e.target.value);
  };
  const handlebecurrency = (e) => {
    setBecurrency(e.target.value);
  };
  const handleukcurrency = (e) => {
    setUkcurrency(e.target.value);
  };
  const handlebewisesaddress = (e) => {
    setBewisesaddress(e.target.value);
  };

  const handleukwisesaddress = (e) => {
    setUkwisesaddress(e.target.value);
  };

  let ob = {
    usaccountholder: usaccountholder,
    usroutingnumber: usroutingnumber,
    usaccountnumber: usaccountnumber,
    usaccounttype: usaccounttype,
    uscountry: uscountry,
    uscity: uscity,
    uscurrency: uscurrency,
    uswisesaddress: uswisesaddress,
    status: usstatus,
    name: usbankname,
  };
  const handlecreateusbank = () => {
    usbank.push(ob);
    CRUDUSBANK(user.token, usbank).then((res) => {
      window.location.reload();
    });
    toast.success("new US Bank Account Has been Created");
  };

  const handletrbanknameChange = (e) => {
    setTrbankname(e.target.value);
  };
  const [trbank, setTrbank] = useState([]);
  const [trVisible, setTrVisible] = useState(false);
  const [traccountholder, setTraccountholder] = useState("");
  const [trcountry, setTrcountry] = useState("");
  const [trcity, setTrcity] = useState("");
  const [trcurrency, setTrcurrency] = useState("");
  const [triban, setTriban] = useState("");
  const [trstatus, setTrstatus] = useState("visible");
  const [trstatusChecked, setTrstatusChecked] = useState(true);
  const [trbankname, setTrbankname] = useState("");

  const handleTrbankstatus = (e) => {
    setTrstatusChecked(!trstatusChecked);
    if (trstatus === "visible") {
      setTrstatus("invisible");
    } else {
      setTrstatus("visible");
    }
  };

  const handleBebankstatus = (e) => {
    setBestatusChecked(!bestatusChecked);
    if (bestatus === "visible") {
      setBestatus("invisible");
    } else {
      setBestatus("visible");
    }
  };

  let ob2 = {
    traccountholder: traccountholder,
    triban: triban,

    trcountry: trcountry,
    trcity: trcity,
    trcurrency: trcurrency,
    status: trstatus,
    name: trbankname,
  };

  const handlecreatetrbank = () => {
    trbank.push(ob2);
    CRUDTRBANK(user.token, trbank).then((res) => {
      window.location.reload();
    });
    toast.success("new tr Bank Account Has been Created");
  };
  const handlebebanknameChange = (e) => {
    setBebankname(e.target.value);
  };
  const [bebank, setBebank] = useState([]);
  const [beVisible, setBeVisible] = useState(false);
  const [beaccountholder, setBeaccountholder] = useState("");
  const [beBic, setBeBic] = useState("");
  const [beiban, setBeiban] = useState("");
  const [bewisesaddress, setBewisesaddress] = useState("");
  const [becountry, setBecountry] = useState("");
  const [becity, setBecity] = useState("");
  const [becurrency, setBecurrency] = useState("");
  const [bestatus, setBestatus] = useState("visible");
  const [bestatusChecked, setBestatusChecked] = useState(true);
  const [bebankname, setBebankname] = useState("");

  let ob3 = {
    beaccountholder: beaccountholder,
    beiban: beiban,

    becountry: becountry,
    becity: becity,
    becurrency: becurrency,
    beBic: beBic,
    bewisesaddress: bewisesaddress,
    status: bestatus,
    name: bebankname,
  };

  const handlecreatebebank = () => {
    bebank.push(ob3);
    CRUDBEBANK(user.token, bebank).then((res) => {
      window.location.reload();
    });
    toast.success("new belgium Bank Account Has been Created");
  };

  const [ukbank, setUkbank] = useState([]);
  const [ukVisible, setUkVisible] = useState(false);
  const [ukaccountholder, setUkaccountholder] = useState("");
  const [ukaccountnumber, setUkaccountnumber] = useState("");
  const [ukiban, setUkiban] = useState("");
  const [uksortcode, setUksortcode] = useState("");
  const [ukwisesaddress, setUkwisesaddress] = useState("");
  const [ukcountry, setUkcountry] = useState("");
  const [ukcity, setUkcity] = useState("");
  const [ukcurrency, setUkcurrency] = useState("");
  const [ukstatus, setUkstatus] = useState("visible");
  const [ukstatusChecked, setUkstatusChecked] = useState(true);
  const [ukbankname, setUkbankname] = useState("");
  const handleUkbankstatus = (e) => {
    setUkstatusChecked(!ukstatusChecked);
    if (ukstatus === "visible") {
      setUkstatus("invisible");
    } else {
      setUkstatus("visible");
    }
  };
  let ob4 = {
    ukaccountholder: ukaccountholder,
    ukiban: ukiban,
    ukaccountnumber: ukaccountnumber,
    ukcountry: ukcountry,
    ukcity: ukcity,
    ukcurrency: ukcurrency,
    uksortcode: uksortcode,
    ukwisesaddress: ukwisesaddress,
    status: ukstatus,
    name: ukbankname,
  };
  const handleukbankname = (e) => {
    setUkbankname(e.target.value);
  };

  const handleCreateukbank = () => {
    ukbank.push(ob4);
    CRUDUKBANK(user.token, ukbank).then((res) => {
      window.location.reload();
    });
    toast.success("new united kingdom Bank Account Has been Created");
  };
  const onPaypalChange = (e) => {
    // console.log(paypalvisible)

    setPaypalcheck(!paypalcheck);
    if (paypalvisible === "visible") {
      setPaypalvisible("invisible");
    } else {
      setPaypalvisible("visible");
    }
    // console.log(`checked = ${e.target.value}`);
  };

  const onCardChange = (e) => {
    // console.log(paypalvisible)

    setCardcheck(!cardcheck);
    if (cardvisible === "visible") {
      setCardvisible("invisible");
    } else {
      setCardvisible("visible");
    }
    // console.log(`checked = ${e.target.value}`);
  };

  const onBankChange = (e) => {
    // console.log(paypalvisible)

    setBankcheck(!bankcheck);
    if (bankvisible === "visible") {
      setBankvisible("invisible");
    } else {
      setBankvisible("visible");
    }
    // console.log(`checked = ${e.target.value}`);
  };

  return (
    <>
      <div
        class="modal fade"
        id="paypal"
        tabindex="-1"
        aria-labelledby="role"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="role">
                Control paypal Account
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <label>Enter New Client Id without ("") Qutation</label>
              <input
                type="text"
                className="form-control"
                value={paypal}
                onChange={handleChangePaypal}
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleSubmitPaypal}
                data-bs-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="usbank"
        tabindex="-1"
        aria-labelledby="role"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="role">
                {" "}
                united state bank Account{" "}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">Are You Sure?</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handlecreateusbank}
              >
                Yes{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="trbank"
        tabindex="-1"
        aria-labelledby="role"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="role">
                Control Turky bank Account{" "}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">Are You Sure?</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handlecreatetrbank}
              >
                Yes{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="bebank"
        tabindex="-1"
        aria-labelledby="role"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="role">
                {" "}
                Belgium Bank Account{" "}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">Are You Sure?</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handlecreatebebank}
              >
                Yes{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="paymentsway"
        tabindex="-1"
        aria-labelledby="role"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="role">
                Are You Sure?{" "}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <button
                type="button"
                class="btn btn-secondary mb-1"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={CreateOrUpdatePaymentVisbility}
              >
                Yes{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="ukbank"
        tabindex="-1"
        aria-labelledby="role"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="role">
                {" "}
                united kingdom Bank Account{" "}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">Are You Sure?</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleCreateukbank}
              >
                Yes{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid customerspage">
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 col-sm-12">
            <form
              className="bg-light m-5 p-5 rounded"
              onSubmit={CreateDefaultFees}
            >
              <h3> Default Fees</h3>

              <div className="d-flex justify-content-center">
                <input
                  placeholder="default fees"
                  type="number"
                  value={defaultfees}
                  onChange={handleDefaultFees}
                  className="form-control"
                />
              </div>
              <fieldset>
                {loading ? (
                  <button
                    className="btn btn-dark"
                    disabled
                    data-submit="...Sending"
                  >
                    {" "}
                    <SyncOutlined spin />
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={CreateDefaultFees}
                    className="btn btn-dark"
                  >
                    Update
                  </button>
                )}
              </fieldset>
            </form>

            <form
              className="bg-light m-5 p-5 rounded"
              onSubmit={CreatetransActoinFees}
            >
              <h3> Transaction Fees</h3>

              <div className="d-flex justify-content-center">
                <input
                  placeholder="TransAction fees"
                  type="number"
                  value={transactionfees}
                  onChange={handleTransactionfees}
                  className="form-control"
                />
              </div>
              <fieldset>
                {loading ? (
                  <button
                    className="btn btn-dark"
                    disabled
                    data-submit="...Sending"
                  >
                    {" "}
                    <SyncOutlined spin />
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={CreatetransActoinFees}
                    className="btn btn-dark"
                  >
                    Update
                  </button>
                )}
              </fieldset>
            </form>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 col-sm-12">
            <form
              className="bg-light m-5 p-5 rounded"
              onSubmit={handleCashoutFees}
            >
              <h3> Cashout Fees</h3>

              <div className="d-flex justify-content-center">
                <input
                  type="number"
                  placeholder="cashout fees"
                  value={cashoutfees}
                  onChange={handleCashoutChange}
                  className="form-control"
                />
              </div>
              <fieldset>
                {loading ? (
                  <button
                    className="btn btn-dark"
                    disabled
                    data-submit="...Sending"
                  >
                    {" "}
                    <SyncOutlined spin />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-dark"
                    onClick={handleCashoutFees}
                  >
                    Update
                  </button>
                )}
              </fieldset>
            </form>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 col-sm-12">
            <div className="bg-light m-5 p-5 rounded">
              <h3 className="text-center">Payments Accounts</h3>

              <div className="">
                <label className="text-dark">paypal</label>
                <br />
                <Input
                  disabled
                  style={{
                    width: "calc(100% - 35px)",
                    marginBottom: "2%",
                    background: "dark",
                  }}
                  value={paypal}
                  prefix={
                    <FaCcPaypal className="site-form-item-icon text-warning " />
                  }
                />
                <Tooltip title="Edit paypal">
                  <Button
                    icon={<EditOutlined className="text-danger" />}
                    data-bs-toggle="modal"
                    data-bs-target="#paypal"
                  />
                </Tooltip>
                <label className="text-dark">United States Bank Account</label>
                <br />
                <Input
                  disabled
                  style={{
                    width: "calc(100% - 35px)",
                    marginBottom: "2%",
                    background: "dark",
                  }}
                  value={usbank}
                  prefix={
                    <AiOutlineBank className="site-form-item-icon text-danger " />
                  }
                />
                <Tooltip title="Edit usbank">
                  <Button
                    icon={
                      <EditOutlined
                        onClick={handleUsbankInputs}
                        className="text-danger"
                      />
                    }
                  />
                  {/* <Button icon={<EditOutlined OnClick className='text-danger'/>}   data-bs-toggle="modal" data-bs-target="#usbank"  /> */}
                </Tooltip>

                {usVisible ? (
                  <div>
                    {/* {JSON.stringify(usbank)} */}
                    <label>show name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={usbankname}
                      onChange={handleusbanknameChange}
                    />
                    <label>accountholder</label>
                    <input
                      type="text"
                      className="form-control"
                      value={usaccountholder}
                      onChange={handleusaccountholder}
                    />

                    <label>routingnumber</label>
                    <input
                      type="text"
                      className="form-control"
                      value={usroutingnumber}
                      onChange={handleusroutingnumber}
                    />

                    <label>accounttype</label>
                    <input
                      type="text"
                      className="form-control"
                      value={usaccounttype}
                      onChange={handleusaccounttype}
                    />

                    <label>accountnumber</label>
                    <input
                      type="text"
                      className="form-control"
                      value={usaccountnumber}
                      onChange={handleusaccountnumber}
                    />

                    <label>wisesaddress</label>
                    <input
                      type="text"
                      className="form-control"
                      value={uswisesaddress}
                      onChange={handleuswisesaddress}
                    />

                    <label>country</label>
                    <input
                      type="text"
                      className="form-control"
                      value={uscountry}
                      onChange={handleuscountry}
                    />

                    <label>city</label>
                    <input
                      type="text"
                      className="form-control"
                      value={uscity}
                      onChange={handleuscity}
                    />

                    <label>currency</label>
                    <input
                      type="text"
                      className="form-control"
                      value={uscurrency}
                      onChange={handleuscurrency}
                    />
                    {/* <label>visibility</label> */}
                    <div className="col   ms-2 h2 ">
                      <Checkbox
                        checked={usstatusChecked}
                        onChange={handleUsbankstatus}
                        value={usstatus}
                      >
                        {usstatus}
                      </Checkbox>
                    </div>

                    <button
                      className="btn btn-primary mt-5 "
                      data-bs-toggle="modal"
                      data-bs-target="#usbank"
                    >
                      save
                    </button>
                    <button
                      className="btn btn-danger mt-1 mb-5"
                      onClick={handleUsbankInputs}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <label className="text-dark">Turky Bank Account</label>
                <br />

                <Input
                  disabled
                  style={{
                    width: "calc(100% - 35px)",
                    marginBottom: "2%",
                    background: "dark",
                  }}
                  value={trbank}
                  prefix={
                    <AiOutlineBank className="site-form-item-icon text-danger " />
                  }
                />
                <Tooltip title="Edit bank2">
                  <Button
                    icon={<EditOutlined className="text-danger" />}
                    onClick={handleTrbankInputs}
                    data-bs-toggle="modal"
                    data-bs-target="#bank2"
                  />
                </Tooltip>
                {trVisible ? (
                  <div>
                    <label>show name</label>

                    <input
                      type="text"
                      className="form-control"
                      value={trbankname}
                      onChange={handletrbanknameChange}
                    />

                    <label>accountholder</label>
                    <input
                      type="text"
                      className="form-control"
                      value={traccountholder}
                      onChange={handletraccountholder}
                    />

                    <label>IBAN</label>
                    <input
                      type="text"
                      className="form-control"
                      value={triban}
                      onChange={handleIban}
                    />

                    <label>country</label>
                    <input
                      type="text"
                      className="form-control"
                      value={trcountry}
                      onChange={handleTrcountry}
                    />

                    <label>city</label>
                    <input
                      type="text"
                      className="form-control"
                      value={trcity}
                      onChange={handleTrcity}
                    />

                    <label>currency</label>
                    <input
                      type="text"
                      className="form-control"
                      value={trcurrency}
                      onChange={handleTrcurrency}
                    />
                    <div className="col   ms-2 h2 ">
                      <Checkbox
                        checked={trstatusChecked}
                        onChange={handleTrbankstatus}
                        value={trstatus}
                      >
                        {trstatus}
                      </Checkbox>
                    </div>
                    <button
                      className="btn btn-primary mt-5 "
                      data-bs-toggle="modal"
                      data-bs-target="#trbank"
                    >
                      save
                    </button>
                    <button
                      className="btn btn-danger mt-1 mb-5"
                      onClick={handleTrbankInputs}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  ""
                )}

                <label className="text-dark">belgium Bank Account</label>
                <br />
                <Input
                  disabled
                  style={{
                    width: "calc(100% - 35px)",
                    marginBottom: "2%",
                    background: "dark",
                  }}
                  value={trbank}
                  prefix={
                    <AiOutlineBank className="site-form-item-icon text-danger " />
                  }
                />
                <Tooltip title="Edit bank2">
                  <Button
                    icon={<EditOutlined className="text-danger" />}
                    onClick={handleBebankInputs}
                  />
                </Tooltip>
                {beVisible ? (
                  <div>
                    <label>show name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={bebankname}
                      onChange={handlebebanknameChange}
                    />
                    <label>accountholder</label>
                    <input
                      type="text"
                      className="form-control"
                      value={beaccountholder}
                      onChange={handlebeaccountholder}
                    />

                    <label>bic</label>
                    <input
                      type="text"
                      className="form-control"
                      value={beBic}
                      onChange={handlebeBic}
                    />

                    <label>IBAN</label>
                    <input
                      type="text"
                      className="form-control"
                      value={beiban}
                      onChange={handlebeIban}
                    />

                    <label>wisesaddress</label>
                    <input
                      type="text"
                      className="form-control"
                      value={bewisesaddress}
                      onChange={handlebewisesaddress}
                    />

                    <label>country</label>
                    <input
                      type="text"
                      className="form-control"
                      value={becountry}
                      onChange={handlebecountry}
                    />

                    <label>city</label>
                    <input
                      type="text"
                      className="form-control"
                      value={becity}
                      onChange={handlebecity}
                    />

                    <label>currency</label>
                    <input
                      type="text"
                      className="form-control"
                      value={becurrency}
                      onChange={handlebecurrency}
                    />
                    <div className="col   ms-2 h2 ">
                      <Checkbox
                        checked={bestatusChecked}
                        onChange={handleBebankstatus}
                        value={bestatus}
                      >
                        {bestatus}
                      </Checkbox>
                    </div>
                    <button
                      className="btn btn-primary mt-5 "
                      data-bs-toggle="modal"
                      data-bs-target="#bebank"
                    >
                      save
                    </button>
                    <button
                      className="btn btn-danger mt-1 mb-5"
                      onClick={handleBebankInputs}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  ""
                )}

                <label className="text-dark">united kingdom Bank Account</label>
                <br />
                <Input
                  disabled
                  style={{
                    width: "calc(100% - 35px)",
                    marginBottom: "2%",
                    background: "dark",
                  }}
                  value={bank4}
                  prefix={
                    <AiOutlineBank className="site-form-item-icon text-danger " />
                  }
                />
                <Tooltip title="Edit bank4">
                  <Button
                    icon={<EditOutlined className="text-danger" />}
                    onClick={handleUkbankInputs}
                  />
                </Tooltip>

                {ukVisible ? (
                  <div>
                    <label>show name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ukbankname}
                      onChange={handleukbankname}
                    />

                    <label>accountholder</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ukaccountholder}
                      onChange={handleukaccountholder}
                    />

                    <label>Account Number</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ukaccountnumber}
                      onChange={handleukaccountnumber}
                    />

                    <label>sort code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={uksortcode}
                      onChange={handleukSortCode}
                    />

                    <label>IBAN</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ukiban}
                      onChange={handleukIban}
                    />

                    <label>wisesaddress</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ukwisesaddress}
                      onChange={handleukwisesaddress}
                    />

                    <label>country</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ukcountry}
                      onChange={handleukcountry}
                    />

                    <label>city</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ukcity}
                      onChange={handleukcity}
                    />

                    <label>currency</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ukcurrency}
                      onChange={handleukcurrency}
                    />
                    <div className="col   ms-2 h2 ">
                      <Checkbox
                        checked={ukstatusChecked}
                        onChange={handleUkbankstatus}
                        value={ukstatus}
                      >
                        {ukstatus}
                      </Checkbox>
                    </div>
                    <button
                      className="btn btn-primary mt-5 "
                      data-bs-toggle="modal"
                      data-bs-target="#ukbank"
                    >
                      save
                    </button>
                    <button
                      className="btn btn-danger mt-1 mb-5"
                      onClick={handleUkbankInputs}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5 d-flex justify-content-center">
          <div className="col-md-6 col-sm-12">
            <div className="card  bg-light">
              <h5 className="text-center text-dark">
                {" "}
                Chose Paymets way visibility
              </h5>
              <div className="row">
                <div className="col   ms-2 h2 ">
                  <Checkbox
                    checked={paypalcheck}
                    onChange={onPaypalChange}
                    value={paypalvisible}
                  >
                    Paypal
                  </Checkbox>
                </div>
                <div className="col   ms-2 h2 ">
                  <Checkbox
                    checked={cardcheck}
                    onChange={onCardChange}
                    value={cardvisible}
                  >
                    Credit cards
                  </Checkbox>
                </div>
                <div className="col   ms-2 h2 ">
                  <Checkbox
                    checked={bankcheck}
                    onChange={onBankChange}
                    value={bankvisible}
                  >
                    Bank Transfer
                  </Checkbox>
                </div>
              </div>
              <div className="d-flex justify-content-center mb-2 mt-2">
                <button
                  className="btn btn-dark w-50"
                  data-bs-toggle="modal"
                  data-bs-target="#paymentsway"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <form id="contact"   onSubmit={CreateDefaultFees}>
                <h3>careate fees</h3>
                  
                    <fieldset  className="d-flex justify-content-center">
                      <input placeholder="default fees" value={defaultFees}  onChange={handleDefaultFees} 
                       required className="form-control"/>
                     </fieldset>
                    <fieldset>
                      <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Submit</button>
                    </fieldset>
            </form>   */}
      </div>
    </>
  );
};
export default AdminSetting;
