import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GETOOSPB } from "../../../function/oospb";
import { Table, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

const Oospb = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [oospbs, setOospbs] = useState([]);
  const [sp, setSp] = useState([]);
  useEffect(() => {
    GETOOSPB(user.token).then((res) => {
      // console.log("res.datares.datares.data", res.data);
      setOospbs(res.data.oospbs);
      {
        res.data.oospbs.map((rs) => {
          // I have update this line here because there are null vlaue come from the backend
          if (rs.serviceprovider !== null) {
            setSp(rs.serviceprovider);
          }
        });
      }
    });
  }, []);

  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h1>Oospb List</h1>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>Service Provider</th>
                <th>Operation Type</th>
                <th>Operation Currency</th>
                <th>Operation Amount</th>
                <th>Last Balance</th>
                <th>New Balance</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {oospbs.map((oospb) => (
                <tr key={oospb._id}>
                  <td>
                    <b className="text-dark">
                      {moment(oospb.createdAt).format("D/M/Y h:mm:ss A")}
                    </b>
                  </td>

                  <td>
                    {" "}
                    <Link to={`/user/${oospb.serviceprovider?.publicid} `}>
                      {" "}
                      {/* 2-Nov */}
                      {oospb.serviceprovider?.name}{" "}
                    </Link>
                  </td>

                  <td>{oospb.operationtype}</td>
                  <td>{oospb.currency}</td>
                  <td>{oospb.operationamount}</td>
                  <td>{oospb.lastbalance.toFixed(2)}</td>
                  <td>{oospb.newbalance.toFixed(2)}</td>
                  <td>{oospb.note}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Oospb;
