import axios from "axios";
export const CRUDUSER = async (authtoken, values) => {
  return await axios.post(`${process.env.REACT_APP_API}/CRUDUSER`, values, {
    headers: {
      authtoken,
    },
  });
};
export const LOGIN = async (authtoken, values) => {
  return await axios.post(`${process.env.REACT_APP_API}/login`, values, {
    headers: {
      authtoken,
    },
  });
};
// export const CURRENTUSER = async (authtoken) => {
//     return await axios.post(process.env.REACT_APP_API_CURRENTUSER, {}, {
//         headers: {
//             authtoken,
//         }
//     })
// }
export const CURRENTADMIN = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/CURRENTAdmin`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const CURRENTSPA = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/currentserviceprovider`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const GETSERVICEPROVIDER = async (authtoken) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API}/GETSERVICEPROVIDERS`,
    {
      headers: {
        authtoken,
      },
    }
  );
  console.log("res", res);
  return res;
};
export const getUsersCount = async () =>
  await axios.get(`${process.env.REACT_APP_API}/user/total`, {});

// export const GETUSER = async (publicid, token) => {
//   return await axios.get(`${process.env.REACT_APP_API}/user/${publicid}`});
// };

// 4-nov I have updated this function here to becoming like the following (it become take token depends on the how I handle the api in the backend )
export const GETUSER = async (publicid, token) => {
  return await axios.get(`${process.env.REACT_APP_API}/user/${publicid}`, {
    headers: {
      authtoken: token,
    },
  });
};

// 4-nov I added this function here for get data from the backend api for service provider dsahboard
export const SERVICEPROVIDERDASHBOARDINFO = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/serviceprovider/dashboard`,
    {
      headers: {
        authtoken: token,
      },
    }
  );
};

export const USERCHECK = async (email) =>
  await axios.post(`${process.env.REACT_APP_API}/usercheck`, { email });
export const getServicesProviderswf = async (arg) => {
  return await axios.post(`${process.env.REACT_APP_API}/search/filters`, arg);
};

export const CHECKADMIN = async (authtoken) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API}/checkAdmin`,
    {},
    {
      headers: {
        authtoken: authtoken,
      },
    }
  );
  console.log("admin check response", response.data);
  return response;
};

export const CHECKSERVICEPROVIDER = async (authtoken) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API}/checkServiceProvider`,
    null,
    {
      headers: {
        authtoken: authtoken,
      },
    }
  );
  return response;
};
