import React from 'react';
import './css/loader2.css';
import logo  from './images/logo-removebg-preview.png'
const Loader = () => (
  <div className="loader">
    <img src={logo} alt="Loading..."  className="animated-image"  />
  </div>
);

export default Loader;
