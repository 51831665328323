import axios from 'axios'
export const CREATECAHSOUTREQ = async (authtoken,amount,cashoutMethod,cashoutbankname
    ,cashoutbankiban,cashoutcryptonumber,output,currencyfees
      ,cashoutCrureencyFees,totalCashoutAmountDollar) => {
    return await axios.post(`${process.env.REACT_APP_API}/cashout`,
    {amount,cashoutMethod,cashoutbankname,cashoutbankiban,cashoutcryptonumber,output,currencyfees
      ,cashoutCrureencyFees,totalCashoutAmountDollar},{
       headers: { 
            authtoken 
        } 
    })
}

export const GETCASHOUTREQUESTbank = async (authtoken,sort, order, page) => {
    return await axios.post(`${process.env.REACT_APP_API}/cashoutreqbank`,{
        sort, order, page
    },{
       headers: { 
            authtoken 
        } 
    })
}
export const GETCASHOUTREQUESTcrypto = async (authtoken,sort, order, page) => {
    return await axios.post(`${process.env.REACT_APP_API}/cashoutreqcrypto`,{
        sort, order, page
    },{
       headers: { 
            authtoken 
        } 
    })
}

export const UPDATESTATUS = async (authtoken,_id,requestStatus,cashoutamount) => {
    return await axios.put(`${process.env.REACT_APP_API}/cashout`, {_id,requestStatus,cashoutamount},{
        headers:{
            authtoken
        }
    } )
}
export const UPDATESTATUSCRYPTO = async (authtoken,_id,requestStatus,cashoutamount) => {
    return await axios.put(`${process.env.REACT_APP_API}/cashout/crypto`, {_id,requestStatus,cashoutamount},{
        headers:{
            authtoken
        }
    } )
}
export const CANCELEDCASHOUTREQUEST = async (authtoken,_id) => {
    return await axios.put(`${process.env.REACT_APP_API}/cashoutcancel`, {_id},{
        headers:{
            authtoken
        }
    } )
}
export const ProcessingCASHOUTREQUEST = async (authtoken,_id) => {
    return await axios.put(`${process.env.REACT_APP_API}/processingcashout`, {_id},{
        headers:{
            authtoken
        }
    } )
}

export const GETSPCASHOUTREQUEST = async (authtoken) => {
    return await axios.get(`${process.env.REACT_APP_API}/spcashout`,{
       headers: { 
            authtoken 
        } 
    })
}
 export const cashoutrequesttotal = async () => await axios.get(`${process.env.REACT_APP_API}/cashout/totalreq`, {});
  export const cashoutrequesttotalbank = async () =>
    await axios.get(`${process.env.REACT_APP_API}/cashoutbank/total`, {
     
    })


    export const cashoutrequesttotalcrypto = async () =>
    await axios.get(`${process.env.REACT_APP_API}/cashoutcrypto/total`, {
     
    })


    export const GETSPCASHOUTREQCOUNT=async(authtoken,_id)=>{
    return await axios.get(`${process.env.REACT_APP_API}/cashout/total/${_id}`,{
        headers:{
            authtoken
        }
    })
}

export const GETLASTCASHOUTREQUEST = async (authtoken) => {
    return await axios.get(`${process.env.REACT_APP_API}/cashout/last`,{
       headers: { 
            authtoken 
        } 
    })
}
export const CREATECAHSOUTREQCRYPTO = async (
  authtoken,
  cashoutcryptonumber,
  cashoutamount,

) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/cashout/binance`,
    { cashoutcryptonumber, cashoutamount },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const GETSPLASTCASHOUTREQUEST = async (authtoken) => {
    return await axios.get(`${process.env.REACT_APP_API}/splastcashout`,{
       headers: { 
            authtoken 
        } 
    })
}
 export const createandupdaterejectreason = async (authtoken,_id,rejectreason) => {
    return await axios.put(`${process.env.REACT_APP_API}/cashoutrejectreason`, {_id,rejectreason},{
        headers:{
              authtoken 
        }
      
    } )
}
