import React from "react";
import { CreditCard, Clipboard } from "react-feather";
import { FaCcMastercard } from "react-icons/fa";
import { RiVisaLine } from "react-icons/ri";



import '../css/footer.css';
import { Link } from "react-router-dom";
const Footer = () => (
  <footer className="page-footer font-small blue py-5  ">
    {/* <div  className="d-flex justify-content-center">
     <h3 className="text-center text-white wah  mt-5">   We are here to help
you grow your business</h3>

    </div>
     <div  className="d-flex justify-content-center">
        <button className="btn gsf mb-5">Get Started Now</button>

    </div> */}

    <div className="container text-center ">
      <div className="row">
        <div className="col-md-3 mt-md-0  ">
          <h5
            className="text-start text-uppercase "
            style={{ color: "#fff", float: "start" }}
          >
            Tathkeer
          </h5>
          <p className="text-white text-start">
            Start Your Journey Now And make the Success By Your Self, Tathkeer
            Will be the support provider For You,no limmits no borders with our
            community,Enjoy!
          </p>
        </div>

        <hr className="clearfix w-75 d-md-none pb-0" />

        <div className="col-md-3 mb-md-0 mb-3 link_footer">
          <h5 className="text-uppercase">useful links</h5>
          <ul className="list-unstyled ">
            <li>
              <a href="https://www.tathkeer.net/">HOME</a>
            </li>
            <li>
              <a href="https://www.tathkeer.net/register">SIGNUP</a>
            </li>
            <li>
              <a href="https://www.tathkeer.net/login">LOGIN</a>
            </li>
          </ul>
        </div>

        <div className="col-md-3 mb-md-0 mb-3 link_footer">
          <center>
            {/* <a href="#" target="_blank" className="market-btn apple-btn rounded m-2" role="button">
  <span className="market-button-subtitle">Download on the</span>
  <span className="market-button-title">App Store</span>
</a> */}

            <a
              href="https://play.google.com/store/apps/details?id=net.tathkeer"
              target="blank"
              className="market-btn google-btn rounded w-100"
              role="button"
            >
              <span className="market-button-subtitle">Download on the</span>
              <span className="market-button-title">Google Play</span>
            </a>
          </center>
        </div>
        <div className="col ">
          <FaCcMastercard
            size={100}
            color="#F79E1B
"
          />
          <RiVisaLine size={100} color="#2774E3" />
        </div>
      </div>
    </div>
    <hr style={{ color: "#ccc" }} />
    <div
      className="footer-copyright text-center py-3 "
      style={{ color: "#fff" }}
    >
      Copyright @ 2022. All Rights Reserved.
      <a href="/" style={{ color: "yellow", textDecoration: "none" }}>
        {" "}
        Tathkeer{" "}
        <u>
          <Link to="/Privacy" className="text-white" target="blank">
            Privacy
          </Link>
        </u>
        {" , "}
        <u className="">
          <Link
            to="/cancellationpolicy&refund"
            className="text-warning"
            target="blank"
          >
            cancellation & Refund policy
          </Link>
        </u>
        {" , "}
        <u className="">
          <Link to="/terms&conditions" className="text-dark" target="blank">
            Terms&Conditions
          </Link>
        </u>
        {" , "}
        <u className="">
          <Link
            to="/MerchandiSeservicesPrices"
            className="text-dark"
            target="blank"
          >
            Merchandise Services Prices{" "}
          </Link>
        </u>
        {" , "}
        <u className="">
          <Link to="/DeliveryMethod" className="text-dark" target="blank">
            Delivery Policy & Method{" "}
          </Link>
        </u>
      </a>
    </div>
  </footer>
);

export default Footer

