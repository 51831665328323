import React from "react";
import ReactDOM from "react-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RedirectLoading } from "../pages/redirectloading";
// import UpdateSubCategory from '../AdminPages/SubCategory/update';

import { useState } from "react";
// import AdminDashboard from '../AdminPages/DashBoard/DashBoard';
// import ManegeOrders from '../AdminPages/orders';
// import Create from '../AdminPages/category/create';
// import UpdateCategory from '../AdminPages/category/update';
// import SubCategory from '../AdminPages/SubCategory/Create';
// import ProductCreate from '../AdminPages/product/create';
// import AllProducts from '../AdminPages/product/Allproducts';
// import ProductUpdate from '../AdminPages/product/ProductUpdate';
// import CreateCoupons from '../AdminPages/coupon/createCoupon'
// import CreateColor from '../AdminPages/colors/create';
// import CreateBrand from '../AdminPages/brand/create';
// import CreateTrader from '../AdminPages/trader/create';

import { RedirectLoadingAdmin } from "../pages/redirectloading";
import { CURRENTSPA, CHECKSERVICEPROVIDER } from "../../function/auth";
import SERVICEPROVIDERORDERSDETAILS from "../pages/serviceproviderpages/orders";
import SPCustomersOrdersManage from "../pages/serviceproviderpages/customerordermanage";
import SPCustomers from "../pages/serviceproviderpages/customers";

import SPWallet from "../pages/serviceproviderpages/wallet";
import SPCacheOut from "../pages/serviceproviderpages/cahsout";
import SPHistory from "../pages/serviceproviderpages/history";
import Refund from "../pages/serviceproviderpages/refund";
import Financialprocedures from "../pages/serviceproviderpages/financialprocedures";

// ------SPOD
const SPOD = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);
  useEffect(() => {
    if (user && user.token) {
      // I have replaced CURRENTSPA(user.token) to CHECKSERVICEPROVIDER(user.token)
      CHECKSERVICEPROVIDER(user.token)
        .then((res) => {
          console.log("update", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <SERVICEPROVIDERORDERSDETAILS /> : <RedirectLoadingAdmin />;
};

// ----SPODM
const SPODM = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);
  useEffect(() => {
    if (user && user.token) {
      // I have replaced CURRENTSPA(user.token) to CHECKSERVICEPROVIDER(user.token)
      CHECKSERVICEPROVIDER(user.token)
        .then((res) => {
          console.log("current service response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <SPCustomersOrdersManage /> : <RedirectLoadingAdmin />;
};

// -------SPC
const SPC = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      // I have replaced CURRENTSPA(user.token) to CHECKSERVICEPROVIDER(user.token)
      CHECKSERVICEPROVIDER(user.token)
        .then((res) => {
          console.log("current service response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <SPCustomers /> : <RedirectLoadingAdmin />;
};

// -------SPCW
const SPCW = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      // I have replaced CURRENTSPA(user.token) to CHECKSERVICEPROVIDER(user.token)
      CHECKSERVICEPROVIDER(user.token)
        .then((res) => {
          console.log("current service response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <SPWallet /> : <RedirectLoadingAdmin />;
};

//-------------SPCACHOUT
const SPCACHOUT = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      // I have replaced CURRENTSPA(user.token) to CHECKSERVICEPROVIDER(user.token)
      CHECKSERVICEPROVIDER(user.token)
        .then((res) => {
          console.log("current service response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <SPCacheOut /> : <RedirectLoadingAdmin />;
};

// --SPH
const SPH = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      // I have replaced CURRENTSPA(user.token) to CHECKSERVICEPROVIDER(user.token)
      CHECKSERVICEPROVIDER(user.token)
        .then((res) => {
          console.log("current service response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <SPHistory /> : <RedirectLoadingAdmin />;
};

const SPR = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);
  useEffect(() => {
    if (user && user.token) {
      // I have replaced CURRENTSPA(user.token) to CHECKSERVICEPROVIDER(user.token)
      CHECKSERVICEPROVIDER(user.token)
        .then((res) => {
          console.log("current service response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Refund /> : <RedirectLoadingAdmin />;
};

// ------SPFP
const SPFP = () => {
  // let navigate=useNavigate()
  const { user } = useSelector((state) => ({ ...state }));
  const [ok, setOk] = useState(false);

  useEffect(() => {
    if (user && user.token) {
      // I have replaced CURRENTSPA(user.token) to CHECKSERVICEPROVIDER(user.token)
      CHECKSERVICEPROVIDER(user.token)
        .then((res) => {
          console.log("current service response", res);
          setOk(true);
        })
        .catch((err) => {
          console.log(err);
          setOk(false);
        });
    }
  }, [user]);

  return ok ? <Financialprocedures /> : <RedirectLoadingAdmin />;
};

export { SPOD, SPODM, SPC, SPCW, SPCACHOUT, SPH, SPR, SPFP };
