import React, {useEffect, useState} from "react";
import "../../css/dashboard.css";
import {CgProfile} from "react-icons/cg";
import Dropdown from "react-bootstrap/Dropdown";
import {Link, useNavigate} from "react-router-dom";
import {AiOutlineSetting} from "react-icons/ai";
import {AntDesignOutlined, UserOutlined} from "@ant-design/icons";
import {Avatar, Divider, Tooltip} from "antd";
import {BiLogOut} from "react-icons/bi";
import Card from "react-bootstrap/Card";
import {useSelector} from "react-redux";
import {CHECKADMIN, getUsersCount, USERCHECK} from "../../../function/auth";
import {getCardsTotal} from "../../../function/cards";
import {
    GETBO,
    GETCO,
    GETLAST,
    GETNETTOTALAF,
    GETORDERSCOMPLETED,
    getorderscount,
    GETORDERSONPROCCESS,
    GETORDERSRejected,
    GETPO,
    GETTOTALBANKPURCHASE,
    GETTOTALCARDPURCHASE,
    GETTOTALCARDPURCHASEAF,
    GETTOTALPAYPALPURCHASE,
    GETTOTALPAYPALPURCHASEAF,
} from "../../../function/orders";
import {GETCUSTOMERSCOUNT} from "../../../function/customer";
import {
    cashoutrequesttotal,
    GETLASTCASHOUTREQUEST,
} from "../../../function/cashout";
import {
    GETAllTRANSACTIONSCOUNT,
    GETLASTTRANSACTIONS,
} from "../../../function/transaction";
import firebase from "firebase/compat/app";
import {useDispatch} from "react-redux";
import Button from "react-bootstrap/Button";
import {GETORDERS} from "../../../function/orders";
import davatar from "../../images/davatar.png";
import cashoutimg from "../../images/cashoutimg.png";
import service from "../../images/service.png";
import customer from "../../images/customer.png";
import ordersimg from "../../images/ordersimg.png";
import transferimg from "../../images/transferimg.png";
import walletimg from "../../images/walletimg.png";
import {GETSPTOTALBALANCE} from "../../../function/serviceprovider";
import AdminChart from "./adminchart";
import Credit from "../credit";
import moment from "moment";

const Dashboard = () => {
    const [ordersFa, setOrdersFa] = useState([]);
    const [transActionsCount, setTransActionsCount] = useState();
    const [orders, setOrders] = useState();
    const [casheout, setCasheout] = useState();
    const [cardsTotal, setCardsTotal] = useState();
    const [userCount, setUserCount] = useState();
    const [customersCount, setCustomersCount] = useState();
    const [balance, setBalance] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [totalbalance, setTotalbalance] = useState([]);
    const [totalCompletedOrders, setTotalCompletedOrders] = useState();
    const [onProccess, setOnProccess] = useState();
    const [Rejected, setRejected] = useState();
    const [lastOrder, setLastOrder] = useState("");
    const [bankOrders, setBankOrders] = useState();
    const [cardOrders, setCardOrders] = useState();
    const [paypalOrders, setPaypalOrders] = useState();
    const [totalBankPurchase, setTotalBankPurchase] = useState();
    const [totalCardPurchase, setTotalCardPurchase] = useState();
    const [totalPaypalPurchase, setTotalPaypalPurchase] = useState();
    const [lastTrans, setLastTrans] = useState([]);
    const [lastCash, setLastCash] = useState([]);
    const [adminbalance, setAdminbalance] = useState();
    const [dollarAdminBalance, setDollarAdminBalance] = useState();
    const [pPAF, setPPAF] = useState();
    const [netTotal, setNettotal] = useState();
    const [totalDollarBalance, setTotalDollarBalance] = useState();

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        GETNETTOTALAF(user.token).then((res) => {
            setNettotal(res.data)

        })
        GETTOTALCARDPURCHASEAF(user.token).then((res) => {
            setTotalCardPurchase(res.data.toFixed(2))
        })
        GETTOTALPAYPALPURCHASEAF(user.token).then((res) => {
            setPPAF(res.data.toFixed(2))
        })
        CHECKADMIN(user.token).then((res) => {
            setAdminbalance(res.data.adminbalance.toFixed(2))
            setDollarAdminBalance(res.data.admindollardynmicbalance.toFixed(2));

        })
        GETLASTCASHOUTREQUEST(user.token).then((res) => {
            setLastCash(res.data)
        })
        GETLASTTRANSACTIONS(user.token).then((res) => {
            setLastTrans(res.data)
        })
        GETTOTALBANKPURCHASE(user.token).then((res) => {
            setTotalBankPurchase(res.data.toFixed(2))
            // console.log("totalBankPurchase",totalBankPurchase)
        })
        // GETTOTALCARDPURCHASE(user.token).then((res)=>{
        //   setTotalCardPurchase(res.data)
        // //  console.log("totalBankPurchase",res.data)
        // })
        GETTOTALPAYPALPURCHASE(user.token).then((res) => {
            setTotalPaypalPurchase(res.data.toFixed(2))
            //  console.log("totalBankPurchase",res.data)
        })
        GETPO(user.token).then((res) => {
            setPaypalOrders(res.data)
        })
        GETCO(user.token).then((res) => {
            setCardOrders(res.data)
        })
        GETBO(user.token).then((res) => {
            setBankOrders(res.data)
        })
        GETLAST(user.token).then((res) => {
            setLastOrder(res.data)
        })
        GETORDERSRejected(user.token).then((res) => {
            setRejected(res.data)
        })
        GETORDERSONPROCCESS(user.token).then((res) => {
            setOnProccess(res.data)
        })
        GETORDERSCOMPLETED(user.token).then((res) => {
            setTotalCompletedOrders(res.data)
        })
        GETSPTOTALBALANCE(user.token).then((res) => {
            console.log(res.data.eurobalance);
            setTotalbalance(res.data.eurobalance)
            setTotalDollarBalance(res.data.dollarsum);

        })
        CHECKADMIN(user.token).then((res) => {
            setBalance(res.data.balance.toFixed(2));
        })
        cashoutrequesttotal().then((res) => {
            // console.log("res.data--------->".res.data);
            setCasheout(res.data)
        })
        getorderscount().then((res) => {
            setOrders(res.data)
        })
        getCardsTotal().then((res) => {
            setCardsTotal(res.data)
        })
        getUsersCount().then((res) => {
            setUserCount(res.data)
        })
        GETCUSTOMERSCOUNT(user.token).then((res) => {
            setCustomersCount(res.data)
        })
        GETAllTRANSACTIONSCOUNT(user.token).then((res) => {
            setTransActionsCount(res.data)
        })

        GETORDERS(user.token).then((res) => {
            {
                res.data.map((order) => {
                    setOrdersFa(order);
                    //  console.log("ooooooooooo",order.paymentIntent)
                });
            }
        });
    };
    const logout = () => {
        firebase.auth().signOut();
        dispatch({
            type: "LOGE_OUT",
            payload: null,
        });
        navigate("/login");
    };
    const {user} = useSelector((state) => ({...state}));
    return (
        <div className="container-fluid admindashboard">
            <h1 className="pt-5 text-dark">Admin Dashboard</h1>
            <div className="row">
                <div className="col-md-4">
                    <Card
                        className="headCards"
                        style={{textAlign: "", marginTop: "15px"}}
                    >
                        <Card.Body>
                            <Card.Title>
                                <div className=" text-capitalize">
                                    {user.name}{" "}
                                    <div className="float-end">
                                        <img width={"100px"} src={davatar}/>
                                    </div>
                                </div>
                                {" "}
                            </Card.Title>
                            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                            <Card.Text>
                                <div className="container ">
                                    <div className="row pt-1">
                                        <div className="col-8 ">{user.email}</div>
                                        <b className="text-primary">
                                            EURU Balance: €
                                            {adminbalance ? adminbalance : "no data for now"}
                                        </b>
                                        <br/>

                                        <b className="text-primary">
                                            Dollar Balance: $
                                            {dollarAdminBalance
                                                ? dollarAdminBalance
                                                : "no data for now"}
                                        </b>
                                    </div>

                                    <div className=" mt-3">
                                        <Link to="/profile">
                                            {" "}
                                            <CgProfile className="h3 ms-3 text-primary"/>
                                        </Link>
                                        <Link to="/admin/setting">
                                            {" "}
                                            <AiOutlineSetting className="h3 text-warning ms-3"/>
                                        </Link>
                                        <BiLogOut
                                            style={{cursor: "pointer"}}
                                            onClick={logout}
                                            className="h3 text-danger ms-3"
                                        />
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card
                        className="headCards"
                        style={{textAlign: "", marginTop: "15px"}}
                    >
                        <Card.Body>
                            <Card.Title>
                                <div className=" text-capitalize">
                                    Service Providers{" "}
                                    <b className="text-primary">
                                        {userCount ? userCount : "no data for now"}
                                    </b>{" "}
                                    <div className="float-end">
                                        <img width={"70px"} src={service}/>
                                    </div>
                                </div>
                                {" "}
                            </Card.Title>

                            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                            <Card.Text>
                                <div className="container ">
                                    <div className=" mt-2">
                                        {totalbalance ? (
                                            <h5>
                                                {" "}
                                                <h6>
                                                    EURO Total Balance:{" "}
                                                    <b className="text-success">
                                                        €{Number(totalbalance).toFixed(2)}
                                                    </b>
                                                    <br/>
                                                    Dollar Total Balance
                                                    <b className="text-success">
                                                        {" "}
                                                        ${Number(totalDollarBalance).toFixed(2)}
                                                    </b>
                                                </h6>
                                                {/* {JSON.stringify(totalbalance)}
                        {JSON.stringify(totaldollarbalance)} */}
                                            </h5>
                                        ) : (
                                            "No data for now"
                                        )}
                                        <Avatar.Group
                                            maxCount={2}
                                            size="large"
                                            maxStyle={{
                                                color: "#f56a00",
                                                backgroundColor: "#fde3cf",
                                            }}
                                        >
                                            <Avatar src="https://joeschmoe.io/api/v1/random"/>
                                            <Avatar
                                                style={{
                                                    backgroundColor: "#f56a00",
                                                }}
                                            >
                                                K
                                            </Avatar>
                                            <Tooltip title="Ant User" placement="top">
                                                <Avatar
                                                    style={{
                                                        backgroundColor: "#87d068",
                                                    }}
                                                    icon={<UserOutlined/>}
                                                />
                                            </Tooltip>
                                            <Avatar
                                                style={{
                                                    backgroundColor: "#1890ff",
                                                }}
                                                icon={<AntDesignOutlined/>}
                                            />
                                        </Avatar.Group>
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    <Card
                        className="headCards"
                        style={{textAlign: "", marginTop: "15px"}}
                    >
                        <Card.Body>
                            <Card.Title>
                                <div className=" text-capitalize">
                                    Customers{" "}
                                    <div className="float-end">
                                        <img width={"70px"} src={customer}/>
                                    </div>
                                </div>
                                {" "}
                            </Card.Title>

                            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                            <Card.Text>
                                <div className="container ">
                                    <h5 className=" mt-1">
                                        Total :{" "}
                                        <b className="text-primary">
                                            {" "}
                                            {customersCount ? customersCount : "No data for now"}
                                        </b>
                                    </h5>

                                    <div className=" mt-2">
                                        {lastOrder ? (
                                            <h6>
                                                last purchase:
                                                <b className="text-dark">
                                                    {moment(lastOrder).format("D/M/Y h:mm A")}
                                                </b>
                                            </h6>
                                        ) : (
                                            "No data for now"
                                        )}
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-md-7">
                    <Card
                        className="headCards"
                        style={{textAlign: "", marginTop: "15px"}}
                    >
                        <Card.Body>
                            <Card.Title>
                                <div className=" text-capitalize">
                                    Orders{" "}
                                    <div className="float-end">
                                        <Dropdown>
                                            <Dropdown.Toggle className="monthsdropdown">
                                                Compared to January
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="w-100">
                                                <Dropdown.Item href="#/action">January</Dropdown.Item>
                                                <Dropdown.Item href="#/action">February</Dropdown.Item>
                                                <Dropdown.Item href="#/action">March</Dropdown.Item>
                                                <Dropdown.Item href="#/action">April</Dropdown.Item>
                                                <Dropdown.Item href="#/action">May</Dropdown.Item>
                                                <Dropdown.Item href="#/action">June</Dropdown.Item>
                                                <Dropdown.Item href="#/action">July</Dropdown.Item>
                                                <Dropdown.Item href="#/action">August</Dropdown.Item>
                                                <Dropdown.Item href="#/action">September</Dropdown.Item>
                                                <Dropdown.Item href="#/action">October</Dropdown.Item>
                                                <Dropdown.Item href="#/action">November</Dropdown.Item>
                                                <Dropdown.Item href="#/action">December</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <br/>
                                    <small>Monthly</small>
                                </div>
                                {" "}
                            </Card.Title>

                            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                            <Card.Text>
                                <div className="container ">
                                    <div className="row">
                                        <div className="col-12">
                                            <Card
                                                className="headCardschart"
                                                style={{textAlign: "", marginTop: "15px"}}
                                            >
                                                <Card.Body>
                                                    <Card.Title>
                                                        <div className=" text-capitalize">
                                                            Sales <div className="float-end"></div>
                                                        </div>
                                                        {" "}
                                                    </Card.Title>

                                                    <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                                                    <Card.Text>
                                                        <AdminChart/>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        {/* /////////// */}
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-5">
                    <Card
                        className="OrdersSummary "
                        style={{textAlign: "", marginTop: "15px"}}
                    >
                        <Card.Body>
                            <Card.Title>
                                <div className=" text-capitalize">
                                    Orders Summary{" "}
                                    <div className="float-end">
                                        <img width={"100px"} src={ordersimg}/>
                                    </div>
                                </div>
                                {" "}
                            </Card.Title>

                            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                            <Card.Text>
                                <div className="container ">
                                    <h5 className=" mt-1">
                                        Total Orders: <b className="text-primary"> {orders}</b>
                                    </h5>

                                    <div className=" mt-2">
                                        <h5>
                                            net total payments purchase :
                                            <b className="text-primary">€{netTotal || 0}</b>
                                        </h5>
                                        <h5>
                                            Total BankTransfre purchase :
                                            <b className="text-primary">€{totalBankPurchase || 0}</b>
                                        </h5>
                                        <h5>
                                            Total card purchase :
                                            <b className="text-primary"> €{totalCardPurchase || 0}</b>
                                        </h5>
                                        <h5>
                                            Total Paypal purchase :
                                            <b className="text-primary"> €{pPAF || 0}</b>
                                        </h5>
                                        <hr/>
                                        <h5>
                                            Total BankTransfre Orders :
                                            <b className="yellow">{bankOrders || 0}</b>
                                        </h5>
                                        <h5>
                                            Total card Orders :
                                            <b className="yellow"> {cardOrders || 0}</b>
                                        </h5>
                                        <h5>
                                            Total Paypal Orders :
                                            <b className="yellow"> {paypalOrders || 0}</b>
                                        </h5>
                                        <hr/>
                                        <h5>
                                            Completed Orders :
                                            <b className="text-success">
                                                {" "}
                                                {totalCompletedOrders || 0}
                                            </b>
                                        </h5>
                                        <h5>
                                            On Proccess Orders :
                                            <b className="text-mutted"> {onProccess || 0}</b>
                                        </h5>
                                        <h5>
                                            Rejected Orders :
                                            <b className="text-danger"> {Rejected || 0}</b>
                                        </h5>
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5">
                    <Card
                        className="primaryCard"
                        style={{textAlign: "", marginTop: "15px"}}
                    >
                        <Card.Body>
                            <Card.Title>
                                <div className=" text-capitalize">
                                    Cashout Requests{" "}
                                    <b className="text-primary">
                                        {" "}
                                        {casheout ? casheout : "No data for now"}
                                    </b>{" "}
                                    <div className="float-end">
                                        <img width={"70px"} src={cashoutimg}/>
                                    </div>
                                </div>
                                {" "}
                            </Card.Title>

                            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                            <Card.Text>
                                <div className="container ">
                                    <div className=" mt-3">
                                        <h5>Last Request</h5>
                                    </div>
                                    <div className="row">
                                        {lastCash.map((lc) => {
                                            return (
                                                <>
                                                    {lc ? (
                                                        <div className="">
                                                            Requested By:
                                                            <br/>
                                                            <Avatar
                                                                style={{
                                                                    color: "#f56a00",
                                                                    backgroundColor: "#fde3cf",
                                                                }}
                                                                size="large"
                                                            >
                                                                {lc.requestedBy.name.slice(0, 1)}
                                                            </Avatar>{" "}
                                                            <b>{lc.requestedBy.name}</b>{" "}
                                                            <b className="ms-2 me-3 text-success">
                                                                {lc.currency === "usd" ? "$" : "€"}{" "}
                                                                {lc.cashoutamount}
                                                            </b>{" "}
                                                            Req Date :
                                                            <b className=" text-danger">
                                                                {moment(lc.createdAt).format("D/M/Y h:mm A")}
                                                            </b>
                                                            <br/>
                                                            <h6 className="ms-5">
                                                                Status:{" "}
                                                                <b
                                                                    className={
                                                                        lc.requeststatus === "Completed"
                                                                            ? "text-success"
                                                                            : "text-danger"
                                                                    }
                                                                >
                                                                    {lc.requeststatus}
                                                                </b>
                                                            </h6>
                                                        </div>
                                                    ) : (
                                                        "No data for now"
                                                    )}
                                                    <div></div>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <div className="row mt-2 ">
                                        <div className="d-flex justify-content-center">
                                            <Link to="/cacheout">
                                                {" "}
                                                <Button className="btn btn-light">Details</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-md-7">
                    <Card
                        className="headCards"
                        style={{textAlign: "", marginTop: "15px"}}
                    >
                        <Card.Body>
                            <Card.Title>
                                <div className=" text-capitalize text-center">
                                    TransActions
                                    <b className="text-primary">
                                        {transActionsCount ? transActionsCount : "No data for now"}
                                    </b>{" "}
                                    <div className="float-end">
                                        <img width={"120px"} src={transferimg} alt="transfer img"/>
                                    </div>
                                </div>
                                {" "}
                            </Card.Title>

                            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                            <Card.Text>
                                <div className="container ">
                                    <div className=" mt-3">
                                        <h5>Last Transfer</h5>
                                    </div>
                                    <div className="row">
                                        {lastTrans ? (
                                            <div>
                                                {lastTrans.map((lt) => {
                                                    return (
                                                        <>
                                                            <div className="col-6">
                                                                <h6 className="text-danger">Sender</h6>
                                                                <Avatar
                                                                    style={{
                                                                        color: "#f56a00",
                                                                        backgroundColor: "#fde3cf",
                                                                    }}
                                                                    size="large"
                                                                >
                                                                    {lt.sender.name.slice(0, 1)}
                                                                </Avatar>{" "}
                                                                <b>{lt.sender.name}</b>{" "}
                                                                <b className="ms-2 me-3 text-success">
                                                                    {lt.currency === "usd" ? "$" : "€"}{" "}
                                                                    {lt.sentamount}
                                                                </b>{" "}
                                                                Req Date :
                                                                <b className=" text-danger">
                                                                    {moment(lt.createdAt).format("D/M/Y h:mm A")}
                                                                </b>
                                                            </div>

                                                            <div className="col-6">
                                                                <h6 className="text-primary">Reciever</h6>
                                                                <Avatar
                                                                    style={{
                                                                        color: "#f56a00",
                                                                        backgroundColor: "#fde3cf",
                                                                    }}
                                                                    size="large"
                                                                >
                                                                    {lt?.receiver?.name.slice(0, 1)}
                                                                </Avatar>{" "}
                                                                <b>{lt?.receiver?.name}</b>{" "}
                                                                <b className="ms-2 me-3 text-success">
                                                                    {lt.currency === "usd" ? "$" : "€"}{" "}
                                                                    {lt.receivedamount}
                                                                </b>{" "}
                                                                Status :
                                                                <b className=" text-success">
                                                                    {lt.requeststatus}
                                                                </b>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            "No data for now"
                                        )}
                                    </div>
                                    <div className="row mt-3 ">
                                        <div className="d-flex justify-content-center">
                                            <Link to="/admin/transactions">
                                                {" "}
                                                <Button className="btn btn-light">Details</Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};
export default Dashboard;
