import React ,{Fragment }from 'react';

import { Link, useParams } from 'react-router-dom';
import { PDFViewer, ReactPDF, PDFDownloadLink } from "@react-pdf/renderer";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { Image} from '@react-pdf/renderer';
import logo from '../../images/logo.png'
import InvoiceTitle from './invoicetitle'
import { useEffect } from 'react';
import { INVOICEORDER } from '../../../function/orders';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import moment from "moment";
import { AddressElement } from '@stripe/react-stripe-js';
const OrderInvoice=()=>{
    const {user}=useSelector((state)=>({...state}))
    const [order,setOrder]=useState([])
    const[serialnumber,setSerialnumber]=useState()
    const[date,setDate]=useState()
    const [email,setEmail]=useState('')
    const [name,setName]=useState('')
    const [address,setAddress]=useState('')
    const [paymentType,setPaymentType]=useState('')
    const[paymentIntent,setPaymentIntent]=useState([])
    const [codes,setCodes]=useState([])
    let { _id } = useParams()
      useEffect(()=>{
        loadOrder()
    },[])

    const loadOrder=()=>{
        INVOICEORDER(user.token,_id).then((res)=>{
                setOrder(res.data)
                {res.data.map((o)=>{
      setSerialnumber(o.serialnumber)
      setDate(o.createdAt)
      setEmail(o.email)
      setName(o.name)
      setAddress(o.address)
     setPaymentIntent(o.paymentIntent)
     setCodes(o.cards)
                })}
          
        })
    }
    const DATE=moment(date).format('D/M/Y ')
const PDF = () => {

    const styles = StyleSheet.create({
      page: {
        flexDirection: "row",
        backgroundColor: "white"
      },
      section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
      },
      table: {
        width: "100%"
      },
      row: {
        display: "flex",
        flexDirection: "row",
        borderTop: "1px solid #EEE",
        paddingTop: 8,
        paddingBottom: 8
      },
      header: {
        borderTop: "none"
      },
      bold: {
        fontWeight: "bold"
      },
      // So Declarative and unDRY 👌
      row1: {
        width: "27%"
      },
      row2: {
        width: "15%"
      },
      row3: {
        width: "15%"
      },
      row4: {
        width: "20%"
      },
      row5: {
        width: "27%"
      },
         page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
      invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60
    },
      headerContainer: {
        marginTop: 36
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
    
    titleContainer:{
        flexDirection: 'row',
        marginTop: 12,
        textAlign:'center',
       alignItems: 'center',
    },
    reportTitle:{
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
      container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: 'green',
        borderBottomWidth: 1,
        color:'white',
        height: 90,
        marginTop:'5%',

        fontStyle: 'bold',
   
    },
    description: {
        marginTop:'1%',
        width: '30%',
        textAlign:'center',
       alignItems: 'center',
        borderRightColor: 'silver',
        borderRightWidth: 1,
    },
    qty: {
           marginTop:'1%',
        width: '30%',
        borderRightColor: 'silver',
        borderRightWidth: 1,
    },
    rate: {
           marginTop:'1%',
       
        borderRightColor:'silver',
        borderRightWidth: 1,
    },
    amount: {
           marginTop:'1%',
    
    },
      code: {
           marginTop:'1%',
        width: '30%',
        borderRightColor: 'silver',
     
    },
    expire: {
           marginTop:'1%',
         width: '30%',
        borderLeftColor:'silver',
       
    },
    subscribe: {
           marginTop:'1%',
           marginLeft:'5px',
             borderLeftColor:'silver',
    },
    codescontainer:{
        marginTop:'3%',
          flexDirection: 'row',
        borderBottomColor: 'silver',
        backgroundColor: 'silver',
        borderBottomWidth: 1,
        color:'black',
        height: 24,
        marginTop:'5%',
        textAlign:'center',
       alignItems: 'center',
        fontStyle: 'bold',

    }
    });
    const data = {
     
      items: [
        {
          sr: 1,
          desc: "desc1",
          xyz: 5
        },
        {
          sr: 2,
          desc: "desc2",
          xyz: 6
        }
      ]
    };
  
    return (
      <Document>
                <Page size="A4" style={styles.page}>
                    <Image style={styles.logo} src={logo} />
                    <InvoiceTitle title='Tathkeer'/>
                    <Fragment>
            <View style={styles.invoiceNoContainer}>
                <Text style={styles.label}>Invoice No:</Text>
                <Text style={styles.invoiceDate}>{serialnumber}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Date: </Text>
                <Text >{DATE}</Text>
            </View >
            <View style={styles.headerContainer}>
              <Text style={styles.billTo}>Bill To:</Text>
              <Text>{email}</Text>
              <Text>{name}</Text>
              <Text>{address}</Text>
        
    </View>
      <View style={styles.codescontainer}>
        <Text style={styles.description}>Payment: {paymentIntent.map((pi)=><> {pi.paymenttype}</>)}</Text>
        <Text style={styles.qty}>Number Of Codes:{paymentIntent.map((pi)=><> {pi.cardsNumber}</>)} </Text>
   
        <Text style={styles.amount}>Amount: {paymentIntent.map((pi)=><> €{pi.amount}</>)}</Text>
    </View>
        {codes.map((c)=>{
            return(<>  <View style={styles.container}>
   
             <Text style={styles.code}>Code: {c.code}</Text>
        <Text style={styles.expire}>expire:{moment(c.Expire).format('D/M/Y ')} </Text>
        <Text style={styles.subscribe}>subscribe: {c.subscribe}</Text>
         
        
    </View>   </>)
        })}
   

 <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>Thank you for your trust in TATHKEER</Text>
    </View>
    

    
       
            
            
            
            </Fragment> 
                    {/* <InvoiceNo invoice={invoice}/>
                    <BillTo invoice={invoice}/>
                    <InvoiceItemsTable invoice={invoice} />
                    <InvoiceThankYouMsg /> */}
                </Page>
            </Document>
    );
  };
  
    return(<>
    
    <div className='container-fluid'>
            <div className="row">
                <div className='col'>
        <div className="what">
            {/* {JSON.stringify(paymentIntent)} */}
  
      <PDFDownloadLink document={<PDF />} fileName="TATHKEERINVOCE.pdf">
        {({ blob, url, loading, error }) =>
      {
        return(<>
           <div className='container '>
                <div className='row d-flex justify-content-center mt-5'>
                       
                           <div className='col-4' > <a  classNme="text-white " href={`${url}`}target="_blank"><button className='
                           btn btn-primary '>Preview</button></a><br/></div>
                     
                </div>
                <div className='row d-flex justify-content-center mt-5'>
                     <div className='  col-4'>
                     <button className='btn btn-success'> {   loading ? "Loading document..." : "Download now!"} </button>
                     </div>
                </div>


           </div>
        </>)
     }
        }
      </PDFDownloadLink>
    </div>
                </div>
            </div>

    </div>
    </>)

}
export default OrderInvoice 