import React from 'react';
import "../css/cancelpage.css";
const CancellationPrivacy=()=>{
    return (
      <>
        <div className="container cancelpage">
          <div className="row mt-5 mb-5">
            <div className="container mt-5 mb-5">
              <div className="row mb-5">
                <h1 className="text-center fw-bolder">
                  The cancellation policy for thkeer.net is as follows:
                </h1>
              </div>
              <div className="row">
                <div className="col">
                  <h4>
                    1-Cancellations must be made in writing and sent to
                    <a
                      href="mailto:info@thkeer.net?body=Hello%20Team%2C%0A%0A%0A%0A%20Sincerely%2C%0A[Your%20Name]"
                      target="_blank"
                    >
                      info@thkeer.net
                    </a>
                  </h4>
                  <h4>
                    2-Cancellations made more than 14 days before the start of
                    the service will be refunded in full.
                  </h4>
                  <h4>
                    3-Cancellations made between 7 and 14 days before the start
                    of the service will be refunded 50% of the total cost.
                  </h4>
                  <h4>
                    4-Cancellations made less than 7 days before the start of
                    the service will not be eligible for a refund.
                  </h4>
                  <h4>
                    5-In the event that thkeer.net cancels a service, customers
                    will be refunded in full.
                  </h4>
                  <h4>
                    6-Any changes or modifications to the service must be made
                    in writing and sent to{" "}
                    <a
                      href="mailto:info@thkeer.net?body=Hello%20Team%2C%0A%0A%0A%0A%20Sincerely%2C%0A[Your%20Name]"
                      target="_blank"
                    >
                      info@thkeer.net
                    </a>{" "}
                    Additional fees may apply for changes made less than 15 days
                    before the start of the service.
                  </h4>
                  <h4>
                    7-By agreeing to the terms and conditions of thkeer.net,
                    customers are agreeing to the cancellation policy outlined
                    above.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default CancellationPrivacy;