import React, { Component,useEffect, useState } from 'react';
import '../css/cityandcontry.css'
// note that you can also export the source data via CountryRegionData. It's in a deliberately concise format to 
// keep file size down
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Register from '../pages/register';


const  Example=({selected,setSelected})=>{
const RegisterForm=()=>{
  <Register contry={contry} setContry={setContry} region={region} setRegion={setRegion}/>
}
const[defaultContry,setDefaultContry]=useState()
const [contry,setContry]=useState()
const [region,setRegion]=useState()

 const selectCountry =(val)=> {
  setContry(val);
  setSelected(false)
    window.localStorage.setItem("contry",val)
// window.localStorage.setItem("Defaultcontry",val)
  }

  // const selectRegion =(value)=> {
  // setRegion(value);
  //   window.localStorage.setItem("region",value)
  // }

let callApi = async () => {
        try {
               fetch("https://ipapi.co/json/")
                 .then(res => res.json())
                .then((res) =>{ 

                    
                  window.localStorage.setItem("Defaultcontry",res.country_name)
                   setDefaultContry(res.country_name)
                 
             
                  
                 

                }
               
                 
                     )
            } catch (error) {
            // console.log(error)
        }
    };
   useEffect(()=>{

    callApi();

    setInterval(callApi, 1000 * 60 * 60)

   
    if(selected){
      setContry('')
      setRegion('')
    }
   },[])

    return (
      
      <div>
 {/* {console.log("cons",contry)} */}

        <CountryDropdown
      defaultOptionLabel={defaultContry}
          value={contry}
      className="  mt-1 select w-100  text-dark" 
          onChange={(val) => selectCountry(val)} />
        {/* <RegionDropdown 
          required={true}
          country={contry}
          value={window.localStorage.getItem('region')}
          className="  mt-3 select"
          onChange={selectRegion} /> */}
      </div>
    );
  }

export default Example