import React from 'react'
import { useState } from 'react';
import { UserOutlined,MailOutlined,ControlOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { SettingOutlined } from '@ant-design/icons';
import { Input, Tooltip,Button } from 'antd';
import { Link } from 'react-router-dom';
import {toast} from 'react-toastify'
import { CRUDUSER, LOGIN } from '../../function/auth';
import { DeleteOutlined, EditFilled,CopyOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import { useEffect } from 'react';
import { auth } from "../../firebase";
import '../css/profile.css'
import avatar from '../images/vector.png'
const  Profile=()=> {
 
  const [role, setRole] = useState()

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const [value, setValue] = useState('sp');

  const [count, setCount] = useState(0)
  let { user } = useSelector((state) => ({ ...state }));
 



  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
 

 
       
  }






const handleCopyText=()=>{
  navigator.clipboard.writeText(user.pid)
  toast.success(` ${user.pid} Copied `)
}


  return (
    <div className='container-fluid profilepage'>
      
        <h1 className='text-center text-dark pt-5'>PROFILE Details</h1>
   
      <div className='row mt-5 d-flex justify-content-center border'>
             <div className=' col-md-2 col-sm-12 p-5 mt-5  rounded'>
               {/* <Avatar size={150} icon={<UserOutlined  className='text-primary'/>} /> */}
               <img src={avatar} alt='avatar' className='img-fluid'/>
               </div>

 
          <div className=' col-md-5 col-sm-12 p-5  rounded'>
             

   
    
     <div className='mt-3 mb-2'>
            Public Id<br/>
           <Input disabled
        style={{
          width: 'calc(100% - 35px)',marginBottom:'2%'
        }}
        value={user.pid}
      />
      <Tooltip title="copy id">
        <Button icon={<CopyOutlined />} onClick={handleCopyText} />
      </Tooltip>
            </div>

 <div className='mt-1 mb-2'>
           Name<br/>
           <Input disabled
       
        value={user.name}
      />
    
            </div>







      <div className=''>
        <div className='mt-1 mb-2'>
           email<br/>
           <Input disabled
       
        value={user.email}
      />
      
      
            </div>
       <p className='text-center'>service provider level</p>
     <div className='mt-1 mb-2'>
       
           <Input disabled
       
        value={user.splevel}
      />
      
      
            </div>
            {/* <Input disabled
    value={user.role}
      prefix={<ControlOutlined className="site-form-item-icon" />}
      suffix={
        <Tooltip title="Edite">
         </Tooltip>
        }
      /> */}
      
      </div>


{/* {user && user.joinedTime && (<div className="col">
 
<div className="form-group d-flex justify-content-center mb-3"><label class="form-label">Joind in <input disabled type="text " class="form-control text-center" name="email" value={user.joinedTime.substring(0, 10)} /></label></div>
</div>)} */}
          <div className='mt-3 d-flex justify-content-center'>
          {/* <button className='btn btn-success me-2'
                                      
                                       type='submit' > save</button> */}
</div>
             {/* (<button disabled={values.name.length > 15 || values.name.length === 0} className={values.name.length < 1 ? 'btn btn-danger ml-2' : values.name.length === 0 ? "btn btn-danger ml-2" : "btn btn-success ml-2"} type='submit' > save</button>)} */}
            {/* <Link to='/password'><button className='btn btn-danger w-100'>ChangePassword</button></Link> */}
          </div>
   
       
          </div>

  
      </div>
    
  )   
}
export default Profile