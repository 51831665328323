import axios from "axios";

export const CREATEEXTERNALTRANSFER = async (
  authtoken,
  from,
  amount,
  fees,
  to,
  convertedamount,
  finalAmount
) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/external/`,
    {
      from,
      amount,
      fees,
      to,
      convertedamount,
      finalAmount,
    },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const GETEXTERNAL = async (authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/external`,

    {
      headers: {
        authtoken,
      },
    }
  );
};
