import React from 'react';
import { Drawer,Button } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {MenuUnfoldOutlined}from '@ant-design/icons';
import logo from '../images/logo.png'

import {MailOutlined, AppstoreOutlined,SettingOutlined} from "@ant-design/icons";
import { UserOutlined,HistoryOutlined ,ArrowDownOutlined} from "@ant-design/icons";
import { AiOutlineSetting } from "react-icons/ai";
import { GoGraph } from "react-icons/go";
import { BiLogOut } from "react-icons/bi";
import {  useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import NewSide from '../pages/newSide';
import { FcDataSheet,FcBullish,FcMoneyTransfer,FcBriefcase,FcInTransit,FcMenu,FcM} from "react-icons/fc"
import{GrTransaction}from 'react-icons/gr'
import{GiReceiveMoney}from 'react-icons/gi'
import { BiUserPlus } from 'react-icons/bi';
const ControlDrawer=()=>{
  const navigate = useNavigate();
    const Close=()=>{
     
            /// close sideDrawe
            dispatch({
                type: "SET_VISIBLE2",
                payload: false,
            });     
 
    }
    const dispatch=useDispatch()
    const {drawer2,user}=useSelector((state)=>({...state}))
   
   
    return(
        
    <Drawer 
  
    placement='right'
    
 
    title={<></>} 
   size={{
      xs: 24,
      sm: 32,
      md: 40,
      lg: 64,
      xl: 80,
      xxl: 100,
    }}
    onClose={()=>{
            /// close sideDrawe
            dispatch({
                type: "SET_VISIBLE2",
                payload: false,
            });     
    }} visible={drawer2}>
       
       {user&&user.role==='admin'&&(<><div className='container-fluid'>
                <div className='row'>
                    <h4 className='text-dark text-capitalize text-center'>Control Panel</h4>   
            
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/admin/dashboard'><Button onClick={Close}   icon={<FcDataSheet  className='h6 text-danger me-2'/>} type="link"> Dashboard</Button></Link>
                </div>
                {/* <div className='row mt-3'>
 <br/><Link to='/admin/analysis'><Button onClick={Close}   icon={<FcBullish  className='h6  me-2 '/>} type="link">Analysis</Button></Link>
                </div> */}
                 <div className='row mt-3'>
 <br/><Link to='/serviceprovider' onClick={Close} ><Button     icon={<FcBriefcase  className='h6 text-warning  me-2 '/>} type="link">Serviceprovider</Button></Link>
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/admin/logs' onClick={Close} ><Button     icon={<FcBriefcase  className='h6 text-warning  me-2 '/>} type="link">Logs</Button></Link>
                </div>
                <div className='row mt-3'>
 <br/><Link to='/admin/orders' onClick={Close} ><Button     icon={<FcInTransit  className='h6 text-warning  me-2 '/>} type="link">Orders</Button></Link>
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/admin/Wallet' onClick={Close} ><Button     icon={<FcMoneyTransfer  className='h6 text-warning  me-2 '/>} type="link">Wallet</Button></Link>
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/admin/cashout' onClick={Close} ><Button     icon={<GiReceiveMoney  className='h6 text-success  me-2 '/>} type="link">Cashout Bank</Button></Link>
                </div>
                <div className='row mt-3'>
 <br/><Link to='/admin/cashoutcrypto' onClick={Close} ><Button     icon={<GiReceiveMoney  className='h6 text-danger  me-2 '/>} type="link">Cashout Crypto</Button></Link>
                </div>

                           <div className='row mt-3'>
 <br/><Link to='/admin/transactions' onClick={Close} ><Button     icon={<GrTransaction  className='h6 text-warning  me-2 '/>} type="link">TransAction Requests</Button></Link>
                </div>
                           <div className='row mt-3'>
 <br/><Link to='/admin/cutsomers' onClick={Close} ><Button     icon={<BiUserPlus  className='h6 text-warning  me-2 '/>} type="link">Customers</Button></Link>
                </div>
                        
            </div>
                </>)}
     
{user&&user.role==='serviceprovider'&&(<><div className='container-fluid'>
                <div className='row'>
                    <h4 className='text-dark text-capitalize text-center'>Control Panel</h4>   
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/serviceprovider/dashboard'><Button onClick={Close}   icon={<FcDataSheet  className='h6 text-danger me-2'/>} type="link"> Dashboard</Button></Link>
                </div>
                
                 <div className='row mt-3'>
 <br/><Link to='/serviceprovider/ordersdetails' onClick={Close} ><Button     icon={<FcInTransit  className='h6 text-warning  me-2 '/>} type="link">Orders</Button></Link>
                </div>
                <div className='row mt-3'>
 <br/><Link to='/serviceprovider/wallet' onClick={Close} ><Button     icon={<FcMoneyTransfer  className='h6 text-warning  me-2 '/>} type="link">wallet</Button></Link>
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/serviceprovider/cashout' onClick={Close} ><Button     icon={<GiReceiveMoney  className='h6 text-warning  me-2 '/>} type="link">cashout</Button></Link>
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/serviceprovider/customers' onClick={Close} ><Button     icon={<BiUserPlus  className='h6 text-warning  me-2 '/>} type="link">Customers</Button></Link>
                </div>                     
            </div>
                </>)}

       
    
    </Drawer>
   )
}
export default ControlDrawer