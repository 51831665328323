import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../css/history.css";
import {useState} from "react";
import {Collapse} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {Input, Space} from "antd";
import {useEffect} from "react";
import {GETSPCASHOUTREQUEST} from "../../../function/cashout";
import {useSelector} from "react-redux";
import moment from "moment";
import {GETSPTRANSACTIONS} from "../../../function/transaction";
import {Link} from "react-router-dom";
import {Radio, Tabs} from "antd";

import Nav from "react-bootstrap/Nav";

import Tab from "react-bootstrap/Tab";
import {GETINTERNAL} from "../../../function/internal";

const SPHistory = () => {
    const [internals, setInternals] = useState([]);
    const [cashOutRequests, setCashOutRequests] = useState([]);
    const [sender, setSender] = useState();
    const [receiver, setReceiver] = useState();
    const [cashoutamountdollar, setCashoutamountdollar] = useState();
    const [receivedTransAction, setReceivedTransaction] = useState([]);
    const [sentTransAction, setSentTransAction] = useState([]);
    let {user} = useSelector((state) => ({...state}));

    useEffect(() => {
        load();
    }, []);
    const load = () => {
        GETINTERNAL(user.token).then((res) => {
            setInternals(res.data);
        });
        GETSPCASHOUTREQUEST(user.token).then((res) => {
            setCashOutRequests(res.data);
            {
                res.data.map((co) => {
                    setCashoutamountdollar(co.cashoutamountdollar);
                });
            }
        });
        // history page updateds
        GETSPTRANSACTIONS(user.token, user._id).then((res) => {
            console.log("history page updateds", res.data);
            setReceivedTransaction(res.data.finSpTransReciver);
            {
                res.data.finSpTransReciver.map((ftr) => {
                    setSender(ftr.sender);
                });
            }
            setSentTransAction(res.data.finSpTransSender);
            {
                res.data.finSpTransSender.map((fts) => {
                    setReceiver(fts.receiver);
                });
            }
        });
    };

    const {Panel} = Collapse;
    return (
        <>
            <div className="container-fluid historypage ">
                <h1 className="text-center">History</h1>
                <div className="row m-5">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={2}>
                                <Nav variant="pills" className="flex-column" on>
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Cash out Requests</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Received TransAction</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">Sent TransAction</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="four"> Internal Transfer</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={12} md={10}>
                                <Tab.Content>
                                    <Tab.Pane className="bg-light" eventKey="first">
                                        {cashOutRequests.length ? (
                                            cashOutRequests.map((cor) => {
                                                return (
                                                    <>
                                                        <div className={"row alert alert-primary"}>
                                                            <div className="col-3  col-md-3 col-sm-12">
                                                                Requested on :
                                                                <b className="text-dark">
                                                                    {moment(cor.createdAt).format(
                                                                        "D/M/Y h:mm:ss A"
                                                                    )}
                                                                </b>
                                                            </div>
                                                            <div className="col-2 col-md-2 col-sm-12 ">
                                                                <a className="float-end">
                                                                    Amount : <br/>
                                                                    <b className="h6">
                                                                        {cor.currency === "usd" ? "$" : "€"}
                                                                        {cor.totalamount}
                                                                    </b>
                                                                </a>
                                                            </div>

                                                            <div className="col-2 col-md-2 col-sm-12 ">
                                                                <a className="float-end">
                                                                    cashout Fees: <br/>
                                                                    <b className="h6">
                                                                        {cor.currency === "usd" ? "$" : "€"}
                                                                        {cor.cashoutamontfees}
                                                                    </b>
                                                                </a>
                                                            </div>

                                                            <div className="col-3 col-md-4 col-sm-12">
                                                                {cor.requeststatus === "Cancelled" ? (
                                                                    <div className="">
                                                                        Cancelled Reason:
                                                                        <b className={"text-primary"}>
                                                                            {cor.rejectreason}
                                                                        </b>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>

                                                            <div className="div  alert alert-primary">
                                                                <div className="">
                                                                    <a className="float-end">
                                                                        Cashout Amount: <br/>
                                                                        {cor.cashouttype === "cryptocurrency" ? (
                                                                            <b className="h3 text-danger">
                                                                                ${cor.cashoutamount}
                                                                            </b>
                                                                        ) : (
                                                                            <b className="h3 text-success">
                                                                                €{cor.cashoutamount}
                                                                            </b>
                                                                        )}
                                                                    </a>
                                                                    <div className="">
                                                                        request status:
                                                                        <b className={"text-primary"}>
                                                                            {cor.requeststatus}
                                                                        </b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                        ) : (
                                            <div>no data for now</div>
                                        )}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        {receivedTransAction.length ? (
                                            receivedTransAction.map((rt) => {
                                                return (
                                                    <>
                                                        <div className={"row alert alert-success"}>
                                                            <div className="col-3 col-md-3 col-sm-12">
                                                                <h6> Requested on :</h6>

                                                                <b className="text-dark">
                                                                    {moment(rt.createdAt).format(
                                                                        "D/M/Y h:mm:ss A"
                                                                    )}
                                                                </b>
                                                            </div>
                                                            <div className="col-2 col-md-2 col-sm-12">
                                                                {/* 2-Nov */}
                                                                <h6>sender:{rt.sender.name}</h6>
                                                                publicid:<u>{rt.sender.publicid}</u>
                                                            </div>
                                                            <div className="col-2   col-md-2 col-sm-12  ">
                                                                <a className="float-end">
                                                                    amount: <br/>
                                                                    <b className="h4">
                                                                        {rt.currency === "eur" ? "€" : "$"}
                                                                        {rt.receivedamount}
                                                                    </b>
                                                                </a>
                                                            </div>
                                                            <div className="col-3 col-md-3 col-sm-12 ">
                                                                <div className="float-end">
                                                                    request status:{" "}
                                                                    <b className={"text-primary"}>
                                                                        {" "}
                                                                        {rt.requeststatus}
                                                                    </b>
                                                                </div>
                                                                <div className="float-end">
                                                                    {rt.requeststatus === "Completed" ? (
                                                                        <u>
                                                                            <Link
                                                                                className="btn  btn-danger"
                                                                                to={`/transactions/refund/${rt._id}`}
                                                                            >
                                                                                Refund
                                                                            </Link>
                                                                        </u>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })
                                        ) : (
                                            <div>no data for now</div>
                                        )}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third" className="bg-light mt-2">
                                        {sentTransAction.map((st) => {
                                            return (
                                                <>
                                                    <div className={"row alert alert-warning"}>
                                                        <div className=" col-4 col-md-4 col-sm-12">
                                                            Requested on :
                                                            <b className="text-dark">
                                                                {moment(st.createdAt).format("D/M/Y h:mm:ss A")}
                                                            </b>
                                                        </div>
                                                        <div className="col-2 col-md-2 col-sm-12 ">
                                                            <h6>receiver :{st?.name}</h6>
                                                            publicid:<u>{st?.publicid}</u>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-sm-12 ">
                                                            <h6>
                                                                amount :
                                                                <b className="text-primary">
                                                                    {st.currency === "eur" ? "€" : "$"}
                                                                    {st.sentamount}
                                                                </b>
                                                            </h6>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-sm-12 ">
                                                            <div className="float-start">
                                                                request status:
                                                                <b
                                                                    className={
                                                                        st.requeststatus === "Refunded"
                                                                            ? "text-danger"
                                                                            : "text-primary"
                                                                    }
                                                                >
                                                                    {st.requeststatus} <br/>
                                                                    {st.requeststatus === "Refunded" ? (
                                                                        <div>
                                                                            refund Date{" "}
                                                                            {moment(st.refounddate).format(
                                                                                "D/M/Y h:mm:ss A"
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="four" className="bg-light mt-2">
                                        {internals.map((st) => {
                                            return (
                                                <>
                                                    <div className={"row alert alert-warning"}>
                                                        <div className=" col-4 col-md-4 col-sm-12">
                                                            Requested on :
                                                            <b className="text-dark">
                                                                {moment(st.createdAt).format("D/M/Y h:mm:ss A")}
                                                            </b>
                                                        </div>
                                                        <div className="col-2 col-md-2 col-sm-12 ">
                                                            <h6>direction :{st.direction}</h6>
                                                            sentamount:
                                                            <u>
                                                                {st.direction === "usd-to-eur" ? "$" : "€"}
                                                                {st.sentamount}
                                                            </u>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-sm-12 ">
                                                            <h6>
                                                                fees :
                                                                <b className="text-primary">
                                                                    {st.direction === "usd-to-eur" ? "€" : "$"}
                                                                    {st.fees}
                                                                </b>
                                                            </h6>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-sm-12 ">
                                                            <div className="float-start">
                                                                Recived Amount:
                                                                <b>
                                                                    {st.direction === "usd-to-eur" ? "€" : "$"}
                                                                    {st.finalamount}
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </>
    );
};
export default SPHistory;
// cor.requeststatus==="Not Prccessed"?"row alert alert-danger":
//       cor.requeststatus==="Processing"?"row alert alert-warning":cor.requeststatus==="Cancelled"?
//      "row alert alert-muted":  "row alert alert-warning"
