import React, { Component } from 'react';
const DeliveryMethod=()=>{
    return (
      <>
        <div className="container">
          <div className="row mt-5 mb-5">
            <h1>Delivery Policy & Method</h1>
            <div className="conatainer mt-5 mb-5">
              <div className="row mt-5">
                <h3>Delivery Policy:</h3>
                <h5>
                  At Tathkeer, we understand the importance of timely delivery
                  for our customers, especially when it comes to codes that
                  activate applications. Our delivery policy is designed to
                  ensure that our customers receive their codes as soon as
                  possible after a successful payment.
                </h5>
                <h5>
                  {" "}
                  Upon successful payment, the code will be sent automatically
                  to the customer's email address provided at the time of
                  purchase. We use secure payment methods to ensure the safety
                  and confidentiality of our customers' personal and financial
                  information.
                </h5>
                <h5>
                  Customers will be notified of their expected delivery date at
                  the time of purchase. In the event of a delay, we will inform
                  the customer as soon as possible and provide a new expected
                  delivery date.
                </h5>
              </div>
              <div className="row mt-5">
                <h3>Delivery Method:</h3>
                <h5>
                  Our primary delivery method is through email where the code
                  will be sent automatically to the customer's email address
                  provided at the time of purchase.
                </h5>
                <h5>
                  Customers can track their orders by logging into their account
                  on our website and viewing their order history. They will also
                  receive an email notification when the code has been sent.
                </h5>
                <h5>
                  Please note that we are not responsible for any lost or stolen
                  deliveries if the customer has provided an incorrect email
                  address.
                </h5>
                <h6>
                  Thank you for choosing Tathkeer, we are committed to providing
                  you with the best service and quality codes that activate
                  applications.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default DeliveryMethod