import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../css/newside.css';
import {FiLogOut} from "react-icons/fi";
import { useSelector } from "react-redux";
import {  
  FcDataSheet,
  FcBullish,
  FcMoneyTransfer,
  FcBriefcase,
  FcInTransit,
  FcMenu,FcM
} from "react-icons/fc"
import {
 EuroCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { BiSupport } from 'react-icons/bi';
import { TbListDetails } from 'react-icons/tb';
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi';
import {FaDashcube}from 'react-icons/fa'
import{GrTransaction}from 'react-icons/gr'
import{GiMoneyStack, GiReceiveMoney}from 'react-icons/gi'
import logo from '../images/logo.png'
    import { NavLink } from 'react-router-dom';
    import {BarChartOutlined,StopOutlined} from '@ant-design/icons'
import {BsFilePerson, BsPersonCircle}from 'react-icons/bs'
import { useEffect } from 'react';
import { BiUserPlus } from 'react-icons/bi';
import { GiTakeMyMoney } from 'react-icons/gi';
import { BsCashCoin } from 'react-icons/bs';
import {AiOutlineCarryOut}from 'react-icons/ai'
import cryptoimg from '../images/cryptocurrency.png'
import { BsCurrencyBitcoin } from 'react-icons/bs';
import { FcCurrencyExchange } from "react-icons/fc";

const NewSide = ({children}) => {
   
const {user}=useSelector(state=>({...state}))
const[disablesend,setDisablesend]=useState(false)
 function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
   const handleSubmitSend=()=>{
        setDisablesend(true)
    }


  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const { width, height } = windowDimensions
    const[isOpen ,setIsOpen] = useState(true);
    const toggle = () => setIsOpen (!isOpen);
 const menuItem = [
   {
     path: "admin/dashboard",
     name: <small>Dashboard</small>,
     icon: <FaDashcube className="text-light" />,
   },
   // {
   //     path:"admin/analysis",
   //     name:"analysis",
   //     icon:<BarChartOutlined className='text-light'/>
   // },<StopOutlined />
   {
     path: "/serviceprovider",
     name: <small>serviceprovider</small>,
     icon: <BsFilePerson />,
   },
   {
     path: "/admin/logs",
     name: <small>Logs</small>,
     icon: <TbListDetails />,
   },
   {
     path: "/admin/oospb",
     name: <small>oospb</small>,
     icon: <BsPersonCircle />,
   },
   {
     path: "/admin/blockedsp",
     name: <small>Blocked SP</small>,
     icon: <StopOutlined />,
   },
   {
     path: "/admin/orders",
     name: <small>orders</small>,
     icon: <AiOutlineCarryOut />,
   },
   {
     path: "/admin/Wallet",
     name: (
       <small>
         <b className="text-warning">€</b> Wallet
       </small>
     ),
     icon: <GiTakeMyMoney />,
   },
   ,
   {
     path: "/admin/dollarWallet",
     name: (
       <small>
         <b className="text-success">$ </b>Wallet
       </small>
     ),
     icon: <GiTakeMyMoney />,
   },

   {
     path: "/admin/externaltransfer",
     name: <small>External Transfer</small>,
     icon: <FcCurrencyExchange />,
   },
   {
     path: "/admin/spenternaltransfer",
     name: <small>SP Enternal Transfer</small>,
     icon: <FcCurrencyExchange />,
   },
   {
     path: "/admin/cashout",
     name: (
       <small>
         cashout <b className="text-success">Bank</b>{" "}
       </small>
     ),
     icon: <GiReceiveMoney className="text-light" />,
   },
   ,
   {
     path: "/admin/cashoutcrypto",
     name: (
       <small>
         cashout <b className="text-danger">Crypto</b>
       </small>
     ),
     icon: <BsCurrencyBitcoin className="text-light" />,
   },
   {
     path: "/admin/transactions",
     name: <small>TransAction Requests</small>,
     icon: <BsCashCoin className="text-light" />,
   },
   {
     path: "/admin/cutsomers",
     name: <small>Customers</small>,
     icon: <BiUserPlus />,
   },
   {
     path: "/admin/fp",
     name: <small>Financial procedures</small>,
     icon: <EuroCircleOutlined />,
   },

   {
     path: "/admin/setting",
     name: <small>Settings</small>,
     icon: <SettingOutlined />,
   },
 ];







    const menuItemSp = [
      {
        path: "/serviceprovider/dashboard",
        name: <small>Dashboard</small>,
        icon: <FaDashcube className="text-light" />,
      },

      {
        path: "/serviceprovider/ordersdetails",
        name: <small>orders</small>,
        icon: <AiOutlineCarryOut />,
      },
      {
        path: "/serviceprovider/wallet",
        name: <small>Wallet</small>,
        icon: <GiTakeMyMoney />,
      },

      {
        path: "/serviceprovider/Financialprocedures",
        name: <small>Financialprocedures</small>,
        icon: <GiMoneyStack />,
      },
      {
        path: "/serviceprovider/cashout",
        name: <small>cashout</small>,
        icon: <GiReceiveMoney className="text-light" />,
      },
      {
        path: "/serviceprovider/customers",
        name: <small>Customers</small>,
        icon: <BiUserPlus />,
      },
      {
        name: (
          <div
            className="w-100"
            data-bs-toggle="modal"
            data-bs-target="#support"
          >
            Support
          </div>
        ),
        icon: <BiSupport data-bs-toggle="modal" data-bs-target="#support" />,
      },
    ];
    return (<>    
  
           
                    
{ user&&user.role==="admin"&&( <div  >
           <div style={{width: isOpen ? "230px" : "50px"}} className="sidebar" >
               <div className="top_section">
                <div className='row'>
                    {isOpen?(<div className='col h5 '>
            <img src={logo} width='50px'style={{display: isOpen ? "inline " : "none"}} className="ms-2"/> TATHKEER<BiArrowToLeft className='float-end lefticon' onClick={toggle}/>
                    </div>):(<div style={{marginLeft: isOpen ? "150px" : "0px",fontSize:"30px"}} className="bars">
                       <BiArrowToRight onClick={toggle} className="righticon" />
                   </div>)}
                    

                </div>
                  
                  {/* { isOpen? (<> <div style={{marginLeft: isOpen ? "150px" : "0px",fontSize:"50px"}} className="bars">
                   <BiArrowToLeft onClick={toggle}/>
                   </div></>):(<div style={{marginLeft: isOpen ? "150px" : "0px",fontSize:"30px"}} className="bars">
                       <BiArrowToRight onClick={toggle}/>
                   </div>)} */}
               </div>
               {
                   menuItem.map((item, index)=>(
                    <div >

                       <NavLink to={item.path} key={index} className="link mt-3" activeclassName="active">
                           <div className="icon">{item.icon}</div>
                           <div style={{display: isOpen ? "inline" : "none"}} className="link_text">{item.name}</div>
                       </NavLink></div>
                   ))
               }
           </div>
           <main >{children}</main>
        </div>)}

        { user&&user.role==="serviceprovider"&&( <div  >
        



<div class="modal fade" id="support" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="staticBackdropLabel">Send Email To Support</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
   
<form action="https://formsubmit.co/support@tathkeer.net" method="POST">
    <input type="hidden" name="_subject" value="new tikket from tathkeer "/>
    <input type="hidden" name="_autoresponse" value="thanks for contact tathkeer we will response in short time"/>


<input type="hidden" name="_captcha" value="false"/>







    <div class="form-group mt-2" >
        <input class="border-secondary form-control" type="text" name="name"
         placeholder="NAME" autocomplete="on" minlength="3" 
         maxlength="10" required="" value={user.name}/></div>
    <div class="form-group mt-2"><input class="form-control form-control-lg" type="email"    placeholder="EMAIL" autocomplete="on"    value={user.email}/></div>
    <div class="form-group mt-2 mb-3" ><textarea class="form-control form-control-lg" placeholder="write message here!" rows="5" name="mesage" spellcheck="true" wrap="soft" autocomplete="on" required="" minlength="5" maxlength="100"></textarea></div>
   
    <div >
        <button onClick={handleSubmitSend}  class="btn btn-outline-success btn-lg" id="send" type="submit" >{disablesend?"loading ...":"Send Message"}</button></div>
</form>

<b className='text-danger float-start'>* We will response in Two hours</b><br/>
         <b className='text-danger'>* Support hours are from 8 am to 6 pm Saudi time</b>



      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       <br/>
       
      </div>
      
    </div>
  </div>
</div>
           <div style={{width: isOpen ? "230px" : "50px"}} className="sidebar" >
               <div className="top_section">
                <div className='row'>
                    {isOpen?(<div className='col h5'>
            <img src={logo} width='50px'style={{display: isOpen ? "inline" : "none"}}/> TATHKEER<BiArrowToLeft className='float-end lefticon' onClick={toggle}/>
                    </div>):(<div style={{marginLeft: isOpen ? "150px" : "0px",fontSize:"30px"}} className="bars">
                       <BiArrowToRight onClick={toggle} className="lefticon" />
                   </div>)}
                    

                </div>
                  
                  {/* { isOpen? (<> <div style={{marginLeft: isOpen ? "150px" : "0px",fontSize:"50px"}} className="bars">
                   <BiArrowToLeft onClick={toggle}/>
                   </div></>):(<div style={{marginLeft: isOpen ? "150px" : "0px",fontSize:"30px"}} className="bars">
                       <BiArrowToRight onClick={toggle}/>
                   </div>)} */}
               </div>
               {
                   menuItemSp.map((item, index)=>(
                    <div >

                       <NavLink to={item.path} key={index} className="link mt-3" activeclassName="active">
                           <div className="icon">{item.icon}</div>
                           <div style={{display: isOpen ? "inline" : "none"}} className="link_text">{item.name}</div>
                       </NavLink></div>
                   ))
               }
           </div>
           <main >{children}</main>
        </div>)}
              

            

   

      
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        </>

    );
};

export default NewSide;