import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GETONEOOSPB, GETOOSPB } from "../../../function/oospb";
import { Table, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
const Financialprocedures = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [oospbs, setOospbs] = useState([]);
  const [sp, setSp] = useState([]);

  useEffect(() => {
    GETONEOOSPB(user.token).then((res) => {
      setOospbs(res.data);
      console.log(res.data);
    });
  }, []);
  /*------ahmad 
  I have updated this peac of code to become like this it was send 
  the user's id as a parameter we no need that
  *****please find the prevouise version at line 24*******
  */

  // GETONEOOSPB(user.token, user._id).then((res) => {
  //   setOospbs(res.data);
  //   console.log(res.data);
  // });

  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h1>Financial procedures</h1>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Date</th>

                <th>Operation Type</th>
                <th>Operation balance</th>
                <th>Operation Amount</th>
                <th>Last Balance</th>
                <th>New Balance</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {oospbs.map((oospb) => (
                <tr key={oospb._id}>
                  <td>
                    <b className="text-dark">
                      {moment(oospb.createdAt).format("D/M/Y h:mm:ss A")}
                    </b>
                  </td>

                  <td>{oospb.operationtype}</td>
                  <td>{oospb.currency}</td>
                  <td>{oospb.operationamount}</td>
                  <td>{oospb.lastbalance.toFixed(2)}</td>
                  <td>{oospb.newbalance.toFixed(2)}</td>
                  <td>{oospb.note}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default Financialprocedures;
