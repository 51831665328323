import axios from "axios";

export const CREATETRANSACTION = async (
  authtoken,
  transActionAmount,
  publicid,
  sender,
  currency
) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/transaction/create`,
    { amount: transActionAmount, receiver: publicid, sender, currency },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const GETSPTRANSACTIONS = async (authtoken, _id) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/transaction`,
    { _id },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const GETAllTRANSACTIONS = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/transactions`, {
    headers: {
      authtoken,
    },
  });
};
export const GETAllTRANSACTIONSCOUNT = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/transactions/total`, {
    headers: {
      authtoken,
    },
  });
};
export const GETSPTRANSCOUNT = async (authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/transactions/totalsv`,
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const GETLASTTRANSACTIONS = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/transactions/last`, {
    headers: {
      authtoken,
    },
  });
};

// export const GETSPLASTTRANSACTION = async (authtoken, _id) => {
//   return await axios.get(
//     `${process.env.REACT_APP_API}/transactions/last/${_id}`,
//     {
//       headers: {
//         authtoken,
//       },
//     }
//   );
// };

export const GETSPLASTTRANSACTION = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/transactions/lastsv`, {
    headers: {
      authtoken,
    },
  });
};
