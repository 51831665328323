import axios from "axios";

export const GETCUSTOMERORDERS = async (authtoken,email) => {
    return await axios.get(`${process.env.REACT_APP_API}/customerorders/${email}`,{
       headers: { 
            authtoken 
        } 
    })
}
export const GETCUSTOMERS = async (authtoken,sort, order, page) => {
    return await axios.post(`${process.env.REACT_APP_API}/customers/`,{
        sort, order, page
    },{
       headers: { 
            authtoken 
        } 
    })
}

export const CHECKPUBLICID = async (resllerid) => {
    return await axios.post(`${process.env.REACT_APP_API}/users/check`, {resllerid}, {
    })
}

export const GETCUSTOMERORDER = async (authtoken,_id) => {
    return await axios.get(`${process.env.REACT_APP_API}/customeroreder/${_id}`,{
       headers: { 
            authtoken 
        } 
    })
}
export const GETSPCUSTOMERS = async (authtoken,resllerid) => {

    return await axios.post(`${process.env.REACT_APP_API}/spcustomers`, {resllerid}, {
         headers: { 
            authtoken 
        } 
    })
}
export const GETCUSTOMERSCOUNT = async (authtoken) => {
    return await axios.get(`${process.env.REACT_APP_API}/customers/total`,{
       headers: { 
            authtoken 
        } 
    })
}
export const GETSPCUSTOMERSCOUNT = async (authtoken,resllerid) => {
    return await axios.post(`${process.env.REACT_APP_API}/spcustomers/total`,{resllerid},{
       headers: { 
            authtoken 
        } 
    })
}