import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GETAllTRANSACTIONS } from '../../../function/transaction';
import moment from "moment";
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import {CloudDownloadOutlined,EyeOutlined}from '@ant-design/icons';
 import transferimg from '../../images/transferimg.png'
 import LocalSearch from "../../search/localsearch";
const TransActions=()=>{
    const [transActions,setTransActions]=useState([])
    let {user}=useSelector((state)=>({...state}))
useEffect(()=>{
GETAllTRANSACTIONS(user.token).then((res)=>{
    setTransActions(res.data)
    // console.log(res.data)

})
},[])
    const [keyword, setKeyword] = useState("")
const search = (keyword) => (c) => c.sender.name.toLowerCase().includes(keyword)
    return(
    <>
<div className="row mt-5 p-2">
  {/* <OldTable/> */}
 <div className="container">
        <div clasName="row">
          <div className="col-12">
            <div className="card servicceProvidersCard table-responsive">
              <div className="card-header">
                <div className="row">
                  <div className="col-5">
                    <div ><img width={'90px'}  src={transferimg} />
                <b className="h4 ms-2">Transfer Between Two Service Providers</b>
              
                </div>
                  </div>
                  <div className="col-5">
                    <div className="mt-2"><LocalSearch placeholder="search By Name"  keyword={keyword} setKeyword={setKeyword} /></div>
                  </div>
                  <div className="col-2">
              <div className="float-end me-auto"> <button className=" btn btn-light-info h5">
                <CloudDownloadOutlined style={{ fontSize: '20px'}} />  Export</button></div>
                  </div>
                </div>
                
              </div>
            
              <table className="serviceTable table  ">
                 <thead className="">
      <tr>
            <th> serial Number</th>
            <th>Sender</th>
            <th>Receiver</th>
             <th> Sent Amount</th>
             <th>Fees</th>
              <th>Received Amount</th>
                   <th>Refund Reason</th>
              <th>Status</th>
           
               <th>Date</th>
    
     
       
        </tr>
       
        </thead>
         <tbody >
      

         
          {transActions?transActions.filter(search(keyword)).map((req,index)=>{
                
                 
return(<>
     <tr>
        <td>{req.uniqueid}</td>

            <td>   <Link key={req.sender.publicid}  to={`/user/${req.sender.publicid}`} className="text-danger">{req.sender.name}</Link> 
            </td>
            <td>
<Link key={req.receiver.publicid}  to={`/user/${req.receiver.publicid}`} className="text-success">{req.receiver.name}</Link> 
            </td>
        <td>{req.currency==="eur"?"€":"$"}{req.sentamount}</td>
 <td>{req.currency==="eur"?"€":"$"}{req.fees}</td>
 <td>{req.currency==="eur"?"€":"$"}{req.receivedamount.toFixed(2)}</td>
 <td>{req.refoundreason?(<div>{req.refoundreason}</div>):(<div className='text-success'>NOT Refunded</div>)}</td>
 <td className={req.requeststatus==="Completed"?'text-success':'text-danger'}>{req.requeststatus}</td>
            <td>   <b className="text-dark">{moment(req.createdAt).format('D/M/Y h:mm:ss A')}
</b> </td>
           
       
       
              
        
            
             
        </tr>
            
           </>)
 
                   }):'no data for now'}
     
        </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
</div>
   
    
    </>)
}
export default TransActions