import React from "react";
import {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {useState} from "react";
import {GETUSER, getUsersCount} from "../../../function/auth";
import moment from "moment";
import {Radio} from "antd";
import {
    EyeOutlined,
    CrownOutlined,
    EditFilled,
    CopyOutlined,
    EditOutlined,
    UserSwitchOutlined,
    PhoneOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons";
import {
    UPDATEBALANCE,
    UPDATEFEES,
    UPDATEROLE,
    UPDATESPLEVEL,
    UPDATESTATUS,
} from "../../../function/serviceprovider";
import {
    DollarCircleOutlined,
    CloseCircleOutlined,
    HomeOutlined,
    ShoppingCartOutlined,
    CalendarOutlined,
    CarOutlined,
    MailOutlined,
    ControlOutlined,
    WalletOutlined,
} from "@ant-design/icons";
import {toast} from "react-toastify";
import {Input, Tooltip, Button} from "antd";
import {GETUSPORDERSCOUNT} from "../../../function/orders";
import {useSelector} from "react-redux";
import {GETDEFAULTFEES} from "../../../function/settings";
import "../../css/userdetails.css";
import {CREATEOOSPB, CREATEOOSPDB} from "../../../function/oospb";

const UserDetails = () => {

    const [balanceType, setBalanceType] = useState('');
    const [editableUserBalance, setEditableUserBalance] = useState();
    const [editableUserDollarBalance, setEditableUserDollarBalance] = useState();
    const [userBalanceEdit, setUserBalanceEdit] = useState(false);
    const [userDollarBalanceEdit, setUserDollarBalanceEdit] = useState(false);
    const [number, setNumber] = useState(0);
    const [usdnumber, setUsdnumber] = useState(0);
    const [splevel, setSplevel] = useState();
    const {user} = useSelector((state) => ({...state}))
    const [fees, setFees] = useState();
    const [role, setRole] = useState();
    const [spOrderCount, setSpOrderCount] = useState();
    const [address, setAddress] = useState([]);
    let {publicid} = useParams();
    const [userDetails, setUserDetails] = useState([]);
    const [defaultFees, setDefaultFees] = useState();
    const [note, setNote] = useState("");
    const [usdnote, setUsdnote] = useState("");
    const [accountstatus, setAccountstatus] = useState(userDetails.accountstatus);
    const [amountValid, setAmountValid] = useState(false);
    const [UsdamountValid, setUsdamountValid] = useState(false);


    useEffect(() => {
        if (!spOrderCount || userDetails) {
            LoadUser();
        }

        GETDEFAULTFEES(user.token).then((res) => {
            {
                res.data.map((df) => {
                    setDefaultFees(df.defaultfees);
                });
            }
        });
    }, []);
    const handleEditUserbalance = (e) => {
        setUserBalanceEdit(!userBalanceEdit);
    };

    const handleEditUserDollarbalance = (e) => {
        setUserDollarBalanceEdit(!userDollarBalanceEdit);
    };
    const options = {
        labels: {
            confirmable: "yes",
            cancellable: "Cancel",
        },
    };

    const handleCopyText = () => {
        navigator.clipboard.writeText(userDetails.publicid);
        toast.success(` ${userDetails.publicid} Copied `);
    };
    const handleCopyTextEmail = () => {
        navigator.clipboard.writeText(userDetails.email);
        toast.success(` ${userDetails.email} Copied `);
    };

    const handleCopyTextDate = () => {
        navigator.clipboard.writeText(userDetails.createdAt);
        toast.success(` ${userDetails.createdAt} Copied `);
    };

    const handleCopyTextName = () => {
        navigator.clipboard.writeText(userDetails.name);
        toast.success(` ${userDetails.name} Copied `);
    };

    const handleCopyTextPhone = () => {
        navigator.clipboard.writeText(userDetails.phone);
        toast.success(` ${userDetails.phone} Copied `);
    };

    const handleCopyTextContry = (a) => {
        navigator.clipboard.writeText(a.contry);
        toast.success(` ${a.contry} Copied `);
    };

    const handleCopyTextDefaultContry = (a) => {
        navigator.clipboard.writeText(a.defaultContry);
        toast.success(` ${a.defaultContry} Copied `);
    };

    const handleCopyTextStreetName = (a) => {
        navigator.clipboard.writeText(a.street);
        toast.success(` ${a.street} Copied `);
    };

    const handleCopyTextStreetNumber = (a) => {
        navigator.clipboard.writeText(a.streetNumber);
        toast.success(` ${a.streetNumber} Copied `);
    };

    const handleCopyTextZipCode = (a) => {
        navigator.clipboard.writeText(a.zipCode);
        toast.success(` ${a.zipCode} Copied `);
    };
    const LoadUser = () => {
        // console.log(publicid)
        GETUSER(publicid, user.token).then((res) => {
            setAddress(res.data.address);
            setUserDetails(res.data);
            setEditableUserBalance(res.data.balance.toFixed(0));
            setEditableUserDollarBalance(res.data.usdbalance.toFixed(0));
            setAccountstatus(res.data.accountstatus);
            setRole(res.data.role);
            setSplevel(res.data.splevel);
        });

        // 5-nov  GETUSPORDERSCOUNT(user.token, publicid) to GETUSPORDERSCOUNT(user.token)
        GETUSPORDERSCOUNT(user.token).then((res) => {
            setSpOrderCount(res.data);
        });
    };
    const handleChangeStatus = (email, accountstatus) => {
        // window.confirm(userDetails.accountstatus==="aproved"?'Block User':"Unblock User")
    };

    const handleclick = async () => {
        UPDATESTATUS(user.token, userDetails.email, accountstatus).then((res) => {
            toast.success(`${userDetails.email} has been ${res.data.accountstatus}`);
            LoadUser();
        });
    };

    const changestatusstate = () => {
        if (accountstatus === "aproved") {
            setAccountstatus("blocked");
        } else {
            setAccountstatus("aproved");
        }
    };

    const changerolestate = () => {
        if (role === "admin") {
            setRole("serviceprovider");
        } else {
            setRole("admin");
        }
    };

    const handleRole = () => {
        UPDATEROLE(user.token, userDetails.email, role).then((res) => {
            toast.success(`${userDetails.email} has been ${res.data.role}`);
            LoadUser();
        });
    };

    const handleFees = (e) => {
        UPDATEFEES(user.token, userDetails.email, fees).then((res) => {
            toast.success(`${userDetails.email} fees Updated!  ${res.data.fees}`);
            LoadUser();
        });
    };

    const handleSplevel = () => {
        UPDATESPLEVEL(user.token, userDetails.email, splevel).then((res) => {
            toast.success(`${userDetails.splevel} has been ${res.data.splevel}`);

            LoadUser();
        });
    };

    const [operation, setOperation] = useState("");
    var currentBalance = userDetails.balance;
    const handleChangeNumber = (e) => {
        const value = parseFloat(e.target.value);
        if (!isNaN(value)) {
            setNumber(value);
            if (Number(editableUserBalance) + value < 0) {
                setNumber(0);
                setAmountValid(false);
                toast.error(`decrease maximum  ${editableUserBalance}`);
            } else {
                setAmountValid(true);
            }
        }
    };

    const handleChangeDollarNumber = (e) => {
        const value = parseFloat(e.target.value);
        if (!isNaN(value)) {
            setUsdnumber(value);
            if (Number(editableUserDollarBalance) + value < 0) {
                setUsdnumber(0);
                setUsdamountValid(false);
                toast.error(`decrease maximum  ${editableUserDollarBalance}`);
            } else {
                setUsdamountValid(true);
            }
        }
    };

    const handleminus = () => {
        if (Number(editableUserBalance) - number > 0) {
            setNumber(number - 1);
            setAmountValid(true);
        } else {
            setNumber(0);
            setAmountValid(false);
            toast.error(`decrease maximum  ${editableUserBalance}`);
        }
    };
    const handleincrease = () => {
        setNumber(number + 1);
        setAmountValid(true);
    };

    const handleminusDollar = () => {
        if (Number(editableUserDollarBalance) - usdnumber > 0) {
            setUsdnumber(usdnumber - 1);
            setUsdamountValid(true);
        } else {
            setUsdnumber(0);
            setUsdamountValid(false);
            toast.error(`decrease maximum  ${editableUserDollarBalance}`);
        }
    };
    const handleincreaseDollar = () => {
        setUsdnumber(usdnumber + 1);
        setUsdamountValid(true);
    };

    const handleSubmetServiceProviderBalance = () => {
        if (Number(editableUserBalance) + Number(number) > 0) {
            console.log("editableUserBalance", editableUserBalance, "number", number);

            CREATEOOSPB(user.token, userDetails._id, number, note).then((res) => {
                // console.log(res.data)
                toast.success(res.data.message);
                setUserBalanceEdit(false);
                LoadUser();
            });
        }
    };
    const handleSubmetServiceProviderUSDBalance = () => {
            const summation = Number(editableUserDollarBalance) + Number(usdnumber)

            if (summation > 0) {

                CREATEOOSPDB(user.token, userDetails._id,usdnumber, usdnote).then(
                    (res) => {
                        // console.log(res.data)
                        toast.success(res.data.message);
                        setUserDollarBalanceEdit(false);
                        LoadUser();
                    }
                );
            }
        }


    ;

    const handleFromChange = (e) => {
        setBalanceType(e.target.value);
    }


    const handleSubmitZeroBalance = () => {
        //here we weill get the selected and send the balance type with
        //public id for the server and wait the response to update informations
        UPDATEBALANCE(user.token, userDetails._id, balanceType).then((res) => {
            toast.success(res.data.message);
            LoadUser();
        })
    }


    return (
        <>
            {/* {console.log(accountstatus)} */}

            <div className="container-fluid customerspage ">

                <div className="row">
                    <div class="modal fade" id="resetbalance" tabindex="-1" aria-labelledby="role" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="role">Reset Balance</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                </div>

                                <div class="modal-body">
                                    {/* {JSON.stringify(balanceType)} */}
                                    <Radio.Group onChange={handleFromChange} value={balanceType}>
                                        <Radio value={"euro"} name="from">
                                            € euro balance
                                        </Radio>
                                        <Radio value={"dollar"} name="from">
                                            $ dollar balance
                                        </Radio>
                                    </Radio.Group>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="button" class="btn btn-primary" disabled={!balanceType}
                                            data-bs-dismiss="modal" onClick={handleSubmitZeroBalance}>Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* {JSON.stringify(role)} */}
                    <h1 className="text-dark text-center mt-5">
                        {" "}
                        {balanceType}
                        {userDetails.name} Profile
                    </h1>
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="mt-5 mb-2">
                                    <h3 className="text-dark text-center">Account Details</h3>
                                    <label className="text-dark"> Public Id</label>
                                    <br/>
                                    <Input
                                        disabled
                                        style={{
                                            width: "calc(100% - 35px)",
                                            marginBottom: "2%",
                                        }}
                                        value={userDetails.publicid}
                                    />
                                    <Tooltip title="copy id">
                                        <Button icon={<CopyOutlined/>} onClick={handleCopyText}/>
                                    </Tooltip>
                                    <label className="text-dark"> Email</label>
                                    <br/>
                                    <Input
                                        disabled
                                        style={{
                                            width: "calc(100% - 35px)",
                                            marginBottom: "2%",
                                            color: "blue",
                                        }}
                                        value={userDetails.email}
                                        prefix={<MailOutlined className="site-form-item-icon"/>}
                                    />
                                    <Tooltip title="copy Email">
                                        <Button
                                            icon={<CopyOutlined/>}
                                            onClick={handleCopyTextEmail}
                                        />
                                    </Tooltip>

                                    <label className="text-dark"> Role</label>
                                    <br/>
                                    <Input
                                        disabled
                                        style={{
                                            width: "calc(100% - 35px)",
                                            marginBottom: "2%",
                                        }}
                                        value={userDetails.role}
                                        prefix={
                                            <UserSwitchOutlined className="site-form-item-icon text-primary"/>
                                        }
                                    />
                                    <Tooltip title="change Role">
                                        <Button
                                            icon={<EditOutlined className="text-danger"/>}
                                            data-bs-toggle="modal"
                                            data-bs-target="#role"
                                            onClick={changerolestate}
                                        />
                                    </Tooltip>

                                    <label className="text-dark"> Account Status</label>
                                    <br/>
                                    <Input
                                        disabled
                                        style={{
                                            width: "calc(100% - 35px)",
                                            marginBottom: "2%",
                                        }}
                                        value={userDetails.accountstatus}
                                        prefix={
                                            userDetails.accountstatus === "aproved" ? (
                                                <CheckCircleOutlined className="site-form-item-icon text-success"/>
                                            ) : (
                                                <CloseCircleOutlined className="site-form-item-icon text-danger"/>
                                            )
                                        }
                                    />
                                    <Tooltip title="change status">
                                        <Button
                                            icon={<EditOutlined className="text-danger"/>}
                                            data-bs-toggle="modal"
                                            data-bs-target="#accountstatus"
                                            onClick={changestatusstate}
                                        />
                                    </Tooltip>
                                    <label className="text-dark">Joined At</label>
                                    <br/>
                                    <Input
                                        disabled
                                        style={{
                                            width: "calc(100% - 35px)",
                                            marginBottom: "2%",
                                        }}
                                        value={userDetails.createdAt}
                                        prefix={
                                            <CalendarOutlined className="site-form-item-icon text-success"/>
                                        }
                                    />
                                    <Tooltip title="copy date">
                                        <Button
                                            icon={<CopyOutlined/>}
                                            onClick={handleCopyTextDate}
                                        />
                                    </Tooltip>
                                    <label className="text-dark">Total orders</label>
                                    <br/>
                                    <Input
                                        disabled
                                        style={{
                                            width: "calc(100% - 35px)",
                                            marginBottom: "2%",
                                        }}
                                        value={spOrderCount}
                                        prefix={
                                            <ShoppingCartOutlined className="site-form-item-icon text-success"/>
                                        }
                                    />
                                    <Tooltip title="show orders">
                                        <Link
                                            key={publicid}
                                            to={`/admin/serviceproviderorders/${publicid}`}
                                        >
                                            <Button icon={<EyeOutlined/>}/>
                                        </Link>
                                    </Tooltip>

                                    <label className="text-dark">Fees</label>
                                    <br/>
                                    <Input
                                        disabled
                                        style={{
                                            width: "calc(100% - 35px)",
                                            marginBottom: "2%",
                                        }}
                                        value={
                                            userDetails.fees !== null ? userDetails.fees : defaultFees
                                        }
                                        prefix={
                                            <DollarCircleOutlined className="site-form-item-icon text-success"/>
                                        }
                                    />
                                    <Tooltip title="Edit Fees">
                                        <Button
                                            icon={<EditOutlined className="text-danger"/>}
                                            data-bs-toggle="modal"
                                            data-bs-target="#fees"
                                        />
                                    </Tooltip>

                                    <label className="text-dark">SP Level</label>
                                    <br/>
                                    <Input
                                        disabled
                                        style={{
                                            width: "calc(100% - 35px)",
                                            marginBottom: "2%",
                                            background: "dark",
                                        }}
                                        value={userDetails.splevel}
                                        prefix={
                                            <CrownOutlined className="site-form-item-icon text-warning "/>
                                        }
                                    />
                                    <Tooltip title="Edit Fees">
                                        <Button
                                            icon={<EditOutlined className="text-danger"/>}
                                            data-bs-toggle="modal"
                                            data-bs-target="#splevel"
                                        />
                                    </Tooltip>
                                </div>
                                <br/>
                                <div className="row">
                                    <div className="col-6">
                                        <Link to={`/admin/splogs/${userDetails._id}`}>
                                            <button className=" btn btn-danger">Logs</button>
                                        </Link>
                                    </div>
                                    <div className="col-6">
                                        <button className=" btn btn-primary" data-bs-target='#resetbalance'
                                                data-bs-toggle='modal'>Reset Balance
                                        </button>

                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mt-5 mb-2">
                                    <h3 className="text-dark text-center">Contact Details</h3>
                                    <label className="text-dark"> Name</label>
                                    <br/>
                                    <Input
                                        disabled
                                        style={{
                                            width: "calc(100% - 35px)",
                                            marginBottom: "2%",
                                        }}
                                        value={userDetails.name}
                                    />
                                    <Tooltip title="copy name">
                                        <Button
                                            icon={<CopyOutlined/>}
                                            onClick={handleCopyTextName}
                                        />
                                    </Tooltip>
                                    <label className="text-dark"> Phone</label>
                                    <br/>
                                    <Input
                                        disabled
                                        style={{
                                            width: "calc(100% - 35px)",
                                            marginBottom: "2%",
                                            color: "blue",
                                        }}
                                        value={userDetails.phone}
                                        prefix={<PhoneOutlined className="site-form-item-icon"/>}
                                    />
                                    <Tooltip title="copy phone">
                                        <Button
                                            icon={<CopyOutlined/>}
                                            onClick={handleCopyTextPhone}
                                        />
                                    </Tooltip>
                                    <h6 className="text-center text-dark">Adress Details</h6>

                                    {address.map((a) => {
                                        return (
                                            <>
                                                <label className="text-dark"> Country</label>
                                                <br/>
                                                <Input
                                                    disabled
                                                    style={{
                                                        width: "calc(100% - 35px)",
                                                        marginBottom: "2%",
                                                        color: "blue",
                                                    }}
                                                    value={a.contry}
                                                    prefix={
                                                        <HomeOutlined className="site-form-item-icon"/>
                                                    }
                                                />
                                                <Tooltip title="copy country">
                                                    <Button
                                                        icon={<CopyOutlined/>}
                                                        onClick={(e) => handleCopyTextContry(a)}
                                                    />
                                                </Tooltip>
                                                <label className="text-dark">Default Country</label>
                                                <br/>
                                                <Input
                                                    disabled
                                                    style={{
                                                        width: "calc(100% - 35px)",
                                                        marginBottom: "2%",
                                                        color: "blue",
                                                    }}
                                                    value={a.defaultContry}
                                                    prefix={
                                                        <HomeOutlined className="site-form-item-icon"/>
                                                    }
                                                />
                                                <Tooltip title="copy default country">
                                                    <Button
                                                        icon={<CopyOutlined/>}
                                                        onClick={(e) => handleCopyTextDefaultContry(a)}
                                                    />
                                                </Tooltip>

                                                <label className="text-dark">Street Name</label>
                                                <br/>

                                                <Input
                                                    disabled
                                                    style={{
                                                        width: "calc(100% - 35px)",
                                                        marginBottom: "2%",
                                                        color: "blue",
                                                    }}
                                                    value={a.street}
                                                    prefix={
                                                        <HomeOutlined className="site-form-item-icon"/>
                                                    }
                                                />
                                                <Tooltip title="copy street name">
                                                    <Button
                                                        icon={<CopyOutlined/>}
                                                        onClick={(e) => handleCopyTextStreetName(a)}
                                                    />
                                                </Tooltip>
                                                <label className="text-dark">Street Number</label>
                                                <br/>

                                                <Input
                                                    disabled
                                                    style={{
                                                        width: "calc(100% - 35px)",
                                                        marginBottom: "2%",
                                                        color: "blue",
                                                    }}
                                                    value={a.streetNumber}
                                                    prefix={
                                                        <HomeOutlined className="site-form-item-icon"/>
                                                    }
                                                />
                                                <Tooltip title="copy street number">
                                                    <Button
                                                        icon={<CopyOutlined/>}
                                                        onClick={(e) => handleCopyTextStreetNumber(a)}
                                                    />
                                                </Tooltip>

                                                <label className="text-dark">Zip Code</label>
                                                <br/>

                                                <Input
                                                    disabled
                                                    style={{
                                                        width: "calc(100% - 35px)",
                                                        marginBottom: "2%",
                                                        color: "blue",
                                                    }}
                                                    value={a.zipCode}
                                                    prefix={
                                                        <HomeOutlined className="site-form-item-icon"/>
                                                    }
                                                />
                                                <Tooltip title="copy zip code">
                                                    <Button
                                                        icon={<CopyOutlined/>}
                                                        onClick={(e) => handleCopyTextZipCode(a)}
                                                    />
                                                </Tooltip>
                                                <label className="text-danger">Balance € </label>
                                                <br/>

                                                <Input
                                                    disabled
                                                    style={{
                                                        width: "calc(100% - 35px)",
                                                        marginBottom: "2%",
                                                        color: "blue",
                                                    }}
                                                    value={userDetails.balance.toFixed(2)}
                                                    prefix={
                                                        <WalletOutlined className="site-form-item-icon"/>
                                                    }
                                                />
                                                <Tooltip title="Edit">
                                                    <Button
                                                        icon={<EditOutlined className="text-danger"/>}
                                                        onClick={(e) => handleEditUserbalance(e)}
                                                    />
                                                </Tooltip>

                                                <label className="text-success">Balance $</label>
                                                <br/>
                                                {/* {JSON.stringify(userDetails.usdbalance)} */}
                                                <Input
                                                    disabled
                                                    style={{
                                                        width: "calc(100% - 35px)",
                                                        marginBottom: "2%",
                                                        color: "blue",
                                                    }}
                                                    value={userDetails.usdbalance.toFixed(2)}
                                                    prefix={
                                                        <WalletOutlined className="site-form-item-icon"/>
                                                    }
                                                />
                                                <Tooltip title="Edit">
                                                    <Button
                                                        icon={<EditOutlined className="text-danger"/>}
                                                        onClick={(e) => handleEditUserDollarbalance(e)}
                                                    />
                                                </Tooltip>

                                                {/* {fees} */}
                                            </>
                                        );
                                    })}

                                    {userBalanceEdit ? (
                                        <>
                                            <div className="row ">
                                                <div className="col">
                                                    <div className="input-container">
                            <span
                                className="text-success d-flex justify-content-center"
                                onClick={handleminus}
                            >
                              <i>-</i>
                            </span>
                                                        <input
                                                            type="number"
                                                            value={number}
                                                            onChange={handleChangeNumber}
                                                        />
                                                        <span
                                                            className="text-danger d-flex justify-content-center"
                                                            onClick={handleincrease}
                                                        >
                              <i>+</i>
                            </span>
                                                        <b className="ms-3">
                                                            {editableUserBalance}(+) {number}={" "}
                                                            {Number(editableUserBalance) + Number(number)}
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <button
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#balance"
                                                        disabled={!amountValid || !note}
                                                        className={
                                                            amountValid ? "btn btn-success" : "btn btn-danger"
                                                        }
                                                    >
                                                        SAVE
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <label className=" mt-2">
                                                    Note for this Balance Change
                                                </label>
                                                <br/>
                                                <textarea
                                                    value={note}
                                                    onChange={(e) => setNote(e.target.value)}
                                                ></textarea>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {userDollarBalanceEdit ? (
                                        <>
                                            <div className="row ">
                                                <div className="col">
                                                    <div className="input-container">
                            <span
                                className="text-success d-flex justify-content-center"
                                onClick={handleminusDollar}
                            >
                              <i>-</i>
                            </span>
                                                        <input
                                                            type="number"
                                                            value={usdnumber}
                                                            onChange={handleChangeDollarNumber}
                                                        />
                                                        <span
                                                            className="text-danger d-flex justify-content-center"
                                                            onClick={handleincreaseDollar}
                                                        >
                              <i>+</i>
                            </span>
                                                        <b className="ms-3">
                                                            {editableUserDollarBalance}(+) {usdnumber}={" "}
                                                            {Number(editableUserDollarBalance) +
                                                                Number(usdnumber)}
                                                        </b>
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <button
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#usdbalance"
                                                        disabled={!UsdamountValid || !usdnote}
                                                        className={
                                                            UsdamountValid
                                                                ? "btn btn-success"
                                                                : "btn btn-danger"
                                                        }
                                                    >
                                                        SAVE
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <label className=" mt-2">
                                                    Note for this Balance Change
                                                </label>
                                                <br/>
                                                <textarea
                                                    value={usdnote}
                                                    onChange={(e) => setUsdnote(e.target.value)}
                                                ></textarea>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <div
                                        class="modal fade"
                                        id="usdbalance"
                                        tabindex="-1"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                    >
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">
                                                        {" "}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        class="btn-close"
                                                        onClick={LoadUser}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div class="modal-body">
                                                    Are You sure You want USD Balance changed from{" "}
                                                    <b className="text-success">
                                                        {editableUserDollarBalance}{" "}
                                                    </b>
                                                    to be{" "}
                                                    <b className="text-danger">
                                                        {Number(editableUserDollarBalance) +
                                                            Number(usdnumber)}
                                                    </b>{" "}
                                                    ?
                                                </div>
                                                <div class="modal-footer">
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary"
                                                        onClick={LoadUser}
                                                        data-bs-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        onClick={handleSubmetServiceProviderUSDBalance}
                                                        type="button"
                                                        class="btn btn-primary"
                                                        data-bs-dismiss="modal"
                                                        // onClick={handleclick}
                                                    >
                                                        Save changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}

                                    <div
                                        class="modal fade"
                                        id="balance"
                                        tabindex="-1"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                    >
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">
                                                        {" "}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        class="btn-close"
                                                        onClick={LoadUser}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div class="modal-body">
                                                    Are You sure You want balance changed from{" "}
                                                    <b className="text-success">{editableUserBalance} </b>
                                                    to be{" "}
                                                    <b className="text-danger">
                                                        {Number(editableUserBalance) + Number(number)}
                                                    </b>{" "}
                                                    ?
                                                </div>
                                                <div class="modal-footer">
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary"
                                                        onClick={LoadUser}
                                                        data-bs-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        onClick={handleSubmetServiceProviderBalance}
                                                        type="button"
                                                        class="btn btn-primary"
                                                        data-bs-dismiss="modal"
                                                        // onClick={handleclick}
                                                    >
                                                        Save changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="modal fade"
                                        id="accountstatus"
                                        tabindex="-1"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                    >
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">
                                                        {" "}
                                                        {userDetails.email}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        class="btn-close"
                                                        onClick={LoadUser}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div class="modal-body">
                                                    Are You sure You want {userDetails.email} to be{" "}
                                                    <b>{accountstatus}</b>
                                                </div>
                                                <div class="modal-footer">
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary"
                                                        onClick={LoadUser}
                                                        data-bs-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary"
                                                        data-bs-dismiss="modal"
                                                        onClick={handleclick}
                                                    >
                                                        Save changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="modal fade"
                                        id="role"
                                        tabindex="-1"
                                        aria-labelledby="role"
                                        aria-hidden="true"
                                    >
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="role">
                                                        {" "}
                                                        {userDetails.email}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        class="btn-close"
                                                        onClick={LoadUser}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div class="modal-body">
                                                    Are You sure You want {userDetails.email} to be{" "}
                                                    <b>{role}</b>
                                                </div>
                                                <div class="modal-footer">
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary"
                                                        data-bs-dismiss="modal"
                                                        onClick={LoadUser}
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary"
                                                        data-bs-dismiss="modal"
                                                        onClick={handleRole}
                                                    >
                                                        Save changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="modal fade"
                                        id="fees"
                                        tabindex="-1"
                                        aria-labelledby="role"
                                        aria-hidden="true"
                                    >
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="role">
                                                        {" "}
                                                        {userDetails.email}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        class="btn-close"
                                                        onClick={LoadUser}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div class="modal-body">
                                                    <b>
                                                        {" "}
                                                        fees :
                                                        {userDetails.fees !== null
                                                            ? userDetails.fees
                                                            : null}{" "}
                                                    </b>
                                                    <br/>
                                                    <Input
                                                        style={{
                                                            width: "calc(100% - 35px)",
                                                            marginBottom: "2%",
                                                            color: "blue",
                                                        }}
                                                        onChange={(e) => setFees(e.target.value)}
                                                        value={fees}
                                                        type="number"
                                                        placeholder="New Fees"
                                                        prefix={
                                                            <DollarCircleOutlined className="site-form-item-icon"/>
                                                        }
                                                    />
                                                </div>
                                                <div class="modal-footer">
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary"
                                                        data-bs-dismiss="modal"
                                                        onClick={LoadUser}
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary"
                                                        data-bs-dismiss="modal"
                                                        onClick={handleFees}
                                                    >
                                                        Save changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="modal fade"
                                        id="splevel"
                                        tabindex="-1"
                                        aria-labelledby="role"
                                        aria-hidden="true"
                                    >
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="role">
                                                        {" "}
                                                        {userDetails.email}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        class="btn-close"
                                                        onClick={LoadUser}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div class="modal-body">
                                                    <b> service provider level :{userDetails.splevel}</b>
                                                    <br/>
                                                    <Input
                                                        style={{
                                                            width: "calc(100% - 35px)",
                                                            marginBottom: "2%",
                                                            color: "silver",
                                                        }}
                                                        onChange={(e) => setSplevel(e.target.value)}
                                                        value={splevel}
                                                        type="number"
                                                        placeholder="New Fees"
                                                        prefix={
                                                            <CrownOutlined className="site-form-item-icon"/>
                                                        }
                                                    />
                                                </div>
                                                <div class="modal-footer">
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary"
                                                        data-bs-dismiss="modal"
                                                        onClick={LoadUser}
                                                    >
                                                        Close
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary"
                                                        data-bs-dismiss="modal"
                                                        onClick={handleSplevel}
                                                    >
                                                        Save changes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserDetails;
