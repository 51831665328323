import "./App.css";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { lazy, Suspense } from "react";
import { useState } from "react";
import { getMessaging, onMessage } from "firebase/messaging";
import { fapp } from "./fcm";
import RegisterComplete from "./component/pages/registercomplete";
import Home from "./component/pages/Home";
import Dashboard from "./component/pages/serviceproviderpages/dashboard";
import Users from "./component/pages/adminpages/users";
import ServiceProvider from "./component/pages/adminpages/serviceProvider";
import Orders from "./component/pages/adminpages/orders";
import Profile from "./component/pages/profile";
import CacheOut from "./component/pages/adminpages/CacheOut";
import History from "./component/pages/History";
import { auth } from "./firebase";
import { useEffect } from "react";
import ResetPassword from "./component/pages/resetpassword";
import NaveBar from "./component/navbar/navbar";
import { CRUDUSER, LOGIN, USERCHECK } from "./function/auth";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./component/pages/footer";
import CodeGenerator from "./component/codegenerator/codeGenerator";
import Analysis from "./component/pages/adminpages/analysis";
import {AdminLogsPage} from './component/routes/admin'
import {
  AdminAnlasis,
  AdminBlockedSp,
  AdminCashout,
  AdminCashoutcrypto,
  AdminCodes,
  AdminCustomerDetailRoute,
  AdminCustomersRoute,
  AdminDash,
  AdminDollarWalletPage,
  AdminExternal,
  AdminInvoce,
  AdminOospb,
  AdminRefund,
  AdminServiceProvider,
  AdminSettingRoute,
  AdminSpEternal,
  AdminTransActions,
  AdminUsersPage,
  AdminWalletPage,
  COrdersM,
} from "./component/routes/admin";
import {
  SpAnlasis,
  SPAP,
  SPC,
  SPCACHOUT,
  SPCW,
  SPFP,
  SPH,
  SPOD,
  SPODM,
  SPR,
} from "./component/routes/serviceprovider";
import { SPDash } from "./component/routes/serviceprovider";
import firebase from "firebase/compat/app";
import SPDashboard from "./component/pages/serviceproviderpages/dashboard";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import NewSide from "./component/pages/newSide";
import NewLogin from "./component/pages/NewLogin";
import Register from "./component/pages/register";
import UserDetails from "./component/pages/adminpages/userdetails";
import { SpOrders } from "./component/routes/admin";
import CustomerOrders from "./component/pages/adminpages/CustomerOrders";
import ServiceProviderOrders from "./component/pages/adminpages/ServiceProviderOrders";
import SERVICEPROVIDERORDERS from "./component/pages/serviceproviderpages/orders";
import SideDrawer from "./component/drawer/sideDrawer";
import ControlDrawer from "./component/drawer/controlDrawer";
import { GETALL, getclientid } from "./function/payment";
import Privacy from "./component/pages/privacy";
import { AdminFinancialprocedures } from "./component/routes/admin";
import CancelationPrivacy from "./component/pages/cancelationprivacy";
import CancellationPrivacy from "./component/pages/cancelationprivacy";
import TermsConditions from "./component/pages/terms&condtions";
import MerchandiSeservicesPrices from "./component/pages/merchandise&services prices";
import DeliveryMethod from "./component/pages/deliveryMethoud";
import SpLogs from "./component/pages/adminpages/splogs";

const App = () => {
  const [clientId, setClientId] = useState();
  useEffect(() => {
    onMessage(function (payload) {
      try {
        //try???
        console.log("Message received. ", payload);

        const noteTitle = payload.notification.title;
        const noteOptions = {
          body: payload.notification.body,
          icon: "typewriter.jpg", //this is my image in my public folder
        };

        console.log("title ", noteTitle, " ", payload.notification.body);
        //var notification = //examples include this, seems not needed

        new Notification(noteTitle, noteOptions); //This can be used to generate a local notification, without an incoming message. noteOptions has to be an object
      } catch (err) {
        console.log("Caught error: ", err);
      }
    });
  }, []);

  let { user } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getclientid().then((res) => {
      console.log("client id is now not avaliable", res.data);
      setClientId(res.data);
    });
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        // // console.log('user', user)
        LOGIN(idTokenResult.token).then((res) => {
          dispatch({
            type: "LOGED_IN_USER",
            payload: {
              // this way we can get the info from fire base directelly
              // email: user.email,
              // displayName: user.displayName,
              // token: idTokenResult.token
              //and this way we  got the data from our backend server from resbonse
              name: res.data.name,
              email: res.data.email,
              role: res.data.role,
              // picture: user.photoURL,
              // picture: res.data.picture,
              token: idTokenResult.token,
              _id: res.data._id,
              splevel: res.data.splevel,
              pid: res.data.publicid,
            },
          });

          // console.log(res)
        });
      }
      // res.data.accountstatus I have updated this one here to res.data becasue the status value of the user return direct from the api
      USERCHECK(user.email).then((res) => {
        if (res.data === "blocked") {
          toast.error("user has been Blocked PLease Try Later");
          firebase.auth().signOut();
          dispatch({
            type: "LOGE_OUT",
            payload: null,
          });
          navigate("/login");
        }
      });
    });
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  let { paypalAcc } = useSelector((state) => ({ ...state }));
  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };
  const { height, width } = useWindowDimensions();
  return (
    <PayPalScriptProvider
      options={{
        "client-id": `${clientId}`,
        currency: "EUR",
        commit: true,
      }}
    >
      <div className="App ">
        <NaveBar />

        <ToastContainer />
        <div className="container-fluid p-0 d-flex ">
          {user && width > 500 && <NewSide />}
          <SideDrawer />
          {user && <ControlDrawer />}
          <div className="routes ">
            <Routes>
              <Route
                path="/admin/cutsomers"
                element={<AdminCustomersRoute />}
              />
              <Route path="/admin/codes/:_id" element={<AdminCodes />} />
              <Route path="/admin/splogs/:_id" element={<SpLogs />} />
              <Route path="/admin/blockedsp" element={<AdminBlockedSp />} />
              <Route
                path="/admin/cutsomerdetails/:email"
                element={<AdminCustomerDetailRoute />}
              />
              <Route path="/code" element={<CodeGenerator />} />
              {/* <Route path="password" element={<Password/>}/> */}
              <Route path="resetpassword" element={<ResetPassword />} />
              <Route path="/" element={<Home />} />
              {!user && <Route path="/register" element={<Register />} />}
              {!user && (
                <Route
                  path="/register/complete"
                  element={<RegisterComplete />}
                />
              )}
              {!user && <Route path="/login" element={<NewLogin />} />}
              <Route path="/admin/dashboard" element={<AdminDash />} />
              {user && user.role === "serviceprovider" && (
                <Route
                  path="/serviceprovider/dashboard"
                  element={<SPDashboard />}
                />
              )}
              <Route path="/admin/analysis" element={<AdminAnlasis />} />
              <Route path="/admin/logs" element={<AdminLogsPage />} />
              <Route path="/admin/invoice/:_id" element={<AdminInvoce />} />
              <Route
                path="/admin/transactions"
                element={<AdminTransActions />}
              />
              <Route path="/admin/oospb" element={<AdminOospb />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route
                path="/MerchandiSeservicesPrices"
                element={<MerchandiSeservicesPrices />}
              />

              <Route
                path="/cancellationpolicy&refund"
                element={<CancellationPrivacy />}
              />
              <Route
                path="/DeliveryMethod"
                DeliveryMethod
                element={<DeliveryMethod />}
              />
              <Route path="/terms&conditions" element={<TermsConditions />} />
              <Route
                path="/serviceprovider"
                element={<AdminServiceProvider />}
              />
              <Route
                path="/admin/serviceproviderorders/:publicid"
                element={<SpOrders />}
              />
              <Route path="/serviceprovider/ordersdetails" element={<SPOD />} />
              <Route path="/serviceprovider/customers" element={<SPC />} />
              <Route
                path="/serviceprovider/Financialprocedures"
                element={<SPFP />}
              />
              <Route path="/serviceprovider/wallet" element={<SPCW />} />
              <Route path="/serviceprovider/history" element={<SPH />} />
              <Route path="/serviceprovider/cashout" element={<SPCACHOUT />} />
              <Route path="/admin/cashout" element={<AdminCashout />} />
              <Route
                path="/serviceprovider/Customerorders/Edit/:_id"
                element={<SPODM />}
              />
              <Route path="/transactions/refund/:_id" element={<SPR />} />
              <Route
                path="/admin/Customerorders/Edit/:_id"
                element={<COrdersM />}
              />
              <Route path="/admin/orders" element={<Orders />} />
              <Route path="/CacheOut" element={<CacheOut />} />
              <Route path="/admin/fp" element={<AdminFinancialprocedures />} />
              <Route path="/admin/wallet" element={<AdminWalletPage />} />
              <Route
                path="/admin/externaltransfer"
                element={<AdminExternal />}
              />
              <Route
                path="/admin/spenternaltransfer"
                element={<AdminSpEternal />}
              />
              <Route
                path="/admin/dollarwallet"
                element={<AdminDollarWalletPage />}
              />
              <Route path="/admin/users" element={<AdminUsersPage />} />
              {/* <Route path="/settings" element={<Accountsettings />} /> */}
              <Route path="/admin/setting" element={<AdminSettingRoute />} />
              <Route path="/Profile" element={<Profile />} />
              <Route path="/user/:publicid" element={<UserDetails />} />
              <Route
                path="/admin/Customerorders/:email"
                element={<CustomerOrders />}
              />
              <Route path="/History" element={<History />} />
              <Route
                path="/admin/cashoutcrypto"
                element={<AdminCashoutcrypto />}
              />
            </Routes>
          </div>
        </div>
      </div>

      {!user && <Footer />}
    </PayPalScriptProvider>
  );
};

export default App;
