import axios from "axios";


export const GETREFUNDREQ =async(authtoken,_id) => {
    // console.log(_id,authtoken)
    return await axios.get(`${process.env.REACT_APP_API}/r/${_id}`,{
       headers: { 
            authtoken 
        } 
    })
}


export const REFUNDUPDATE=async(authtoken,_id,reason,refounddate)=>{
    return await axios.put(`${process.env.REACT_APP_API}/refundupdate`,{_id,reason,refounddate},{
       headers: { 
            authtoken 
        } 
    })
}