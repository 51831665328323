import React from "react";
import "antd/dist/antd.min.css";
import "../css/navbar.css";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux"; 
import { Link, useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {  Button } from 'antd';
import { BiLogOut } from "react-icons/bi";
import { GoGraph } from "react-icons/go";
import { CgProfile } from "react-icons/cg";
import { AiOutlineSetting } from "react-icons/ai";
import { FaRegistered } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import firebase from "firebase/compat/app";
import { UserOutlined,HistoryOutlined ,AntDesignOutlined,ArrowDownOutlined,PicRightOutlined} from "@ant-design/icons";
import logo from '../images/logo.png'
import avatar from '../images/avatar.png'
import { Avatar,Badge, Image } from 'antd';
const NaveBar = () => {
  
  function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
const  useWindowDimensions=()=> {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { user } = useSelector((state) => ({ ...state }));
  const phoneWidth=500;
  const logout = () => {
    firebase.auth().signOut(); 
    dispatch({
      type: "LOGE_OUT",
      payload: null,
    });

    navigate("/login");
  };
  const handleAvatar=()=>{
      if(typeof window!=="undefined"){

                dispatch({
                    type: "SET_VISIBLE",
                    payload: true,
                })

        }
  }
   const handleAvatar2=()=>{
      if(typeof window!=="undefined"){

                dispatch({
                    type: "SET_VISIBLE2",
                    payload: true,
                })

        }
  }
  
  return (
   
      <Menu  className="mainNavbar shadow"  mode="horizontal" defaultSelectedKeys={["mail"]}>
        <Link to="/">
          <Menu.Item className="text-white mt-2" key="mail" >
            <img src={logo} width='60px'/>
          </Menu.Item>
        </Link>
        <div className="childDiv">
          <div className="rightnav">
            {/* <Menu.Item icon={<AiOutlineHome/>} key="home">home</Menu.Item>
            <Menu.Item key="shop">shop</Menu.Item>
            <Menu.Item key="cart">cart</Menu.Item> */}
          </div>
          <div>
            {user &&width>500&& (
              <Menu.SubMenu  className="text-dark h5   mt-2   pt-2 pe-5 pb-2  text-center me-2"
                key="SubMenu"
                title={<> {user.name||user.email.split('@')[0]}</>}
                icon={ <><Badge count={1}>
 <Avatar
       src={avatar} 
       size='large'
     />
       
   </Badge></> }
              >
                <Menu.Item   key="one" icon={<CgProfile />}>
                  <Link to="/profile" style={{textDecoration:'none'}}>profile</Link>
                </Menu.Item>

              {user&&user.role==="admin" &&( <Menu.Item key="two" icon={<AiOutlineSetting />}>
                  <Link to="/admin/setting" style={{textDecoration:'none'}}> settings</Link>
                </Menu.Item>)}
                {/* {user&&user.role==="serviceprovider" &&( <Menu.Item key="two" icon={<AiOutlineSetting />}>
                  <Link to="settings"> settings</Link>
                </Menu.Item>)} */}
                   {user&&user.role==="serviceprovider" &&( 
                   <Menu.Item key="157" icon={<HistoryOutlined />}>
                  <Link to="serviceprovider/history" style={{textDecoration:'none'}}> History</Link>
                </Menu.Item>)}
                {/* icon={<GoGraph />} */}
              
        {user&&user.role==="serviceprovider"&&(
                <Menu.Item icon={<GoGraph />} key="there">
                  <Link to="/serviceprovider/dashboard" style={{textDecoration:'none'}}>Dashboord</Link>
                </Menu.Item>)}
                {user&&user.role==="admin"&&(
                <Menu.Item icon={<GoGraph />} key="there">
                  <Link to="/admin/dashboard">Dashboord</Link>
                </Menu.Item>)}
                {/* <Menu.Item key="six" icon={<CgProfile />}>
                  <Link to="/password">Password</Link>
                </Menu.Item> */}
                <Menu.Item onClick={logout} key="four" icon={<BiLogOut />}>
                  logout
                </Menu.Item>
              </Menu.SubMenu>
            )} 

         
               {!user &&width>500&& (  <div className="noUsers mt-2">
          
              
                  <Menu.Item className="li"  key="mail" >
       <Link to="/">   <button className=" li btn  text-center navbtn ">Home</button>    </Link>

                  </Menu.Item>
             
          
   
               
                  <Menu.Item className="li"  key="mail" >
   <Link to="/login">   <button className=" li btn  text-center  navbtn">Login</button> </Link>   

                </Menu.Item>
             
           
             
                
                  <Menu.Item className=" me-5" key="mail" >
        <Link to="/register" className="lin">   <button className="  btn  text-center signupbutton ">SignUp</button>    </Link> 
                  </Menu.Item>
            
              
            </div>)}
  {phoneWidth<width?'':<Avatar size={44} onClick={handleAvatar}  className="me-3 mt-3" icon={<UserOutlined />} />}
 {user&&(<>
 {phoneWidth<width?'':<Avatar size={44} onClick={handleAvatar2}  className="me-3 mt-3" icon={<PicRightOutlined />} />}
 </>)}
          </div>
        </div>
      </Menu>
  
  );
};
export default NaveBar;
