import React from 'react'
import { render } from 'react-dom'
import Styles from './Styles'
import { Form, Field } from 'react-final-form'
import Card from './Card'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from './cardUtils'
import { useSelector } from 'react-redux'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
  await sleep(300)
  window.alert(JSON.stringify(values, 0, 2))
}

const Credit = () =>{
    const {user}=useSelector((state)=>({...state}))
  return(

  <Styles>
  
    {/* <h2>Credit Card </h2> */}
   
    <Form

      onSubmit={onSubmit}
      render={({
        
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        active
      }) => {
      
        return (
            <Card
              number={values.number || '2315************'}
              name={values.name || user.name}
              expiry={values.expiry || '1224'}
              cvc={values.cvc || ''}
              focused={active}
            />
          // <form onSubmit={handleSubmit}>
          
          //   <div>
          //     <Field
          //       name="number"
          //       component="input"
          //       type="text"
          //       pattern="[\d| ]{16,22}"
          //       placeholder="Card Number"
          //       format={formatCreditCardNumber}
          //     />
          //   </div>
          //   <div>
          //     <Field
          //       name="name"
          //       component="input"
          //       type="text"
          //       placeholder="Name"
          //     />
          //   </div>
          //   <div>
          //     <Field
          //       name="expiry"
          //       component="input"
          //       type="text"
          //       pattern="\d\d/\d\d"
          //       placeholder="Valid Thru"
          //       format={formatExpirationDate}
          //     />
          //     <Field
          //       name="cvc"
          //       component="input"
          //       type="text"
          //       pattern="\d{3,4}"
          //       placeholder="CVC"
          //       format={formatCVC}
          //     />
          //   </div>
          //   <div className="buttons">
          //     <button type="submit" disabled={submitting}>
          //       Submit
          //     </button>
          //     <button
          //       type="button"
          //       onClick={form.reset}
          //       disabled={submitting || pristine}
          //     >
          //       Reset
          //     </button>
          //   </div>
          //   {/* <h2>Values</h2> */}
          //   {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          // </form>
        )
      }}
    />
  </Styles>
)}


export default Credit