import React, { Component } from 'react';
import '../css/msp.css'

const MerchandiSeservicesPrices=()=>{
    return (
      <>
        <div className="container msppage">
          <div className="row mt-5">
            <h1 className="text-center fw-bolder">
              merchandise & services prices
            </h1>
            <div className="container mt-5">
              <div className="row mt-5">
                <div className="card mx-auto">
                  <div className="card-body">
                    <table class="table table-striped">
                      <thead>
                        <tr className="text-center">
                          <th>Subscriptions</th>
                          <th>Number of Codes</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center">
                          <td>1 Year Subscription</td>
                          <td>1</td>
                          <td>25 Euro</td>
                        </tr>
                        <tr className="text-center">
                          <td>6 Months Subscription</td>
                          <td>1</td>
                          <td>15 Euro</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-footer alert alert-primary">
                    <b className="text-danger">*</b>
                    merchidant sent automated for client's emails from your
                    website's server.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default MerchandiSeservicesPrices


    //  <b className='text-danger'>*</b>
    //                   merchidant sent automated for client's emails from your website's
    //                   server.