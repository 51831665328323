import React from "react";
import {useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {GETUSPORDERS} from "../../../function/orders";
import {useState} from "react";
import {Card} from "antd";
import {Switch, Table} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {Button} from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import {useNavigate} from "react-router-dom";
import "../../css/orders.css";
import moment from "moment";
import ordersimg from "../../images/ordersimg.png";
import LocalSearch from "../../search/localsearch";
import {CloudDownloadOutlined, EyeOutlined} from "@ant-design/icons";
import {EditOutlined, EyeTwoTone} from "@ant-design/icons";

const SERVICEPROVIDERORDERSDETAILS = () => {
    const [keyword, setKeyword] = useState("");
    const search = (keyword) => (c) =>
        c.transactioncode.toLowerCase().includes(keyword);

    const [pt, setPt] = useState("");
    const [orders, setOrders] = useState([]);
    const {user} = useSelector((state) => ({...state}));
    const [loadings, setLoadings] = useState([]);
    const navigate = useNavigate();
    let publicid = user.pid;

    useEffect(() => {
        // updated GETUSPORDERS(user.token, publicId) to GETUSPORDERS(user.token)
        GETUSPORDERS(user.token).then((res) => {
            setOrders(res.data);
            {
                orders.map((order) => {
                    {
                        order.paymentIntent.map((opi) => {
                            setPt(opi.paymenttype);
                        });
                    }
                });
            }
        });
    }, []);

    const handleCards = (card) => {
    };

    return (
        <>
            <div className="container">
                <div clasName="row ">
                    <div className="col-12 mt-5">
                        <div className="card servicceProvidersCard table-responsive">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-5">
                                        <div>
                                            <img width={"70px"} src={ordersimg}/>
                                            <b className="h4 ms-2">Orders</b>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="mt-2">
                                            <LocalSearch
                                                placeholder="search By Name"
                                                keyword={keyword}
                                                setKeyword={setKeyword}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="float-end me-auto">
                                            {" "}
                                            <button className=" btn btn-light-info h5">
                                                <CloudDownloadOutlined style={{fontSize: "20px"}}/>{" "}
                                                Export
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <table className="serviceTable table ">
                                <thead className="">
                                <tr className=" text-center">
                                    <th>#</th>
                                    <th>Customer</th>
                                    <th>SubScription</th>
                                    <th>Cards Number</th>
                                    <th>Amount</th>
                                    <th>fee</th>
                                    <th>Net Amount</th>
                                    <th>Type OF Payment</th>
                                    <th>payment status</th>
                                    <th>name</th>
                                    <th>Date of order</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orders.length ? (
                                    orders.map((order, index) => {
                                        return (
                                            <>
                                                <tr className="text-center">
                                                    <th scope="row">#{index + 1}</th>
                                                    <td>{order.email}</td>
                                                    <td>{order.subScriptionType}</td>
                                                    <td>{order.cards.length}</td>


                                                    {order.paymentIntent.map((opi) => {
                                                        return (
                                                            <>
                                                                <td className="text-dark">  {order.currency === "EUR" ? "€" : "$"}{opi.amount}</td>
                                                                {order.amountaaf ? (
                                                                    <td> {order.currency === "EUR" ? "€" : "$"}{(Number(opi.amount) - Number(order.amountaaf)).toFixed(2)}</td>) : (
                                                                    <td>-</td>)}
                                                                <td>
                                                                    {order.currency === "EUR" ? "€" : "$"} {order.amountaaf ? order.amountaaf : "-"}
                                                                </td>
                                                                <td> {opi.paymenttype}</td>
                                                            </>
                                                        );
                                                    })}
                                                    <td>{order.status}</td>

                                                    {order.paymentDetails.map((op) => {
                                                    })}
                                                    <td>{order.name ? order.name : "No name"}</td>
                                                    <td>
                                                        {moment(order.createdAt).format(
                                                            "D/M/Y h:mm:ss A"
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Link
                                                            className=" "
                                                            to={`/serviceprovider/Customerorders/Edit/${order._id}`}
                                                        >
                                                            <EditOutlined className="eyeOutline text-danger me-2"/>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                ) : (
                                    <h1 className="text-danger">no data for now</h1>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* ///////////////////////////////////////////// */}
        </>
    );
};

export default SERVICEPROVIDERORDERSDETAILS;
