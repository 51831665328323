import React, { useEffect } from "react";
import "../css/NewLogin.css";
import { toast } from "react-toastify";
import { SyncOutlined } from "@ant-design/icons";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { auth } from "../../firebase";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CRUDUSER, LOGIN, USERCHECK } from "../../function/auth";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import SideDrawer from "../drawer/sideDrawer";
import SliderCaptcha from "@slider-captcha/react";
const NewLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [robot, setRobot] = useState(true);
  const { user } = useSelector((state) => ({ ...state }));
  useEffect(() => {
    if (user) {
      //    console.log(user)
    }
  }, []);
  const handleISNOTBlocked = async () => {
    try {
      const result = await auth.signInWithEmailAndPassword(email, password);
      const { user } = result;
      const idTokenResult = await user.getIdTokenResult();
      console.log(idTokenResult);
      LOGIN(idTokenResult.token)
        .then((res) => {
          dispatch({
            type: "LOGED_IN_USER",
            payload: {
              // this way we can get the info from fire base directelly
              // email: user.email,
              // displayName: user.displayName,
              // token: idTokenResult.token
              //and this way we  got the data from our backend server from resbonse
              name: res.data.name,
              email: res.data.email,
              role: res.data.role,
              pid: res.data.publicid,
              splevel: res.data.splevel,
              token: idTokenResult.token,
              _id: res.data._id,
              joinedTime: res.data.createdAt,
            },
          });
          if (res.data.role === "admin") {
            navigate("/admin/dashboard");
          } else {
            navigate("/");
          }
        })
        .catch((err) => {});
    } catch (error) {
      if (error) toast.error("Somthing went Wrong PLease try later!");
      setLoading(false);
      // toast.error(error.message)
    }
  };
  useEffect(() => {
    if (user) {
      //  console.log(user)
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    USERCHECK(email).then((res) => {
      console.log(res);
      try {
        if (res.data === "email does not exist") {
          toast.error("user not found please register first");
          setLoading(false);
        }
        // console.log(res.data.accountstatus)
        if (res.data === "blocked") {
          toast.error("user has been Blocked PLease Try Later");
          setLoading(false);
        } else {
          handleISNOTBlocked();
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  function verifiedCallback(token) {
    console.log("Captcha token: " + token);
  }
  function Recaptcha() {
    sessionStorage.setItem("captcha", "123");
    return (
      <SliderCaptcha
        create={`${process.env.REACT_APP_API}/captcha/create`}
        verify={`${process.env.REACT_APP_API}/captcha/verify`}
        callback={verifiedCallback}
      />
    );
  }
  return (
    <>
      <div className="container-fluid ">
        <div className="row mt-5 mb-5 bg-light rounded loginpage ">
          <div className="col-sm-12 col-md-6 d-flex justify-content-center">
            <div className="leftside mt-5">
              <div className="mt-3">
                <h2 className="textlogin fw-bolder text-center">
                  Login to your account
                </h2>
              </div>
              <div className="d-flex justify-content-center">
                <img src={logo} className="img-fluid w-50" />
              </div>
            </div>
          </div>
          <div className="col-sm-10 col-md-6">
            <form
              onSubmit={handleSubmit}
              control=""
              className="form-group m-5 "
            >
              <div className="row">
                <label className="empas ">Email</label>
                <input
                  type="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control emailinput"
                />
              </div>
              <div className="row  mt-3">
                <label className="empas">Password</label>
                <input
                  required
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control passinput "
                />
              </div>
              <div className="row mt-3">
                <Link to="/resetpassword" className="text-dark">
                  Forget Password?
                </Link>
                <br />
              </div>
              <div className="row mt-3">
                <button
                  onClick={() => {}}
                  className="btn loginbtn  mt-5"
                  type="submit"
                  value="Submit"
                >
                  {loading ? (
                    <SyncOutlined spin twoToneColor="#108fff" />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewLogin;

const last = () => {
  <div>
    <div className="container-fluid d-flex justify-content-center mb-5">
      <div className="row  mt-5">
        <div className="col-md-6 text-center ">
          <span className="company__logo">
            <h2>
              <span className="fa fa-android"></span>
            </h2>
          </span>
          <img src={logo} />
        </div>
        <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
          <div className="container-fluid">
            <div className="row">
              <h2>Log In</h2>
            </div>
            <div className="row"></div>
            <div className="row">
              <p>
                Don't have an account?{" "}
                <Link to={"/register"}>Register Here</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};
