import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import 'bootstrap';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Space } from 'antd';
import 'antd/dist/antd.min.css'
import '../css/register.css'
import { auth } from '../../firebase'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import firebase from "firebase/compat/app";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { CRUDUSER, LOGIN } from '../../function/auth';
import { useDispatch } from 'react-redux';
import { Alert, Spin } from 'antd';
import { useSelector } from 'react-redux';
import PasswordStrengthBar from 'react-password-strength-bar';
const RegisterComplete = () => {
  const [nof, setNof] = useState(1)
  const [loading, setLoading] = useState(false)
  const [zipCode, setZipCode] = useState()
  const [street, setStreet] = useState()
  const [streetNumber, setStreetNumber] = useState()
  const [defaultContry, setDefaultContry] = useState()
  const [address, setAddress] = useState([])
  const [values, setValues] = useState()
  const [name, setName] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [phone, setPhone] = useState()
  const [contry, setContry] = useState()
  const [region, setRegion] = useState()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector(state => ({ ...state }))
  useEffect(() => {

    setValues(window.localStorage.getItem('values'))
    setDefaultContry(window.localStorage.getItem('Defaultcontry'))
    setStreet(window.localStorage.getItem('street'))
    setZipCode(window.localStorage.getItem('zipCode'))
    setStreetNumber(window.localStorage.getItem('streetNumber'))
    setEmail(window.localStorage.getItem('emailForSignIn'))
    setName(window.localStorage.getItem('name'))
    setPhone(window.localStorage.getItem('phone'))
    setContry(window.localStorage.getItem('contry'))

    setRegion(window.localStorage.getItem('region'))

  }, [])
  let usenavigate = useNavigate()
  const publicid = Math.floor(100000 + Math.random() * 900000)
  const Rn = () => {




    return (
      <>
        {threeHeaders}

        <h3 class="app__random-no-heading">{publicid}</h3>
        {/* {console.log(publicid)} */}

      </>
    )
  }
  const threeHeaders = Array.from({ length: 1 }, (_, index) => {
    return <Rn key={index} />;
  });

  const [alertVisible, setAlerVisible] = useState(false)
  const handleSubmit = async (e) => {


    e.preventDefault()


    //validation
    setLoading(true)
    Rn()
    if (password !== password2) {
      toast.error("Password are not the same")
      setLoading(false)
      return
    }
    if (!email || !password) {
      toast.error("ُEmail and Password are required ")
      return
    }
    if (password.length < 6) {
      toast.error('password are too short!!')
      return
    }
    let check = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    if (!password.match(check)) {
      setAlerVisible(true)
      setLoading(false)
      return
    }

    setAlerVisible(false)

    try {
      const result = await auth.signInWithEmailLink(email, window.location.href)


      // console.log(result)
      if (result.user.emailVerified) {
        //remove email from local storeage
        window.localStorage.removeItem('emialForRegisteration')
        window.localStorage.removeItem('phone')
        window.localStorage.removeItem('contryCode')
        window.localStorage.removeItem('email')
        window.localStorage.removeItem('name')

        // get user  id  token then redirect
        let user = auth.currentUser
        // update user password
        await user.updatePassword(password)
        /// get the token 
        const idTokenResult = await user.getIdTokenResult()

        //reduxt store
        // console.log('user',user,"token",idTokenResult)
        CRUDUSER(idTokenResult.token, { name, email, phone, publicid, address: [{ contry: contry || defaultContry, defaultContry: defaultContry, street: street, streetNumber: streetNumber, zipCode: zipCode }] })
          .then((res) => {
            LOGIN(idTokenResult.token)
            dispatch({
              type: "LOGED_IN_USER",
              payload: {
                // this way we can get the info from fire base directelly 
                // email: user.email,
                // displayName: user.displayName,
                // token: idTokenResult.token  
                //and this way we  got the data from our backend server from resbonse
                name: res.data.name,
                email: res.data.email,
                role: res.data.role,
                // picture: user.photoURL,
                pid: res.data.publicid,
                splevel: res.data.splevel,
                // picture: res.data.picture,
                token: idTokenResult.token,
                _id: res.data._id
              }
            })

            //  console.log("res--------->",res)
          }).catch((err) => {
            console.log("erre message",err)
            toast.error(err)
            // navigate("/login")
          })

        navigate("/")

        setEmail('')
        setAddress('')
        setContry('')
        setPhone('')
        setRegion('')
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      toast.error(error.message)
      setEmail('')
      setPassword('')
      setLoading(false)

    }

  }

  const Form = () => {



    return (<> <div className='container'>
      <div> <h5 className='text-center mt-5 os'>You're One Step Far for Creating Account!</h5></div>
      <div className="  border rerow bg-light mb-5">
        <div className="row d-flex justify-content-center   h-100">



          <div className="row   ">
            {!name && !email ? (<><div className='alert alert-danger'>
              *<b>Please register from the same Broweser</b>
            </div></>) : ''}

            <div className="col-lg-6   text-white py-4  ">
              <form className="" onSubmit={handleSubmit}>





                <div className="form-group mt-3">
                  <label className='text-dark'>Full Name <b className='star'>*</b></label>
                  <input type="text" disabled className="form-control " value={name}
                  />
                </div>
                <div className="form-group"></div>






                <div className="form-group ">

                  <label className='text-dark'>Email <b className='star'>*</b></label>
                  <input type="email" className="form-control" disabled required value={email}
                  />


                </div>   <div className="form-group mt-3">
                  <label className='text-dark'>Country <b className='star'>*</b></label>

                  <input type="text" className="form-control" name='contry' value={contry || defaultContry} disabled />
                </div>

                <div className="row">

                  <div className="col-md-12  pb-2">

                    <div className="form-outline form-white">

                      <div className='col  mt-3'>
                        <label className='text-dark'>Street <b className='star'>*</b></label>
                        <input value={street} required className="form-control" placeholder='Street ' type='text' disabled />
                      </div>
                      <div className='col  mt-3'>
                        <label className='text-dark'>Street Number <b className='star'>*</b></label>

                        <input value={streetNumber} required className="form-control" type='number' disabled />

                      </div>
                    </div>

                  </div>
                </div>


                <div className="form-outline form-white ">
                  <h6>Phone <b className='star'>*</b></h6>
                  <div className='row'>

                    <div className='col'>
                      <div className="form-group ">
                        <input type="text" className="form-control" name='phone' value={phone} disabled />
                      </div>
                      <div className="form-group mt-2">
                        <label className='text-dark'>Zip code <b className='star'>*</b></label>
                        <input className="form-control" required disabled type='number' value={zipCode} />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="form-group ">
                  Enter New Password
                  <Input.Password
                    type="password" className="form-control" placeholder="Password *" value={password}
                    onChange={(e) => setPassword(e.target.value)} autoFocus
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                  <Input.Password
                    type="password" className="form-control mt-3" placeholder="RETYPE Password *" value={password2}
                    onChange={(e) => setPassword2(e.target.value)} autoFocus
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />

                </div><br />
                <PasswordStrengthBar password={password} />

                {alertVisible ? (<><div className='alert alert-danger'>
                  <ul>
                    <b> Password Should Contain</b>
                    <li>Cpital letter </li>
                    <li>Numbers</li>
                    <li>Symbols</li>
                  </ul>
                </div></>) : ''}
                <div>                          {loading ? (<div className='d-flex justify-content-center'><Spin tip="Loading...">

                </Spin></div>) : <button type="submit" id='sbtn' className="btn w-100 mt-3"  >Submit and Login</button>}
                  {/* !email.includes("@") || !email.includes(".com") */}
                </div>

                <div className="form-check d-flex justify-content-start mb-4 pb-3">



                </div>


              </form>
            </div>
            <div className="col-lg-6 bgimg2 ">

            </div>
          </div>
        </div>
      </div>

    </div>


    </>)
  }
  return (
    <div className="container  register " >

      <div className='row text-dark '>

        {Form()}
        <ToastContainer />
      </div>
    </div>

  )

};

export default RegisterComplete