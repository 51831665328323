import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../css/history.css";
import { useState } from "react";
import LocalSearch from "../../search/localsearch";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input, Space } from "antd";
import { useEffect } from "react";
import { GETORDERS } from "../../../function/orders";
import { useSelector } from "react-redux";
import { GETCUSTOMERS, GETSPCUSTOMERS } from "../../../function/customer";
import { CloudDownloadOutlined, EyeOutlined } from "@ant-design/icons";
import customer from "../../images/customer.png";
const SPCustomers = () => {
  const [keyword, setKeyword] = useState("");
  const search = (keyword) => (c) => c.toLowerCase().includes(keyword);
  const [customers, setCustomers] = useState([]);
  let { user } = useSelector((state) => ({ ...state }));
  let resllerid = user.pid;
  console.log(user, resllerid);
  useEffect(() => {
    GETSPCUSTOMERS(user.token, resllerid).then((res) => {
      setCustomers(res.data);
    });
  }, []);

  return (
    <>
      {/* ////////////////////////////////////////////////////////////////////////////////////// */}
      <div className="row mt-5 p-2">
        {/* <OldTable/> */}
        <div className="container">
          <div clasName="row">
            <div className="col-12">
              <div className="card servicceProvidersCard table-responsive">
                <div className="card-header">
                  <div className="row">
                    <div className="col-5">
                      <div>
                        <img width={"70px"} src={customer} />
                        <b className="h4 ms-2">Customers</b>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="mt-2">
                        <LocalSearch
                          placeholder="search By Name"
                          keyword={keyword}
                          setKeyword={setKeyword}
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="float-end me-auto">
                        {" "}
                        <button className=" btn btn-light-info h5">
                          <CloudDownloadOutlined style={{ fontSize: "20px" }} />{" "}
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <table className="serviceTable table  ">
                  <thead className="">
                    <tr>
                      <th>#</th>

                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.length ? (
                      customers.filter(search(keyword)).map((email, index) => (
                        <tr>
                          <td>{index + 1} </td>

                          <td>{email}</td>
                        </tr>
                      ))
                    ) : (
                      <h1 className="text-danger">no data for now</h1>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SPCustomers;
