import React from 'react';
import { Drawer,Button } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {MenuUnfoldOutlined}from '@ant-design/icons';
import logo from '../images/logo.png'

import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { UserOutlined,HistoryOutlined ,ArrowDownOutlined} from "@ant-design/icons";
import { AiOutlineSetting } from "react-icons/ai";
import { GoGraph } from "react-icons/go";
import { BiLogOut } from "react-icons/bi";
import {  useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";



const SideDrawer=()=>{
  const navigate = useNavigate();






  const logout = () => {
    firebase.auth().signOut();
    dispatch({
      type: "LOGE_OUT",
      payload: null,
    });

    navigate("/login");
  };


    const Close=()=>{
     
            /// close sideDrawe
            dispatch({
                type: "SET_VISIBLE",
                payload: false,
            });     
 
    }
    const dispatch=useDispatch()
    const {drawer,user}=useSelector((state)=>({...state}))
   
   
    return(
        
    <Drawer 
  
    placement='right'
    
 
    title={<></>} 
   size={{
      xs: 24,
      sm: 32,
      md: 40,
      lg: 64,
      xl: 80,
      xxl: 100,
    }}
    onClose={()=>{
            /// close sideDrawe
            dispatch({
                type: "SET_VISIBLE",
                payload: false,
            });     
    }} visible={drawer}>
        <h6 className='text-center text-success'>TATHKEER</h6>
        <div className='d-flex justify-content-center'>
            {user&&user.role==='admin'&&(<><div className='container-fluid'>
                <div className='row'>
                    <h4 className='text-dark text-capitalize text-center'>{user.name} ({user.role})</h4>   
            
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/admin/dashboard'><Button onClick={Close}   icon={<GoGraph  className='h6 text-danger me-2'/>} type="link"> Dashboard</Button></Link>
                </div>
                <div className='row mt-3'>
 <br/><Link to='/profile'><Button onClick={Close}   icon={<UserOutlined  className='h6  me-2 '/>} type="link">Profile</Button></Link>
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/admin/setting' onClick={Close} ><Button     icon={<AiOutlineSetting  className='h6 text-warning  me-2 '/>} type="link">Settings</Button></Link>
                </div>
                 <div className='row mt-3'>
        <br/><Link to='/admin/setting' onClick={logout} ><Button     icon={<BiLogOut  className='h6 text-dark  me-2'/>} type="link">logout</Button></Link>
                </div>
            </div>
                </>)}
                  {user&&user.role==='serviceprovider'&&(<><div className='container-fluid'>
                <div className='row'>
                    <h4 className='text-dark text-capitalize text-center'>{user.name} ({user.role})</h4>   
            
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/serviceprovider/dashboard'><Button onClick={Close}   icon={<GoGraph  className='h6 text-danger me-2'/>} type="link"> Dashboard</Button></Link>
                </div>
                <div className='row mt-3'>
 <br/><Link to='/profile'><Button onClick={Close}   icon={<UserOutlined  className='h6 text-info  me-2 '/>} type="link">Profile</Button></Link>
                </div>
                 <div className='row mt-3'>
 <br/><Link to='/serviceprovider/history' onClick={Close} ><Button     icon={<HistoryOutlined  className='h6 text-primary  me-2 '/>} type="link">History</Button></Link>
                </div>
                 <div className='row mt-3'>
        <br/><Link to='/admin/setting' onClick={logout} ><Button     icon={<BiLogOut  className='h6 text-dark  me-2'/>} type="link">logout</Button></Link>
                </div>
            </div>
                </>)}
  


         
            {!user&&(<img src={logo}  width="150px"/>)}
            </div>
            {!user&&(<Link to='/login' >
                <button onClick={()=>
                    dispatch({
                        type:"SET_VISIBLE", 
                        payload:false
                    })
                } className='text-center btn btn-success btn-raised btn-block'>
                    Login
            </button>
            </Link>)}
           {!user&&(  <Link to='/register' >
                <button onClick={()=>
                    dispatch({
                        type:"SET_VISIBLE",
                        payload:false
                    })
                } className='text-center btn btn-primary mt-2 btn-raised btn-block'>
                    Register
            </button>
            </Link>)}
    </Drawer>
   )
}
export default SideDrawer
