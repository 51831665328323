import React, { useEffect, useState, Component } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Col, Dropdown, Typography } from "antd";
import { Select } from "antd";
import { Checkbox, Menu } from "antd";
import axios from "axios";
import "../css/home.css";
import Alert from "react-bootstrap/Alert";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    EyeInvisibleOutlined,
    EyeTwoTone,
    PlusCircleOutlined,
} from "@ant-design/icons";
import { Input, Space, Radio } from "antd";
import { toast } from "react-toastify";
import { FaCcPaypal } from "react-icons/fa";
import { FaCreditCard } from "react-icons/fa";
import { AiOutlineBank } from "react-icons/ai";
import CreditCard from "./payment/newCard";
import "../css/paymentform.css";
import Credit from "./credit";
import { Link } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { createCards } from "../../function/cards";
import {
    CONFIRMORDER,
    confirmwithoutimg,
    createOrder,
    createTransActionOrder,
    GETORDERSTATUS,
    CREATEOTP,
} from "../../function/orders";
import { CHECKPUBLICID } from "../../function/customer";
import himg from "../images/Vector367.png";
import headerimage from "../images/headerimage.png";
import { Tooltip, Button } from "antd";
import Axios from "axios";
import moment from "moment";
import { UPDATEORDERSTATUS } from "../../function/orders";
import { CopyOutlined } from "@ant-design/icons";
import {
    CRUDBEBANK,
    CRUDPAYPAL,
    CRUDTRBANK,
    CRUDUKBANK,
    CRUDUSBANK,
    GETBEBANKINFO,
    GETPAYPALINFO,
    GETTRBANKINFO,
    GETUSBANKINFO,
    GETUKBANKINFO,
    GETALL,
} from "../../function/payment";
import StripeCheckout from "../stripe/stripecheckout";
import Payment from "./payment";
import { getVisiblity } from "../../function/settings";
import { getclientid } from "../../function/payment";
import PaymentForm from "./meps/paymentform";
import Card from "./Card";
import { set } from "mongoose";

var imgid;
var countAx = 2;

class FilesUploadComponent extends Component {
    constructor(props) {
        super(props);

        this.onFileChange = this.onFileChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleConfirmewithoutimg = this.handleConfirmewithoutimg.bind(this);
        this.state = {
            image: "",
            loading: false,
            _id: this.props._id,
        };
    }

    onFileChange(e) {
        //    console.log(e.target.files[0])
        this.setState({ image: e.target.files[0] });
    }

    handleConfirmewithoutimg(e) {
        e.preventDefault();

        confirmwithoutimg(this.props._id).then((res) => {
            if (res.data.ok === true) {
                this.setState({ loading: false });
                this.props.handleOrderStatusCheck();
                toast.success("Order Confirmed Succesfully!");
            } else {
                toast.error("somthing went wrong please try later!");
            }
        });
    }

    onSubmit(e) {
        this.setState({ loading: true });
        e.preventDefault();
        this.ob = {
            _id: 5,
        };
        //   console.log("asdasdaiughiagfgio",this.props._id)

        const formData = new FormData();
        formData.append("image", this.state.image);
        formData.append("_id", this.props._id);

        axios
            .post(
                `${process.env.REACT_APP_API}/uploadImage`,
                formData,
                this.props._id,
                {
                    headers: {
                        _id: this.props._id,
                    },
                }
            )
            .then((res) => {
                // console.log("response",res)
                if (res.data.ok === true) {
                    this.setState({ loading: false });
                    this.props.handleOrderStatusCheck();
                    toast.success("Order Confirmed Succesfully!");
                } else {
                    toast.error("somthing went wrong please try later!");
                }
            });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    {this.state.loading ? (
                        "Loading ..."
                    ) : (
                        <form>
                            <div className="form-group">
                                <input
                                    accept="image/png, image/gif, image/jpeg"
                                    type="file"
                                    onChange={this.onFileChange}
                                />
                            </div>
                            <div className="form-group">
                                {this.state.image ? (
                                    <button className="btn btn-primary " onClick={this.onSubmit}>
                                        Confirm banktransfer img
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-primary  mt-2"
                                        onClick={this.handleConfirmewithoutimg}
                                    >
                                        Confirm without picture
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </div>
            </div>
        );
    }
}

const Next = (props) => {
    const { Option } = Select;
    const [currency, setCurrency] = useState("€");
    const [euroCurrency, setEuroCurrency] = useState("€");
    const [dollarCurrency, setDollarCurrency] = useState("$");
    const [checked, setChecked] = useState(false);
    const [message, setMessage] = useState("");
    const { order } = props;
    const [image, setImage] = useState("");
    const [selectedBankAccount, setSelectedBankAccount] =
        useState("united state");
    const [credit, setCredit] = useState(false);
    const [amount1, setAmount1] = useState(25);
    const [amount2, setAmount2] = useState(0);
    const [amount1indollar, setAmount1indollar] = useState();
    const [amount2indollar, setAmount2indollar] = useState();
    const [amount1inbound, setAmount1inbound] = useState();
    const [amount2inbound, setAmount2inbound] = useState();
    const [paypal, setPaypal] = useState(false);
    const [hideFirstStep, setHideFirstStep] = useState(false);
    // ahmad update the inital value from undefiend to null
    const [resllerid, setResllerid] = useState(null);
    const [cardsNumber1, setCardsNumber1] = useState(1);
    const [cardsNumber2, setCardsNumber2] = useState(0);
    const [subSucribtion2, setSubSucribtion2] = useState(15);
    const [subSucribtion1, setSubSucribtion1] = useState(25);
    const [subSucribtion, setSubSucribtion] = useState(25);
    const [amountindollar, setAmountindollar] = useState();
    const [amountinbound, setAmountinbound] = useState();
    const [subScriptionType, setSubScriptionType] = useState();
    const [subScriptionType2, setSubScriptionType2] = useState("6 Months");
    const [subScriptionType1, setSubScriptionType1] = useState("1 year");
    const [amount, setAmount] = useState(25);
    const [codes, setCodes] = useState([]);
    const [cardsNumberisValid, setCardsNumberisValid] = useState(false);
    const [paymentIntent, setPaymentIntent] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [email, setEmail] = useState("");
    const [bankTransAction, setBankTransAction] = useState(false);
    const [paymenttype, setPaymenttype] = useState("paypal");
    const [orderCheckemail, setOrderCheckemail] = useState("");
    const [bankTransActionAccountNumber, setBankTransActionAccountNumber] =
        useState(56464645448);
    const [bankCode, setBankCode] = useState();
    const [emailCheckAlert, setEmailCheckAlert] = useState("");
    const [otp, setOtp] = useState("");
    const [otpFromEmail, setOtpFromEmail] = useState("");

    const [toggleEmailField, setToggleEmailField] = useState(true);
    const [toggleOtpField, setToggleOtpField] = useState(false);
    const [verfiyEmailBtn, setVerfiyEmailBtn] = useState(false);
    const [confiremBtn, setConfiremBtn] = useState(false);

    const [info, setInfo] = useState([]);
    const [from, setFrom] = useState("eur");
    const [to, setTo] = useState("USD");
    const [output, setOutput] = useState(0);
    const dispatch = useDispatch();
    const [clientId, setClientId] = useState();
    // const [orederDetails,setOrderDetails]=useState([resllerid,paymentIntent,subSucribtion])
    const orederDetails = {
        email,
        resllerid,
        paymentIntent,
        subScriptionType,
        paymentDetails,
        codes,
        bankCode,
    };
    const handleChangeImage = (e) => {
        setImage({ [e.target.name]: URL.createObjectURL(e.target.files[0]) });
    };

    const threeHeadersAc = () =>
        Array.from({ length: 1 }, (_, index) => {

            const bankCode = Math.floor(Math.random() * 1e10).toString().padStart(10, '0');


            setBankCode(bankCode);

            return (
                <>
                    <h3 class="app__random-no-heading" key={index}>
                        {bankCode}
                    </h3>
                </>
            );
        });

    const Accept = (e) => {
        setChecked(e.target.checked);
    };
    useEffect(() => {
        Axios.get(
            `https://v6.exchangerate-api.com/v6/${process.env.REACT_APP_EXCHANGERATEKey}/latest/${from}`
        ).then((res) => {
            setInfo(res.data.conversion_rates);
        });
    }, [amount, paymenttype]);

    useEffect(() => {
        console.log(
            `now currency is ${currency}`,
            `from is ${from} and to is ${to}`
        );
        var rate = info[to];

        if (currency === "$") {
            const values =
                subSucribtion1 * Math.round(cardsNumber1) +
                subSucribtion2 * Math.round(cardsNumber2);
            const firstcardindollar = (
                subSucribtion1 *
                Math.round(cardsNumber1) *
                rate
            ).toFixed(2);
            setAmount1indollar(firstcardindollar);
            const secondcardindollar = (
                subSucribtion2 *
                Math.round(cardsNumber2) *
                rate
            ).toFixed(2);
            setAmount2indollar(secondcardindollar);
            //  console.log(values, );
            const amountindollar = values * rate;
            //  console.log(amountindollar);
            setAmountindollar(amountindollar.toFixed(2));
            // const amountindollar = amount+DeffrenceBetweenCurrencies;
            //    console.log(amountindollar.toFixed(2));
            // handlePaymentIntent()
        } else if (currency === "€") {
            setAmount(
                subSucribtion1 * Math.round(cardsNumber1) +
                subSucribtion2 * Math.round(cardsNumber2)
            );
        } else if (currency === "£") {
            const values =
                subSucribtion1 * Math.round(cardsNumber1) +
                subSucribtion2 * Math.round(cardsNumber2);
            const firstcardinbound = (
                subSucribtion1 *
                Math.round(cardsNumber1) *
                rate
            ).toFixed(2);
            setAmount1inbound(firstcardinbound);
            const secondcardinbound = (
                subSucribtion2 *
                Math.round(cardsNumber2) *
                rate
            ).toFixed(2);
            setAmount2inbound(secondcardinbound);
            console.log("values", values, rate);
            const amountinbound = values * rate;
            //  console.log(amountindollar);
            setAmountinbound(amountinbound.toFixed(2));
        }
    }, [amount, currency, amount1, amount2]);

    const threeHeaders = () =>
        Array.from({ length: cardsNumber1 }, (_, index) => {
            const code = Math.floor(1000000000 + Math.random() * 8000000000);
            const date = new Date();
            if (subSucribtion1 === 25) {
                date.setMonth(date.getMonth() + 12);
            } else {
                date.setMonth(date.getMonth() + 6);
            }
            let subscribe = subScriptionType1;
            let object = { code, Expire: date, subscribe };

            codes.push(object);
            return (
                <>
                    <h3 class="app__random-no-heading" key={index}>
                        {code}
                    </h3>
                </>
            );
        });
    const threeHeaders2 = () =>
        Array.from({ length: cardsNumber2 }, (_, index) => {
            const code = Math.floor(1000000000 + Math.random() * 8000000000);

            const date = new Date();
            if (subSucribtion2 === 25) {
                date.setMonth(date.getMonth() + 12);
            } else {
                date.setMonth(date.getMonth() + 6);
            }

            let subscribe = subScriptionType2;
            let object = { code, Expire: date, subscribe };

            codes.push(object);
            return (
                <>
                    <h3 class="app__random-no-heading" key={index}>
                        {code}
                    </h3>
                </>
            );
        });

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handlePaymentIntent = () => {
        let object = {};
        object.cardsNumber = Number(cardsNumber1) + Number(cardsNumber2);
        object.amount = amount;
        console.log('paymentIntent', paymentIntent)

        if (currency === "$") {
            object.amount = amountindollar;
        } else if (currency === "€") {
            object.amount = amount;
        } else if (currency === "£") {
            console.log("currency", currency, "amount is ", amountinbound);
            object.amount = amountinbound;
        }

        object.paymenttype = paymenttype;
        paymentIntent.push(object);

        // Check if a similar object already exists in paymentIntent
        const isDuplicate = paymentIntent.some((existingObject) => {
            return (
                existingObject.cardsNumber === object.cardsNumber &&
                existingObject.amount === object.amount &&
                existingObject.paymenttype === object.paymenttype
            );
        });

        if (!isDuplicate) {
            console.log("object", object);
            paymentIntent.push(object);
        } else {
            console.log("Duplicate object found, not adding to paymentIntent");
        }
    };

    const handleCredit = () => {
        setCurrency("€");
        setTo("EUR");
        handleSubsc();
        setPaymenttype("card");
        if (!email) {
            toast.warning("PLease Enter Valid Email");
        } else {
            if (!resllerid) {
                toast.warning("PLease Enter Valid Reseller ID");
            } else {
                CHECKPUBLICID(resllerid).then((res) => {
                    if (res.data.ok !== true) {
                        toast.warning("PLease Enter Valid Reseller id");
                    } else {
                        if (cardsNumber1 < 1 && cardsNumber2 < 1) {
                            setBankTransAction(false);
                            setPaypal(false);
                            setCredit(false);
                            toast.error("Please Enter Valid Cards Number");
                            return;
                        }
                        if (cardsNumber1 > 5000 || cardsNumber2 > 5000) {
                            setBankTransAction(false);
                            setPaypal(false);

                            setCredit(false);
                            toast.error("Please Chose Correct Cards Number");
                            return;
                        } else {
                            window.scrollBy({
                                top: 600,
                                left: 100,
                                behavior: "smooth",
                            });
                            // handlePaymentIntent()
                            setCredit(true);
                            setBankTransAction(false);
                            setPaypal(false);
                        }
                    }
                });
            }
        }
    };
    const [acceptTerms, setAcceptTerms] = useState(false);
    const handleSubsc = () => {
        if (amount1 !== 0 && amount2 !== 0) {
            setSubScriptionType(["1 Year", "6 Months"]);
        } else {
            if (amount1 === 0 && amount2 !== 0) {
                setSubScriptionType(["6 Months"]);
            } else {
                if (amount1 !== 0 && amount2 === 0) {
                    setPaypal(false);
                    setSubScriptionType(["1 Year"]);
                }
            }
        }
    };

    const handleRejectTerms = () => {
        setAcceptTerms(false);
        setPaypal(false);
    };
    const hadnleAccepting = () => {
        setAcceptTerms(false);
        handlePaypal();
    };
    const handlePaypal = () => {
        setPaymenttype("paypal");
        setCurrency("€");
        setTo("EUR");
        handleSubsc();
        if (!email) {
            toast.warning("PLease Enter Valid Email");
        } else {
            if (!resllerid) {
                toast.warning("PLease Enter Valid Reseller ID");
            } else {
                // function in the bakend check if the publicId exist or not
                CHECKPUBLICID(resllerid).then((res) => {
                    if (res.data.ok !== true) {
                        toast.warning("PLease Enter Valid Reseller id");
                    } else {
                        if (cardsNumber1 < 1 && cardsNumber2 < 1) {
                            toast.error("Please Enter Valid Cards Number");
                            setBankTransAction(false);
                            setPaypal(false);
                            setCredit(false);
                            return;
                        }

                        if (cardsNumber1 > 5000 || cardsNumber2 > 5000) {
                            setBankTransAction(false);
                            setPaypal(false);
                            setCredit(false);
                            toast.error("Please Chose Correct Cards Number");
                            return;
                        } else {
                            window.scrollBy({
                                top: 600,
                                left: 100,
                                behavior: "smooth",
                            });
                            setPaymenttype("paypal");
                            handlePaymentIntent()
                            setCredit(false);
                            setPaypal(true);
                            setBankTransAction(false);
                        }
                    }
                });
            }
        }
    };

    const hadnleCancel = (e) => {
        // e.preventDefault();
        setCodes([]);
        setPaymentIntent([]);
        setCardsNumber1(0);
        setAmount(subSucribtion);
        window.location.reload();
    };

    const PayPalCheckOut = () => {
        const [paidFor, setPaidFor] = useState(false);
        const [error, setError] = useState(null);

        const handleApprove = (orderID) => {
            setPaidFor(true);
        };
        if (paidFor) {
            toast.success("Thanks For Use our Services");
        }
        if (error) {
            toast.error("err", error);
        }
        var data = {
            codes: codes,
            email: email,
        };
        const hadnleSuccess = () => {
            createOrder(orederDetails).then((res) => {
                if (res.data.ok) {
                    // axios;
                    // .post(`${process.env.REACT_APP_API}/mail`, data)
                    // .then((res) => {
                    // console.log(res)
                    // })
                    // .catch((err) => {
                    // console.log(err)
                    // });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
                toast.success("Order Sent Succesffully!");
            });
            createCards(codes).then((res) => {
                // toast.success("cards Stored Successefully!")
            });
        };

        return (
            <>
                <div className="container ">
                    <div className="row mt-3 d-flex justify-content-center">
                        <div className="col-md-6">
                            <PayPalScriptProvider
                                options={{
                                    "client-id": `${clientId}`,
                                    currency: "EUR",
                                    commit: false,
                                }}
                            >
                                <PayPalButtons
                                    onClick={(data, action) => {
                                        const hasAlreadyBought = false;

                                        if (hasAlreadyBought) {
                                            setError("You already bought");
                                            return action.reject();
                                        } else {
                                            return action.resolve();
                                        }
                                    }}
                                    createOrder={(data, action) => {
                                        handlePaymentIntent();
                                        return action.order.create({
                                            purchase_units: [
                                                {
                                                    description:
                                                        "this is an payment for codes from https://tathkeer.net",
                                                    amount: {
                                                        value: paymentIntent[0].amount,
                                                    },
                                                },
                                            ],
                                        });
                                    }}
                                    onApprove={async (data, action) => {
                                        const order = await action.order.capture();
                                        handleApprove(data.orderID);
                                        // console.log("orderstatus", order.status)
                                        if (order.status === "COMPLETED") {
                                            threeHeaders();
                                            threeHeaders2();
                                            // console.log('submited')
                                            // console.log(cardsNumber1)
                                            // console.log(codes)
                                            // console.log(paymentIntent[0])
                                            setPaymenttype("paypal");
                                            setPaymentDetails([]);
                                            let object = {};
                                            object.order = order;
                                            paymentDetails.push(object);
                                            // console.log(paymentDetails)
                                            hadnleSuccess();
                                        }
                                    }}
                                    onCancel={hadnleCancel}
                                    onError={(err) => {
                                        setError(err);
                                        // console.log("paypal checkout in error", err);
                                    }}
                                />
                            </PayPalScriptProvider>
                        </div>
                    </div>

                    {/* <button className="paypalbtn btn w-100 mt-5">Pay With <b className="text-primary">PayPal</b></button> */}
                </div>
            </>
        );
    };

    const hadnleresellerId = (e) => {
        setResllerid(e.target.value);
        setPaypal(false);
        setCredit(false);
        setBankTransAction(false);
        setPaymentMethod("");
    };

    const onChangeCards = (e) => {
        handleSubsc();
        setCardsNumber1(e.target.value);
        if (e.target.value < 1 && cardsNumber2 === 0) {
            setPaypal(false);
            setCredit(false);
            setBankTransAction(false);
        }

        if (e.target.value > 5000) {
            toast.warning("maximum");
            setPaypal(false);
            setCredit(false);
            setBankTransAction(false);
        } else {
            setCardsNumberisValid(true);
        }
        setCardsNumberisValid(false);

        setAmount1(subSucribtion1 * Math.round(e.target.value));
    };

    const onChangeCards2 = (e) => {
        handleSubsc();

        if (amount2 === 0) {
            setPaypal(false);
        }

        setCardsNumber2(e.target.value);
        if (e.target.value == 0) {
            setPaypal(false);
            setCredit(false);

            setBankTransAction(false);
        }
        if (e.target.value > 5000) {
            toast.warning("maximum");
            setPaypal(false);
            setCredit(false);
            setBankTransAction(false);
        } else {
            setCardsNumberisValid(true);
        }
        setCardsNumberisValid(false);
        setAmount2(subSucribtion2 * Math.round(e.target.value));
    };

    //----------ahmad doesn't use this function any where in the code
    const onChangeSub = (e) => {
        setSubSucribtion(e.target.value);
        // setAmount(e.target.value * Math.round(cardsNumber1))
        // console.log(e.target.value)
        if (e.target.value === 25) {
            setSubScriptionType1("1 Year");
        } else {
            setSubScriptionType1("6 Months");
        }
    };

    const handleBankTransAction = () => {
        setCurrency("€");
        setTo("EUR");
        if (!email) {
            toast.warning("PLease Enter Valid Email");
        } else {
            if (!resllerid) {
                toast.warning("PLease Enter Valid Reseller ID");
            } else {
                //------ahmad backend function to check publicId of user if exists or not
                CHECKPUBLICID(resllerid).then((res) => {
                    if (res.data.ok !== true) {
                        toast.warning("PLease Enter Valid Reseller id");
                    } else {
                        if (cardsNumber1 < 1 && cardsNumber2 < 1) {
                            toast.error("Please Enter Valid Cards Number");
                            setCredit(false);
                            setBankTransAction(false);
                            setPaypal(false);
                            return;
                        }
                        if (cardsNumber1 > 5000 || cardsNumber2 > 5000) {
                            setBankTransAction(false);
                            setPaypal(false);
                            setCredit(false);
                            toast.error("Please Chose Correct Cards Number");
                            return;
                        } else {
                            setPaymenttype("banktransfer");
                            window.scrollBy({
                                top: 600,
                                left: 100,
                                behavior: "smooth",
                            });
                            setCredit(false);
                            setBankTransAction(true);
                            setPaypal(false);
                            if (!bankCode) {
                                // ----ahmad function to generate bank code if it's not exists
                                threeHeadersAc();
                            }
                        }
                    }
                });
            }
        }
    };

    const [paypalinfo, setPaypalinfo] = useState();
    const [allAccounts, setAllAcounts] = useState([]);
    const [usbankAccount, setUsbankAccount] = useState([]);
    const [ukbankAccount, setUkbankAccount] = useState([]);
    const [belgiumbank, setBelgiumbank] = useState([]);
    const [trybank, setTrybank] = useState([]);
    const [paypalvisible, setPaypalvisible] = useState("");
    const [cardvisible, setCardvisible] = useState("");
    const [bankvisible, setBankvisible] = useState("");

    useEffect(() => {
        getVisiblity().then((res) => {
            setPaypalvisible(res.data.paypal);
            setCardvisible(res.data.card);
            setBankvisible(res.data.bank);
        });
        handleSubsc();
        // if(){
        //     setCardsComplete(true)
        // }else{
        //     setCardsComplete(false)
        // }

        GETALL().then((res) => {
            // console.log("respone",res.data)
            setAllAcounts(res.data);
            {
                res.data.map((acc) => [
                    setUsbankAccount(acc.usbank),
                    setUkbankAccount(acc.ukbank),
                    setBelgiumbank(acc.belgiumbank),
                    setTrybank(acc.trybank),
                    setPaypalinfo(acc.paypal),
                    dispatch({
                        type: "Paypal",
                        payload: {
                            paypalAcc: acc.paypal,
                        },
                    }),
                ]);
            }
        });

        getclientid().then((res) => {
            setClientId(res.data);
        });
    }, [cardsNumber1, cardsNumber2]);

    let { user } = useSelector((state) => ({ ...state }));

    let { paypalAcc } = useSelector((state) => ({ ...state }));
    const HandleCopyBTAN = () => {
        navigator.clipboard.writeText(bankTransActionAccountNumber);
        toast.success(` ${bankTransActionAccountNumber} Copied `);
    };

    const HandleCopyBankCode = () => {
        navigator.clipboard.writeText(bankCode);
        toast.success(` ${bankCode} Copied `);
    };
    const handleConfirmBankTransAction = () => {
        // console.log(selectedBankAccount)
        // return
        handlePaymentIntent();

        threeHeaders();
        threeHeaders2();
        createTransActionOrder(orederDetails, selectedBankAccount).then((res) => {
            if (res.data) {
                toast.success("Order Sent Succesffully!");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        });
        setPaymentIntent([])
        createCards(codes).then((res) => {
            // toast.success("cards Stored Successefully!")
        });
    };

    const handleSelectBankAccount = (e) => {
        setSelectedBankAccount(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value.trim());
        setPaypal(false);
        setBankTransAction(false);
        setCredit(false);
        setPaymentMethod("");
    };

    // ahamd update start------------------

    const handleToglleEmailField = () => {
        setToggleEmailField(!toggleEmailField);
    };

    const handleToglleOtpField = () => {
        setToggleOtpField(!toggleOtpField);
    };

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
        if (e.target.value.length >= 5) {
            setConfiremBtn("enable");
        } else {
            setConfiremBtn("disabled");
        }
    };

    // ahmad update end ----------------------
    const emialRegx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleOrderCheckemail = (e) => {
        setOrderCheckemail(e.target.value);
        if (emialRegx.test(e.target.value)) {
            setVerfiyEmailBtn("enable");
        } else {
            setVerfiyEmailBtn("disabled");
        }
    };

    const [paymentMethod, setPaymentMethod] = useState("");

    const handlePayment = (event) => {
        // if (!email || !resllerid ) {
        //   setPaymentMethod("")
        //   // if email or reseller ID is empty, show an error message
        //   toast.error("Please enter required information.");

        // }else{
        setPaymentMethod(event.target.value);
        if (event.target.value === "paypal") {
            setAcceptTerms(true);
        }
        // enable payment method selection

        // if(event.target.value==='paypal'){
        //   setPaypalvisible(true)
        // }

        // }
    };

    useEffect(() => {
        switch (paymentMethod) {
            case "credit_card":
                handleCredit();
                break;
            case "paypal":
                handlePaypal();
                break;
            case "bank":
                handleBankTransAction();
                break;
            default:
                // handle invalid or empty selection
                break;
        }
    }, [paymentMethod]);

    const BankTransAction = () => {
        return (
            <>
                <h1 className="text-center">Bank TransAction</h1>
                <div className="alert alert-warning">
                    <p>Instructions *</p>
                    <ul>
                        <li>
                            choose Payment Country <br />
                            <select
                                class="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                onChange={handleSelectBankAccount}
                                value={selectedBankAccount}
                            >
                                {usbankAccount.map((usb) => {
                                    return (
                                        <>
                                            {usb.status === "visible" ? (
                                                <option selected value={"united state"}>
                                                    {usb.name}
                                                </option>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    );
                                })}
                                {belgiumbank.map((beb) => {
                                    return (
                                        <>
                                            {beb.status === "visible" ? (
                                                <option value={"Belgium"}>{beb.name}</option>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    );
                                })}
                                {ukbankAccount.map((ukb) => {
                                    return (
                                        <>
                                            {ukb.status === "visible" ? (
                                                <option value={"United Kingdom"}>{ukb.name}</option>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    );
                                })}
                                {trybank.map((trb) => {
                                    return (
                                        <>
                                            {trb.status === "visible" ? (
                                                <option value={"turkey"}>{trb.name}</option>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    );
                                })}
                            </select>
                            {selectedBankAccount === "united state" ? (
                                <>
                                    {usbankAccount.map((ac) => {
                                        return (
                                            <>
                                                {ac.status === "visible" ? (
                                                    <ul>
                                                        {ac.usaccountholder ? (
                                                            <li>ccountholder :{ac.usaccountholder}</li>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {ac.usroutingnumber ? (
                                                            <li>routingnumber :{ac.usroutingnumber}</li>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {ac.usaccounttype ? (
                                                            <li>accounttype :{ac.usaccounttype}</li>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {ac.usaccountnumber ? (
                                                            <li>accountnumber :{ac.usaccountnumber}</li>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {ac.uswisesaddress ? (
                                                            <li>wisesaddress :{ac.uswisesaddress}</li>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {ac.uscountry ? (
                                                            <li>country :{ac.uscountry}</li>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {ac.uscity ? <li>city :{ac.uscity}</li> : ""}
                                                        {ac.uscurrency ? (
                                                            <li>Currency:{ac.uscurrency}</li>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </ul>
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        );
                                    })}
                                </>
                            ) : (
                                ""
                            )}
                            {selectedBankAccount === "United Kingdom" ? (
                                <>
                                    {ukbankAccount.map((ac) => {
                                        return (
                                            <>
                                                <ul>
                                                    {ac.ukaccountholder ? (
                                                        <li>Accountholder :{ac.ukaccountholder}</li>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ac.ukiban ? <li>IBAN :{ac.ukiban}</li> : ""}
                                                    {ac.ukaccountnumber ? (
                                                        <li>Accountnumber :{ac.ukaccountnumber}</li>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ac.ukcountry ? <li>Country :{ac.ukcountry}</li> : ""}
                                                    {ac.ukcity ? <li>City :{ac.ukcity}</li> : ""}
                                                    {ac.ukcurrency ? (
                                                        <li>Currency :{ac.ukcurrency}</li>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ac.uksortcode ? (
                                                        <li>Sort Code :{ac.uksortcode}</li>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ac.ukwisesaddress ? (
                                                        <li>Wises Address:{ac.ukwisesaddress}</li>
                                                    ) : (
                                                        ""
                                                    )}
                                                </ul>
                                            </>
                                        );
                                    })}
                                </>
                            ) : (
                                ""
                            )}
                            {selectedBankAccount === "Belgium" ? (
                                <>
                                    {belgiumbank.map((ac) => {
                                        return (
                                            <>
                                                <ul>
                                                    {ac.beaccountholder ? (
                                                        <li>Accountholder :{ac.beaccountholder}</li>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ac.beiban ? <li>IBAN :{ac.beiban}</li> : ""}
                                                    {ac.becountry ? <li>Country :{ac.becountry}</li> : ""}
                                                    {ac.becity ? <li>City :{ac.becity}</li> : ""}
                                                    {ac.becurrency ? (
                                                        <li>Currency :{ac.becurrency}</li>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ac.beBic ? <li>BIC :{ac.beBic}</li> : ""}
                                                    {ac.bewisesaddress ? (
                                                        <li>Wises Address:{ac.bewisesaddress}</li>
                                                    ) : (
                                                        ""
                                                    )}
                                                </ul>
                                            </>
                                        );
                                    })}
                                </>
                            ) : (
                                ""
                            )}
                            {selectedBankAccount === "turkey" ? (
                                <>
                                    {trybank.map((ac) => {
                                        return (
                                            <>
                                                <ul>
                                                    {ac.traccountholder ? (
                                                        <li>Accountholder :{ac.traccountholder}</li>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {ac.triban ? <li>IBAN :{ac.triban}</li> : ""}
                                                    {ac.trcountry ? <li>Country :{ac.trcountry}</li> : ""}
                                                    {ac.trcity ? <li>City :{ac.trcity}</li> : ""}
                                                    {ac.trcurrency ? (
                                                        <li>Currency :{ac.trcurrency}</li>
                                                    ) : (
                                                        ""
                                                    )}
                                                </ul>
                                            </>
                                        );
                                    })}
                                </>
                            ) : (
                                ""
                            )}
                        </li>
                        <li>
                            send the total amount to the above information
                            <br />
                            {selectedBankAccount === "untid state" ? <></> : ""}
                            <b className="text-danger">
                                enclosing the code below in the transfer
                            </b>
                            <br />*
                            <b className="text-danger">
                                Convert the required amount into its equivalent in your currency
                            </b>
                            <br />
                            <div className="mt-2 mb-2">
                                {" "}
                                <Checkbox required checked={checked} onChange={Accept}>
                                    I Accept All Terms And Condtions{" "}
                                </Checkbox>
                                <br />
                            </div>
                            <Input
                                disabled
                                style={{
                                    width: "calc(50% - 35px)",
                                    marginBottom: "2%",
                                    color: "blue",
                                }}
                                value={bankCode}
                            />
                            <Tooltip title="copy Bank Code ">
                                <Button icon={<CopyOutlined />} onClick={HandleCopyBankCode} />
                            </Tooltip>
                        </li>
                        <button
                            disabled={!checked}
                            onClick={handleConfirmBankTransAction}
                            className="btn btn-primary"
                        >
                            Confirm Payment
                        </button>
                    </ul>
                </div>
            </>
        );
    };

    const [ordersChecked, setOrdersChecked] = useState([]);
    const [alertVisible, setAlertVisible] = useState(false);
    const [orderCheckemailVisible, setOrderCheckemailVisible] = useState(false);
    const handleCreateOTP = () => {
        CREATEOTP(orderCheckemail).then((res) => {
            setOtpFromEmail(res.data.result.otp);
        });
    };

    const handleOrderStatusCheck = () => {
        // GETORDERSTATUS(ordersChecked).then((res) => {
        // update this function to be like the foolowing
        GETORDERSTATUS(orderCheckemail, otp)
            .then((res) => {
                if (otp === otpFromEmail) {
                    setOrdersChecked(res.data);
                    toast.success("correct code");
                    setOrderCheckemailVisible(true);
                } else {
                }
            })
            .catch((err) => {
                toast.error("invalied code try agin");

                console.log(err);
            });
    };
    const [show, setShow] = useState(true);
    const MainForm = () => {
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <p className="text-center ">Payment </p>
                <div className="card bg-dark">
                    <div className="card-header">confirm Purchase</div>
                    <div className="card-body">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <label for="name">Name</label>
                                    <input
                                        className="form-control"
                                        id="name"
                                        type="text"
                                        placeholder=""
                                    />
                                </div>
                                <div className="col">
                                    <label>CVV</label>
                                    <input className="form-control" type="text" placeholder="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label className="mt-2">CARD NUMBER</label>
                                    <input className="form-control" type="text" placeholder="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label className="mt-2">EXPIRE DATE</label>
                                    <input className="form-control" type="date" placeholder="" />
                                </div>
                            </div>
                            <input type="submit" />
                        </div>
                    </div>
                </div>
            </div>
        </form>;
    };
    const [orderId, setOrderId] = useState("");
    const [confirmorsubmit, setConfirmorsubmit] = useState("Confirm");
    const handleConfirmOrder = (_id) => {
        setOrderId(_id);

        setConfirmorsubmit("Submit");
    };
    const [file, setFile] = useState();

    const onChange = (e) => {
        setFile({ file: e.target.files[0] });
    };
    const onFormSubmit = async (e, _id) => {
        console.log(file);
        e.preventDefault();
        const formData = new FormData();
        //  console.log(file)
        formData.append("image", file);

        //  console.log(orderId,formData)

        return await axios
            .post(
                `${process.env.REACT_APP_API}/uploadImage`,
                { formData },
                {
                    headers: {
                        _id: orderId,
                        "content-type": "multipart/form-data",
                    },
                }
            )
            .then((response) => {
            })
            .catch((error) => {
                // console.log(error)
            });
    };

    const [count, setCount] = useState(30);
    const [confirmResendBtn, setConfirmResendBtn] = useState("disable");
    const [resendVisable, setResendVisable] = useState(true);
    const startCounter = () => {
        const interval = setInterval(() => {
            setCount((prevCount) => {
                if (prevCount === 1) {
                    setConfirmResendBtn("enable");
                    clearInterval(interval);
                }
                return prevCount - 1;
            });
        }, 1000);
        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(interval);
    };
    return (
        <>
            <div>
                <div className="container-fluid border mainform p-md-3 mt-5">
                    <h1 className="text-center text-dark mt-2">Buy Now</h1>
                    {/* {JSON.stringify(paymentIntent)} */}

                    {/* {currency} */}
                    {/* <Select
                value={currency}
                className="mb-2 mt-2 w-75 text-center"
                onChange={setCurrency}
                size="large"
              >
                <Option
                  disabled={paymenttype === "card"}
                  value={euroCurrency}
                  className="text-success "
                >
                  <h4> €</h4>
                </Option>
                <Option value={dollarCurrency} className="text-success">
                  <h4> $</h4>
                </Option>
              </Select> */}

                    <div className="row">
                        <div className=" row d-flex justify-content-center mb-5 mt-5">
                            <div className=" col-sm-12 col-md-5">
                                <input
                                    onChange={handleEmailChange}
                                    value={email}
                                    id="myInput"
                                    className="input  w-100  h-50 h5"
                                    placeholder="Enter Your Email"
                                />

                                <input
                                    type="number"
                                    id="myInput"
                                    onChange={hadnleresellerId}
                                    className="input  w-100  h5  h-50 "
                                    placeholder="Enter Reseller Id"
                                    value={resllerid}
                                />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="stepOne">
                                <div>
                                    <div className="container-fluid">
                                        {message}
                                        <div
                                            class="modal fade modal-dialog modal-lg"
                                            id="checkorder"
                                            tabindex="-1"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true"
                                        >
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">
                                                            check or confirm your order Here!{" "}
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            class="btn-close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                            onClick={() => {
                                                                window.location.reload();
                                                            }}
                                                        ></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        {alertVisible ? (
                                                            <>
                                                                <Alert
                                                                    variant="danger"
                                                                    onClose={() => setAlertVisible(false)}
                                                                    dismissible
                                                                >
                                                                    <Alert.Heading>
                                                                        Please Enter Valid Email
                                                                    </Alert.Heading>
                                                                </Alert>
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {toggleEmailField && (
                                                            <div>
                                                                <label className="text-dark fw-bold">
                                                                    Enter Email
                                                                </label>
                                                                <input
                                                                    className="form-control w-100"
                                                                    type="text"
                                                                    value={orderCheckemail}
                                                                    onChange={(e) => {
                                                                        handleOrderCheckemail(e);
                                                                    }}
                                                                />
                                                                <button
                                                                    className={
                                                                        verfiyEmailBtn === "enable"
                                                                            ? "btn btn-warning mt-2"
                                                                            : "verfiyEmailBtn btn mt-2"
                                                                    }
                                                                    onClick={() => {
                                                                        if (verfiyEmailBtn === "enable") {
                                                                            handleCreateOTP();
                                                                            handleToglleEmailField();
                                                                            handleToglleOtpField();
                                                                            startCounter();
                                                                        }
                                                                    }}
                                                                >
                                                                    Verify your email
                                                                </button>
                                                            </div>
                                                        )}
                                                        {/* <button className="btn btn-success mt-2">Confirm My Order</button> */}
                                                        {toggleOtpField && (
                                                            <div>
                                                                <lable className="text-dark fw-bold">
                                                                    Verify Code
                                                                </lable>
                                                                <input
                                                                    className="form-control w-100"
                                                                    type="text"
                                                                    onChange={(e) => {
                                                                        handleOtpChange(e);
                                                                    }}
                                                                />
                                                                <button
                                                                    className={
                                                                        confiremBtn === "enable"
                                                                            ? "btn btn-warning mt-2"
                                                                            : "verfiyEmailBtn btn mt-2"
                                                                    }
                                                                    onClick={() => {
                                                                        if (confiremBtn === "enable") {
                                                                            handleOrderStatusCheck();
                                                                            handleToglleOtpField();
                                                                        }
                                                                    }}
                                                                >
                                                                    confirm
                                                                </button>
                                                                {verfiyEmailBtn === "enable" &&
                                                                    resendVisable === true ? (
                                                                    <button
                                                                        style={
                                                                            count == 0
                                                                                ? { color: "black" }
                                                                                : { color: "gray" }
                                                                        }
                                                                        onClick={() => {
                                                                            if (confirmResendBtn == "enable") {
                                                                                setCount(30 * countAx);
                                                                                handleCreateOTP();
                                                                                countAx++;
                                                                                setConfirmResendBtn("disable");
                                                                                startCounter();
                                                                            }
                                                                        }}
                                                                    >
                                                                        resend ({count})
                                                                    </button>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        class="modal-footer"
                                                        style={{ overflowY: "scroll", maxHeight: "30vh" }}
                                                    >
                                                        {orderCheckemailVisible === true ? (
                                                            <div class="table-responsive container ">
                                                                <table
                                                                    style={{ overflowY: "scroll" }}
                                                                    class="table  table-hover mt-5 table-responsive responsive"
                                                                    striped
                                                                    bordered
                                                                    hover
                                                                >
                                                                    {/* {JSON.stringify(serviceProvider)} */}
                                                                    <thead>
                                                                        <tr className="bg-dark text-white">
                                                                            <th>#</th>

                                                                            <th>Date</th>

                                                                            <th>Status</th>

                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="of">
                                                                        {ordersChecked.map((oc, index) => {
                                                                            return (
                                                                                <>
                                                                                    {oc.paymentIntent.map((oca) => {
                                                                                        return (
                                                                                            <>
                                                                                                {oca.paymenttype ===
                                                                                                    "banktransfer" ? (
                                                                                                    <tr>
                                                                                                        <td>{index + 1}</td>
                                                                                                        <td>
                                                                                                            {" "}
                                                                                                            <b className="text-dark">
                                                                                                                {moment(
                                                                                                                    oc.createdAt
                                                                                                                ).format(
                                                                                                                    "D/M/Y h:mm:ss A"
                                                                                                                )}
                                                                                                            </b>
                                                                                                        </td>

                                                                                                        {oc.status ===
                                                                                                            "Completed" && (
                                                                                                                <td
                                                                                                                    className={
                                                                                                                        "text-success"
                                                                                                                    }
                                                                                                                >
                                                                                                                    {oc.status}
                                                                                                                </td>
                                                                                                            )}
                                                                                                        {oc.status ===
                                                                                                            "Not Completed" && (
                                                                                                                <td
                                                                                                                    className={
                                                                                                                        "text-danger"
                                                                                                                    }
                                                                                                                >
                                                                                                                    {oc.status}
                                                                                                                </td>
                                                                                                            )}
                                                                                                        {oc.status ===
                                                                                                            "On Process" && (
                                                                                                                <td
                                                                                                                    className={
                                                                                                                        "text-warning"
                                                                                                                    }
                                                                                                                >
                                                                                                                    {oc.status}
                                                                                                                </td>
                                                                                                            )}
                                                                                                        {oc.status ===
                                                                                                            "Rejected" && (
                                                                                                                <td
                                                                                                                    className={
                                                                                                                        "text-danger"
                                                                                                                    }
                                                                                                                >
                                                                                                                    {oc.status}
                                                                                                                </td>
                                                                                                            )}
                                                                                                        <td>
                                                                                                            {oc.status ===
                                                                                                                "Not Completed" &&
                                                                                                                confirmorsubmit ===
                                                                                                                "Confirm" ? (
                                                                                                                <button
                                                                                                                    className={
                                                                                                                        "btn btn-success"
                                                                                                                    }
                                                                                                                    onClick={() =>
                                                                                                                        handleConfirmOrder(
                                                                                                                            oc._id
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    {confirmorsubmit}
                                                                                                                </button>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {" "}
                                                                                                                    <div>
                                                                                                                        {oc.status ===
                                                                                                                            "Not Completed" && (
                                                                                                                                <>
                                                                                                                                    <FilesUploadComponent
                                                                                                                                        imgid={oc._id}
                                                                                                                                        _id={oc._id}
                                                                                                                                        handleOrderStatusCheck={
                                                                                                                                            handleOrderStatusCheck
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            )}
                                                                                                            {oc.status ===
                                                                                                                "Rejected" &&
                                                                                                                confirmorsubmit ===
                                                                                                                "Confirm" ? (
                                                                                                                <>
                                                                                                                    <p className="text-danger">
                                                                                                                        reject
                                                                                                                        reason:{" "}
                                                                                                                        {oc.rejectreason}
                                                                                                                    </p>
                                                                                                                    <br />
                                                                                                                    <button
                                                                                                                        className={
                                                                                                                            "btn btn-success"
                                                                                                                        }
                                                                                                                        onClick={() =>
                                                                                                                            handleConfirmOrder(
                                                                                                                                oc._id
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {confirmorsubmit}
                                                                                                                    </button>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {" "}
                                                                                                                    <div>
                                                                                                                        {oc.status ===
                                                                                                                            "Rejected" && (
                                                                                                                                <>
                                                                                                                                    {" "}
                                                                                                                                    <FilesUploadComponent
                                                                                                                                        imgid={oc._id}
                                                                                                                                        _id={oc._id}
                                                                                                                                        handleOrderStatusCheck={
                                                                                                                                            handleOrderStatusCheck
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            )}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        ) : orderCheckemailVisible === false &&
                                                            toggleEmailField === false &&
                                                            toggleOtpField === false ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    color: "black",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <h3>invalied code try agin</h3>
                                                                <button
                                                                    style={{ color: "black" }}
                                                                    onClick={() => {
                                                                        setToggleEmailField(true);
                                                                        setResendVisable(false);
                                                                    }}
                                                                >
                                                                    try agin
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {/* {orderId} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-center ">
                                            <div className="col-sm-6 col-md-5  mt-1 rounded  border cardsbg">
                                                <h6 className="text-white text-center mt-4">
                                                    <b className="text-warning">1 Year</b> SubScription{" "}
                                                </h6>
                                                <div className=" d-flex justify-content-center">
                                                    <Radio.Group
                                                        value={subSucribtion1}
                                                        className="mb-2 mt-2 ms-3 "
                                                    >
                                                        <Radio className="text-dark h3 d-none" value={25}>
                                                            1 Year
                                                        </Radio>
                                                    </Radio.Group>
                                                </div>

                                                <div className=" d-flex justify-content-center">
                                                    {" "}
                                                    <p className="h6 text-white"> Number Of Codes </p>
                                                </div>
                                                <div className=" d-flex justify-content-center">
                                                    <input
                                                        className="form-control w-50"
                                                        min={1}
                                                        type="number"
                                                        value={
                                                            cardsNumber1
                                                                ? Math.round(cardsNumber1)
                                                                : cardsNumber1
                                                        }
                                                        onChange={onChangeCards}
                                                    />
                                                </div>
                                                {cardsNumber1 < 1 && cardsNumber2 < 1 ? (
                                                    <h3 className="text-dark text-center ">
                                                        please Enter Valid Number
                                                    </h3>
                                                ) : cardsNumber1 > 5000 ? (
                                                    <div className="text-danger  text-center">
                                                        maximum 5000
                                                    </div>
                                                ) : (
                                                    <h3 className="text-warning text-center mt-3 ">
                                                        <h6 className=" text-white">Amount</h6>
                                                        {currency === "$"
                                                            ? `$${amount1indollar}`
                                                            : currency === "€"
                                                                ? `€${amount1}`
                                                                : `£${amount1inbound}`}
                                                    </h3>
                                                )}
                                            </div>

                                            <div className="col-sm-6 col-md-5 mt-1 cardsbg border  pb-5 pl rounded ">
                                                <h6 className="text-white mt-4 text-center ">
                                                    <b className="text-warning "> 6 Months</b>{" "}
                                                    SubScription{" "}
                                                </h6>
                                                <div className=" d-flex justify-content-center ">
                                                    <Radio.Group
                                                        value={subSucribtion2}
                                                        className="mb-2 mt-2 ms-3 "
                                                    >
                                                        <Radio className=" h3 d-none text-white" value={15}>
                                                            6 Month
                                                        </Radio>
                                                    </Radio.Group>
                                                </div>
                                                <div className=" d-flex justify-content-center ">
                                                    <p className="h6  text-white tex-center">
                                                        {" "}
                                                        Number Of Codes{" "}
                                                    </p>
                                                </div>
                                                <div className=" d-flex justify-content-center ">
                                                    <input
                                                        className="form-control w-50"
                                                        min={0}
                                                        type="number"
                                                        value={
                                                            cardsNumber2
                                                                ? Math.round(cardsNumber2)
                                                                : cardsNumber2
                                                        }
                                                        onChange={onChangeCards2}
                                                    />
                                                </div>
                                                {cardsNumber1 < 1 && cardsNumber2 < 1 ? (
                                                    <h3 className="text-dark text-center ">
                                                        please Enter Valid Number
                                                    </h3>
                                                ) : cardsNumber1 > 5000 ? (
                                                    <div className="text-danger text-center">
                                                        maximum 5000
                                                    </div>
                                                ) : (
                                                    <h3 className="text-warning text-center mt-3 ">
                                                        <h6 className="text-white">Amount</h6>
                                                        {/* {currency === "$" ? (<>{currency==="$"?"$":"€"}{amount2indollar}</>) :  (<>{currency==="$"?"$":"€"}{amount2}</>) } */}
                                                        {currency === "$"
                                                            ? `$${amount2indollar}`
                                                            : currency === "€"
                                                                ? `€${amount2}`
                                                                : `£${amount2inbound}`}
                                                    </h3>
                                                )}
                                            </div>
                                            <div className="mb-2 mt-5">
                                                {cardsNumber1 < 1 && cardsNumber2 < 1 ? (
                                                    <h3 className="text-dark text-center ">
                                                        please Enter Valid Number
                                                    </h3>
                                                ) : cardsNumber1 > 5000 ? (
                                                    <div className="text-danger text-center">
                                                        maximum 5000
                                                    </div>
                                                ) : (
                                                    <h1 className="text-primary text-center ">
                                                        <h6 className="">Total Amount</h6>
                                                        {currency}{" "}
                                                        {currency === "$"
                                                            ? amountindollar
                                                            : currency === "€"
                                                                ? amount
                                                                : amountinbound}
                                                    </h1>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={acceptTerms ? "modal show" : "modal fade"}
                                    id="paypalTerms"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false"
                                    tabindex="-1"
                                    aria-labelledby="staticBackdropLabel"
                                    aria-hidden="true"
                                >
                                    <div class="modal-dialog ">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="role">
                                                    Control paypal Account
                                                </h5>
                                            </div>
                                            <div class="modal-body">
                                                By Click Accept You Are Accepting That ther's{" "}
                                                <b className="text-danger">No Refund</b>
                                                <div>
                                                    <b className="text-danger">*</b> Please Don't Close{" "}
                                                    <b className="text-primary">Paypal</b>
                                                    checkout page until purchase{" "}
                                                    <b className="text-success">Completed</b>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h4>
                                                            1-Cancellations must be made in writing and sent
                                                            to
                                                            <a
                                                                href="mailto:info@thkeer.net?body=Hello%20Team%2C%0A%0A%0A%0A%20Sincerely%2C%0A[Your%20Name]"
                                                                target="_blank"
                                                            >
                                                                info@thkeer.net
                                                            </a>
                                                        </h4>
                                                        <h4>
                                                            2-Cancellations made more than 14 days before the
                                                            start of the service will be refunded in full.
                                                        </h4>
                                                        <h4>
                                                            3-Cancellations made between 7 and 14 days before
                                                            the start of the service will be refunded 50% of
                                                            the total cost.
                                                        </h4>
                                                        <h4>
                                                            4-Cancellations made less than 7 days before the
                                                            start of the service will not be eligible for a
                                                            refund.
                                                        </h4>
                                                        <h4>
                                                            5-In the event that thkeer.net cancels a service,
                                                            customers will be refunded in full.
                                                        </h4>
                                                        <h4>
                                                            6-Any changes or modifications to the service must
                                                            be made in writing and sent to{" "}
                                                            <a
                                                                href="mailto:info@thkeer.net?body=Hello%20Team%2C%0A%0A%0A%0A%20Sincerely%2C%0A[Your%20Name]"
                                                                target="_blank"
                                                            >
                                                                info@thkeer.net
                                                            </a>{" "}
                                                            Additional fees may apply for changes made less
                                                            than 15 days before the start of the service.
                                                        </h4>
                                                        <h4>
                                                            7-By agreeing to the terms and conditions of
                                                            thkeer.net, customers are agreeing to the
                                                            cancellation policy outlined above.
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button
                                                    type="button"
                                                    class="btn btn-secondary"
                                                    data-bs-dismiss="modal"
                                                    onClick={handleRejectTerms}
                                                >
                                                    Denide
                                                </button>
                                                <button
                                                    onClick={hadnleAccepting}
                                                    type="button"
                                                    class="btn btn-primary"
                                                    data-bs-dismiss="modal"
                                                >
                                                    Accept
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* {JSON.stringify(paypalvisible)}
                {JSON.stringify(paymentMethod)} */}

                                <h3 className=" text-center  text-dark">Payment Method</h3>
                                <div className="d-flex justify-content-center">
                                    <br />
                                    <select
                                        onChange={handlePayment}
                                        className="form-control w-25"
                                        value={paymentMethod}
                                    >
                                        <option value="">Select Payment Method</option>
                                        {cardvisible === "visible" && (
                                            <option value="credit_card">
                                                <FaCreditCard className="text primary visa" /> Credit
                                                Card
                                            </option>
                                        )}
                                        {paypalvisible === "visible" && (
                                            <option
                                                value="paypal"
                                                data-bs-target="#paypalTerms"
                                                data-bs-toggle="modal"
                                            >
                                                <FaCcPaypal className="text primary paypal" /> Paypal
                                            </option>
                                        )}
                                        {bankvisible === "visible" && (
                                            <option value="bank">
                                                <AiOutlineBank className="text primary bank" /> Bank
                                                Transfer
                                            </option>
                                        )}
                                    </select>
                                </div>
                                <div className="text-center mt-2">
                                    <u
                                        className="text-primary text-center checkorder h3"
                                        data-bs-toggle="modal"
                                        data-bs-target="#checkorder"
                                    >
                                        Manage Bank Transfer
                                    </u>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="  ">
                        {credit && <div className={credit ? " d-inline" : "d-none"}>
                            <Payment
                                amount={amount}
                                orederDetails={orederDetails}
                                threeHeaders={threeHeaders}
                                threeHeaders2={threeHeaders2}
                                handlePaymentIntent={handlePaymentIntent}
                            />
                            {/* {JSON.stringify(orederDetails)} */}
                        </div>}
                        <div className={paypal ? "d-inline mt-5  " : "d-none "}>
                            <PayPalCheckOut paymentIntent={paymentIntent} />
                        </div>
                        <div className={bankTransAction ? "d-inline mt-5  " : "d-none "}>
                            <BankTransAction paymentIntent={paymentIntent} />
                        </div>
                    </div>
                </div>

                {/* <button type='submit'>submit</button>  */}
            </div>
        </>
    );
};

const Home = () => {
    return (
        <>
            <>
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="header">
                            <div className="row">
                                <div className="col-md-7 col-sm-12  ">
                                    <div className="headersection">
                                        <h5 className="text-white headerpargraph">
                                            The easiest way to activate our application.
                                        </h5>
                                        {/* <p className="equalPargrahp">Equals is the only spreadsheet with built-in connections to any<br/> database, versioning, and collaboration.</p> */}
                                        <div className="d-flex justify-content-center">
                                            <a href="#cards">
                                                <button className="btn buynow mb-5 ">Buy Now</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5  col-sm-10">
                                    <img src={headerimage} className="img-fluid  pt-5" />
                                </div>
                            </div>
                        </div>

                        <img
                            src={himg}
                            style={{ padding: "0px", marginRight: "50px", height: "250px" }}
                        />
                    </div>

                    <div className="row">
                        <div className="col ">
                            <h1 className=" pae text-center">
                                Be part of our team and become a reseller
                            </h1>

                            <div className="d-flex justify-content-center">
                                {/* <p className="uma text-center">Use marketing automation to identify hot leads and email your<br/> sales team telling them to follow up. Nurture and contact leads <br/> at the opportune time to maximize.</p> */}
                            </div>
                            <div className="d-flex justify-content-center">
                                <Link to="/login">
                                    <button id="cards" className="btn  startNow">
                                        Get Started
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Next />
            </>
        </>
    );
};

export default Home;
