import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import "../../css/Table.css";
import { GETSERVICEPROVIDER, getUsersCount } from "../../../function/auth";
import { useSelector } from "react-redux";
import { Moment } from "moment";
import LocalSearch from "../../search/localsearch";
import { GETSPTOTALBALANCE } from "../../../function/serviceprovider";
import Loading from "../../loading/loading";
import moment from "moment";
import transactionimg from "../../images/transactionimg.png";
import { CloudDownloadOutlined, EyeOutlined } from "@ant-design/icons";

const BlockedServiceProvider = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { user } = useSelector((state) => ({ ...state }));
  const [keyword, setKeyword] = useState("");
  const [totalbalance, setTotalbalance] = useState([]);
  const [count, setCount] = useState();

  const [serviceProvider, setServiceProvider] = useState([]);
  useEffect(() => {
    getServicesProviders();
    getUsersCount().then((res) => {
      setCount(res.data);
    });

    GETSPTOTALBALANCE(user.token).then((res) => {
      setTotalbalance(res.data);
    });
  }, [page]);
  const getServicesProviders = () => {
    setLoading(true);
    GETSERVICEPROVIDER(user.token, "createdAt", "asc", page).then((res) => {
      setServiceProvider(res.data);
      setLoading(false);
    });
  };

  const search = (keyword) => (c) => c.name.toLowerCase().includes(keyword);

  return (
    <>
      <div className="row mt-5 p-2">
        {/* <OldTable/> */}
        <div className="container">
          <div clasName="row">
            <div className="col-12">
              <div className="card servicceProvidersCard table-responsive">
                <div className="card-header">
                  <div className="row">
                    <div className="col-5">
                      <div>
                        <img width={"70px"} src={transactionimg} />
                        <b className="h4 ms-2"> Blocked Service Providers</b>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="mt-2">
                        <LocalSearch
                          placeholder="search By Name"
                          keyword={keyword}
                          setKeyword={setKeyword}
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="float-end me-auto">
                        {" "}
                        <button className=" btn btn-light-info h5">
                          <CloudDownloadOutlined style={{ fontSize: "20px" }} />{" "}
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="serviceTable table  ">
                <thead className="">
                  <tr>
                    <th>Full Name </th>
                    <th>public id</th>
                    <th>private id</th>

                    <th className="ms-1">
                      Balance
                      {/* {totalbalance.map((rd)=>{
        return(<>
       total {rd.TotalAmount.toFixed(2)}
        </>)
         })} */}
                    </th>
                    <th>Email</th>
                    <th>phone</th>
                    <th>Country</th>
                    <th>Account Status</th>

                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceProvider
                    ? serviceProvider.filter(search(keyword)).map((s) => {
                        return (
                          <>
                            {s.accountstatus === "blocked" ? (
                              <tr className="h-100">
                                <td>
                                  {" "}
                                  <Link
                                    key={s.publicid}
                                    to={`/user/${s.publicid}`}
                                  >
                                    <EyeOutlined className="eyeOutline me-2" />
                                    {s.name}
                                  </Link>{" "}
                                </td>

                                <td>{s.publicid}</td>
                                <td>{s.uniqueid}</td>

                                <td>€{s.balance.toFixed(2)}</td>
                                <td>{s.email}</td>
                                <td>{s.phone}</td>

                                {s.address.map((d) => (
                                  <td>{d.contry}</td>
                                ))}
                                <td
                                  className={
                                    s.accountstatus === "blocked"
                                      ? "text-danger"
                                      : "text-success"
                                  }
                                >
                                  {s.accountstatus}
                                </td>
                                <td>
                                  {moment(s.createdAt).format(
                                    "D/M/Y h:mm:ss A"
                                  )}
                                </td>
                                <td style={{ width: "150px" }}>
                                  <div className="row">
                                    <div className="col">
                                      <Link
                                        key={s.publicid}
                                        to={`/admin/serviceproviderorders/${s.publicid}`}
                                      >
                                        <button className="btn btn-light  ">
                                          Orders
                                        </button>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    : "no data for now"}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlockedServiceProvider;
