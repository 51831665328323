import axios from "axios";

export const CREATECREDITTRANSACTION=async(authtoken,destination,amount,fees)=>{
      return await axios.post(`${process.env.REACT_APP_API}/credittransaction`,{destination,amount,fees},{
       headers: { 
            authtoken 
        } 
    })

}

export const CREATECREDOLLARDITTRANSACTION = async (
  authtoken,
  destination,
  amount,
  fees
) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/dollarcredittransaction`,
    { destination, amount, fees },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const CREATEBANKTRANSACTION=async(authtoken,destination,amount,fees)=>{
      return await axios.post(`${process.env.REACT_APP_API}/banktransaction`,{destination,amount,fees},{
       headers: { 
            authtoken 
        } 
    })

}

export const CREATEDOLLARBANKTRANSACTION = async (
  authtoken,
  destination,
  amount,
  fees
) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/dollarbanktransaction`,
    { destination, amount, fees },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const createPaypalTransAction=async(authtoken,destination,amount,fees)=>{
      return await axios.post(`${process.env.REACT_APP_API}/paypaltransaction`,{destination,amount,fees},{
       headers: { 
            authtoken 
        } 
    })

}
export const createDollarPaypalTransAction = async (
  authtoken,
  destination,
  amount,
  fees
) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/dollarpaypaltransaction`,
    { destination, amount, fees },
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const createCryptoTransAction=async(authtoken,destination,amount,fees)=>{
      return await axios.post(`${process.env.REACT_APP_API}/cryptotransaction`,{destination,amount,fees},{
       headers: { 
            authtoken 
        } 
    })

}
export const createCryptoForDollarTransAction=async(authtoken,destination,amount,fees)=>{
      return await axios.post(`${process.env.REACT_APP_API}/fdcryptotransaction`,{destination,amount,fees},{
       headers: { 
            authtoken 
        } 
    })

}
export const GETADMINTRANSACTION=async(authtoken)=>{
    return await axios.get(`${process.env.REACT_APP_API}/admintransaction`,{
        headers:{
            authtoken
        }
    })
}