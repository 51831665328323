import React, { useState } from "react";
import { Radio } from "antd";
import { USERCHECK, CHECKADMIN } from "../../../function/auth";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Axios from "axios";
import { CREATEEXTERNALTRANSFER } from "../../../function/external";
import { toast } from "react-toastify";

const External = () => {
  const [info, setInfo] = useState([]);

  const [cfrom, setCfrom] = useState("eur");
  const [cto, setCto] = useState("usd");
  const [amount, setAmount] = useState(null);
  const [from, setFrom] = useState("creditbalance");
  const [to, setTo] = useState("paypalbalance");
  const { user } = useSelector((state) => ({ ...state }));
  const [dollarcreditbalance, setDollarcreditbalance] = useState();
  const [dollarbankbalance, setDollarbankbalance] = useState();
  const [dollarpaypalbalance, setDollarpaypalbalance] = useState();
  const [cryptocurrencybalance, setCryptocurrencybalance] = useState();
  const [creditbalance, setCreditbalance] = useState();
  const [paypalbalance, setPaypalbalance] = useState();
  const [bankbalance, setBankbalance] = useState();
  const [output, setOutput] = useState(0);
  const [fees, setFees] = useState();
  const [finalAmount, setFinalAmount] = useState();
  const handleFees = (e) => {
    setFees(e.target.value);
    if (e.target.value > Number(amount)) {
      toast.error("amount shouldn't be bigger than fees");
      setAmount(0);
      setFees(0);
    } else {
      setFinalAmount(output - e.target.value);
    }
  };
  console.log('cryptocurrencybalance', cryptocurrencybalance);
  const handlefrom = (e) => {
    setFinalAmount(output - e.target.value);
    setFrom(e.target.value);
    setAmount(0);
    setFees(0);
    if (e.target.value === to) {
      setTo("disabled");
    } else if (to === "disabled") {
      setTo("creditbalance");
    }
  };
  const handleTo = (e) => {
    setAmount(0);
    setFees(0);
    const value = e.target.value;
    setTo(value);

    // Disable the "from" options if the "from" and "to" values are the same
    if (value === from) {
      const fromRadios = document.querySelectorAll('input[name="from"]');
      fromRadios.forEach((radio, index) => {
        radio.disabled = true;
        if (index < fromRadios.length - 1 && radio.value !== to) {
          setFrom(radio.value);
          radio.checked = true;
          radio.disabled = false;
        }
      });
    } else {
      const fromRadios = document.querySelectorAll('input[name="from"]');
      fromRadios.forEach((radio) => {
        radio.disabled = false;
      });
    }
  };

  useEffect(() => {
    //  we replace CHECKUSER(user.email) to CHECKADMIN(user.token)
    CHECKADMIN(user.token).then((res) => {
      // setBalance(res.data.dollarpaypalbalance.toFixed(2));
      // setAdminbalance(res.data.admindollardynmicbalance.toFixed(2));
      setDollarcreditbalance(res.data.dollarcreditbalance);
      setDollarbankbalance(res.data.dollarbankbalance);
      setDollarpaypalbalance(res.data.dollarpaypalbalance);
      setCryptocurrencybalance(res.data.cryptocurrencybalance);

      //this down values for euro
      setCreditbalance(res.data.creditbalance);
      setPaypalbalance(res.data.paypalbalance);
      setBankbalance(res.data.bankbalance);
      // console.log(res.data.dollarcreditbalance);
    });
  }, [from]);
  console.log('from', from);
  console.log('to', to);

  useEffect(() => {
    var rate = info[cto];
    console.log('rate', rate);
    console.log('info', info);
    console.log('cto', cto);

    if (
      to === "creditbalance" ||
      to === "bankbalance" ||
      to === "paypalbalance"
    ) {
      setCfrom("eur");
      setCto("EUR");
    } else {
      if (
        to === "cryptocurrencybalance" ||
        to === "dollarpaypalbalance" ||
        to === "dollarbankbalance" ||
        to === "dollarcreditbalance"
      ) {
        setCto("USD");
      }
    }

    if (
      (from === "creditbalance" ||
        from === "paypalbalance" ||
        from === "bankbalance") &&
      (to === "cryptocurrencybalance" ||
        to === "dollarpaypalbalance" ||
        to === "dollarbankbalance" ||
        to === "dollarcreditbalance")
    ) {
      setCfrom("eur");
      console.log(amount, rate);
      setOutput(amount * rate);

    } else {
      if (
        (to === "creditbalance" ||
          to === "paypalbalance" ||
          to === "bankbalance") &&
        (from === "cryptocurrencybalance" ||
          from === "dollarpaypalbalance" ||
          from === "dollarbankbalance" ||
          from === "dollarcreditbalance")
      ) {
        setCfrom("usd");

        setOutput(amount * rate);
      } else {
        setOutput(amount);
      }
    }
  }, [from, to, amount]);

  useEffect(() => {
    Axios.get(
      `https://v6.exchangerate-api.com/v6/${process.env.REACT_APP_EXCHANGERATEKey}/latest/${cfrom}`
    ).then((res) => {
      setInfo(res.data.conversion_rates);
    });
  }, [cfrom, amount]);
  const handleAmount = (e) => {
    setAmount(e.target.value);
    setFees();
    setFinalAmount(null);
    if (fees > e.target.vaule) {
      toast.error("amount shouldn't be bigger than fees");
      setAmount(0);
      setFees(0);
    }
  };

  const handleTransfer = () => {
    CREATEEXTERNALTRANSFER(
      user.token,
      from,
      amount,
      fees,
      to,
      output,
      finalAmount || output
    ).then((res) => {
      // console.log(res)
      if (res.data.ok === true) {
        toast.success("Trasnfer Successeed");
      } else {
        toast.error("somthing went wrong");
      }
    });
  };
  return (
    <div className="container-fluid">
      <div className="container mt-5">
        <div className="row mt-5 d-flex justify-content-center ">
          <div className="col-md-10  col-sm-12 mt-5 ">
            <div className="card shadow">
              <div className="card-header">
                <h1 className="text-center">External Exchanges</h1>
              </div>
              <div className="card-body ">
                <h1 className="text-center text-success"> From </h1>
                <div className="d-flex justify-content-center">
                  <Radio.Group onChange={handlefrom} value={from}>
                    <Radio value={"creditbalance"} name="from">
                      € Credit
                    </Radio>
                    <Radio value={"paypalbalance"} name="from">
                      € Paypal
                    </Radio>
                    <Radio value={"bankbalance"} name="from">
                      € Bank Transfer
                    </Radio>
                    <Radio value={"cryptocurrencybalance"} name="from">
                      $ Crypto
                    </Radio>
                    <Radio value={"dollarpaypalbalance"} name="from">
                      $ Paypal
                    </Radio>
                    <Radio value={"dollarbankbalance"} name="from">
                      $ Bank Transfer
                    </Radio>
                    <Radio value={"dollarcreditbalance"} name="from">
                      {" "}
                      $ Credit
                    </Radio>
                  </Radio.Group>
                </div>
                <h1 className="text-center text-danger mt-5"> To </h1>
                <div className="d-flex justify-content-center">
                  <Radio.Group onChange={handleTo} value={to}>
                    <Radio
                      value={"creditbalance"}
                      disabled={from === "creditbalance"}
                    >
                      € Credit
                    </Radio>
                    <Radio
                      value={"paypalbalance"}
                      disabled={from === "paypalbalance"}
                    >
                      € Paypal
                    </Radio>
                    <Radio
                      value={"bankbalance"}
                      disabled={from === "Bank Transfer"}
                    >
                      € Bank Transfer
                    </Radio>
                    <Radio
                      value={"cryptocurrencybalance"}
                      disabled={from === "cryptocurrencybalance"}
                    >
                      $ Crypto
                    </Radio>
                    <Radio
                      value={"dollarpaypalbalance"}
                      disabled={from === "dollarpaypalbalance"}
                    >
                      $ Paypal
                    </Radio>
                    <Radio
                      value={"dollarbankbalance"}
                      disabled={from === "dollarbankbalance"}
                    >
                      $ Bank Transfer
                    </Radio>
                    <Radio
                      value={"dollarcreditbalance"}
                      disabled={from === "dollarcreditbalance"}
                    >
                      $ Credit
                    </Radio>
                  </Radio.Group>
                </div>
                <div className="row mt-5 ">
                  <h4 className="text-center">
                    {" "}
                    Sender{" "}
                    {from === "dollarcreditbalance" ? (
                      <div className="text-success">
                        dollar credit Balance: $
                        {Number(dollarcreditbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {from === "dollarbankbalance" ? (
                      <div className="text-success">
                        dollar bank Balance: $
                        {Number(dollarbankbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {from === "dollarpaypalbalance" ? (
                      <div className="text-success">
                        dollar Paypal Balance: $
                        {Number(dollarpaypalbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {from === "cryptocurrencybalance" ? (
                      <div className="text-success">
                        crypto Balance: $
                        {Number(cryptocurrencybalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {from === "creditbalance" ? (
                      <div className="text-success">
                        Credit Balance: €{Number(creditbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {from === "paypalbalance" ? (
                      <div className="text-success">
                        Paypal Balance: €{Number(paypalbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {from === "bankbalance" ? (
                      <div className="text-success">
                        Bank Balance: €{Number(bankbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                  </h4>
                  <h4 className="text-center">
                    {" "}
                    Reciver{" "}
                    {to === "dollarcreditbalance" ? (
                      <div className="text-success">
                        dollar credit Balance: $
                        {Number(dollarcreditbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {to === "dollarbankbalance" ? (
                      <div className="text-success">
                        dollar bank Balance: $
                        {Number(dollarbankbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {to === "dollarpaypalbalance" ? (
                      <div className="text-success">
                        dollar Paypal Balance: $
                        {Number(dollarpaypalbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {to === "cryptocurrencybalance" ? (
                      <div className="text-success">
                        crypto Balance: $
                        {Number(cryptocurrencybalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {to === "creditbalance" ? (
                      <div className="text-success">
                        Credit Balance: €{Number(creditbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {to === "paypalbalance" ? (
                      <div className="text-success">
                        Paypal Balance: €{Number(paypalbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {to === "bankbalance" ? (
                      <div className="text-success">
                        Bank Balance: €{Number(bankbalance).toFixed(2)}
                      </div>
                    ) : (
                      ""
                    )}
                    {to === "disabled" ? (
                      <div className="text-danger">
                        Please Select different Reciver Account
                      </div>
                    ) : (
                      ""
                    )}
                    {!to ? (
                      <div className="text-danger">
                        Please Select different Reciver Account
                      </div>
                    ) : (
                      ""
                    )}
                  </h4>

                  <div className="d-flex justify-content-center">
                    <input
                      type="number"
                      className="form-control w-25"
                      value={amount}
                      placeholder="Action Amount"
                      onChange={handleAmount}
                      disabled={!from || !to}
                    />
                  </div>
                </div>
                <div
                  className="mt-5 modal fade modal-lg"
                  id="confirmation"
                  tabindex="-1"
                  aria-labelledby="role"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="role">
                          confimation
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        are you sure you want to send{" "}
                        <b className="text-danger">
                          {cto === "eur" ? "€" : "$"}
                        </b>
                        <b> {output}</b> from
                        <b className="text-warning"> {from}</b> To{" "}
                        <b className="text-success">{to}</b>?
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={handleTransfer}
                          data-bs-dismiss="modal"
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  <input
                    className="form-control w-25 mt-3"
                    type="number"
                    vlaue={fees}
                    onChange={handleFees}
                    placeholder="Fees"
                  />
                </div>

                <div className="row">
                  {amount > 0 && (
                    <div className="col">
                      <h4 className="text-center mt-4">
                        Converted Amount:{" "}
                        <b className="text-danger">
                          {cto === "USD" ? "$" : "€"}
                          {output}
                        </b>
                      </h4>
                      <h4 className="text-center mt-4">
                        Final Amount:{" "}
                        <b className="text-success">
                          {" "}
                          {cto === "EUR" ? "€" : "$"}
                          {finalAmount ? finalAmount.toFixed(2) : output}
                        </b>
                      </h4>
                      <div className="row d-flex justify-content-center">
                        <button
                          data-bs-target="#confirmation"
                          data-bs-toggle="modal"
                          className="btn btn-primary w-25 mt-3"
                        >
                          SAVE
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default External;
