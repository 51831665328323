import axios from "axios";

export const GETLOGS=async(authtoken,sort, order, page)=>{
    return await axios.post(`${process.env.REACT_APP_API}/logs`,{
        sort, order, page
    },{
        headers:{
            authtoken
        }
    })
}
export const GETSPLOGS=async(authtoken,_id,sort, order, page)=>{
    return await axios.post(`${process.env.REACT_APP_API}/splogs`,{
      _id,  sort, order, page
    },{
        headers:{
            authtoken
        }
    })
}



export const getlogscount = async () =>
await axios.get(`${process.env.REACT_APP_API}/logs/total`, {
 
})


export const getsplogscount = async (_id) =>
await axios.get(`${process.env.REACT_APP_API}/logs/sptotal`, {
    headers:{
        _id:_id
    }
 
})

