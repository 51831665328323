import axios from "axios";

export const CREATEINTERNALTRANSFER = async (
  authtoken,
  direction,
  amount,
  output
) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/internal`,
    {
      direction,
      amount,
      output,
    },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const GETINTERNAL = async (authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/internal`,

    {
      headers: {
        authtoken,
      },
    }
  );
};

export const GETAllINTERNALS = async (authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/internals`,

    {
      headers: {
        authtoken,
      },
    }
  );
};
