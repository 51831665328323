import React from 'react'
import { useSelector } from 'react-redux';
const Users=()=>{
  const { user } = useSelector((state) => ({ ...state }));

  return(<>
  <h1>users:{user.email?user.email:"No data for now"}</h1>
  </>)
}
export default Users
