import React, { Component } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GETREFUNDREQ, REFUNDUPDATE } from "../../../function/refund";
import { Navigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
const Refund = () => {
  let navigate = useNavigate();
  const [refounddate] = useState(new Date());
  const [reason, setReason] = useState("");
  // 2-Nov
  // -----ahmad update we no longer need them here in this page
  // const [receiver, setReceiver] = useState([]);
  // const [sender, setSender] = useState([]);
  const [refund, setRefund] = useState([]);
  const [senderName, setSenderName] = useState("");
  const [senderPublicid, setSenderPublicId] = useState("");
  let { user } = useSelector((state) => ({ ...state }));
  let { _id } = useParams();

  useEffect(() => {
    GETREFUNDREQ(user.token, _id).then((res) => {
      setRefund(res.data);

      /*
      ----ahmad I have added two states for sender name and sender publicId and 
      delete the sender and reciver array because we don't need these informauiton here
      // setReceiver(res.data.receiver);
      // setSender(res.data.sender);
      */
      setSenderName(res.data.senderName);
      setSenderPublicId(res.data.senderPublicid);
      console.log("res.data:", res.data);
    });
  }, []);

  const handleConfirmRefund = (e) => {
    e.preventDefault();
    if (reason.length > 15) {
      REFUNDUPDATE(user.token, _id, reason, refounddate).then((res) => {
        //  console.log("resssssss",res.data)
        if (res.data.ok === true) {
          navigate("/serviceprovider/history");
          toast.success("Refunded Successfully");
        } else {
          if (res.data.ok === false) {
            toast.error("Refunded faild reseller dont have enough balance! ");
          }
        }
      });
    } else {
      toast.error("reason should be more than 15 char");
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="alert  alert-success m-5">
          <div className="row">
            <div className="col-2">
              <h6>Request date :</h6>
              {moment(refund.createdAt).format("D/M/Y h:mm:ss A")}
            </div>
            <div className="col-2">
              <h6>request requeststatus :{refund.requeststatus}</h6>
            </div>

            <div className="col-2">
              <h6>Sent Amount :{refund.sentamount}</h6>
            </div>
            <div className="col-2">
              <h6>Fees :{refund.fees}</h6>
            </div>
            <div className="col-2">
              <h6>Received Amount :{refund.receivedamount}</h6>
            </div>
            <div className="col-2">
              <h6>Sender name :{senderName}</h6>
              <h6>Sender public id :{senderPublicid}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <input
                onChange={(e) => setReason(e.target.value)}
                value={reason}
                type="textaria"
                className="w-100"
                cols="10"
                rows="3"
                placeholder="Write here Reason of Refund"
              />
            </div>
            <div className="col-2">
              <button
                className="btn btn-primary"
                disabled={reason.length < 15}
                onClick={handleConfirmRefund}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refund;
