import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/history.css"
import {useState}from 'react'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Space } from 'antd';
const History = () => {
  const[disableCardNumber,setDisableCradNumber]=useState(true)
  const[cardNumber,setCardNumber]=useState(5646546464)
  const[password,setPassword]=useState("")
  const[show,setShow]=useState(false)
  const handleSeeCardNumber=()=>{
    // console.log(show)
setShow(true)
  }
  return (<>
  <div className="container-fluid ">
    <h1 className="text-center">History</h1>
    <div className="row">
      <div className="container d-flex justify-content-center">
          <div className="col-8">
          <div className="card">
    <div className="card-header">
<h5>Card Id:#8237378328732</h5>

    </div>
    <div className="card-body">
    <div className="container">
  <div className="row">
    <div className="col">
<h5>Ahmad Khalaf</h5>
    </div>
    <div className="col text-center">
<h5>13/9/2022</h5>
    </div>
    <div className="col text-end">
<a className="text-primary">Available</a>
<h6 className="text-success">100$</h6>
    </div>
  </div>
  <div className="row">
  <Space direction="vertical">
    
    <Input.Password 
      placeholder="Card number" value={cardNumber} 
       disabled={disableCardNumber} 
    /> {show?(''):(<buton className="btn btn-danger" onClick={handleSeeCardNumber}> show Crad Number</buton>)}
  </Space>
{show?  (<><p>Password:</p><div className="form-group"><input  calassName="form-control" type="password" value={password} /></div><br/>
<buton className="btn btn-success m-2 w-25" > Submit</buton>
</>):''}
  </div>
</div>
    </div>
    <div className="card-footer">

    </div>

  </div>
          </div>
      </div>
 </div>

  </div>
  </>
    // <div className="fortest">
    // <div className="mainhistory">
    //   <div className="maincounter">
    //     <div className="header">
    //       <p className="text-center">22/8/1996</p>
    //       <p id="status" className="text-end"> Avaleibel  </p>
    //     </div>
    //     <div className="body">
    //       <h3>moad bino</h3>
    //       <h4 className="text">1000 <span>usd</span></h4>
    //     </div>
    //     <hr/>
    //     <div className="fotter">
    //       <p> id :15641564563</p>
    //       <button>show detalis</button>
    //     </div>
    //   </div>
    //   </div>
    //   </div>
   
  );
};
export default History;
