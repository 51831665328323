import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import es from "react-phone-input-2/lang/es.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "bootstrap";
import "antd/dist/antd.min.css";
import "../css/register.css";
import { auth } from "../../firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase/compat/app";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import Example from "../cityandcontry/cityandcontry";
import { useSelector } from "react-redux";
import { Alert, Spin } from "antd";
import "../css/mdbRegister.css";
import { Checkbox } from "antd";
import logo from "../images/logo.png";
import { USERCHECK } from "../../function/auth";
const Register = ({ contry, setContry, region, setRegion }) => {
  const [loading, setLoading] = useState(false);
  const [zipCode, setZipCode] = useState();
  const [street, setStreet] = useState();
  const [streetNumber, setStreetNumber] = useState();
  const [city, setCity] = useState("");
  const [contryName, setContryName] = useState("");
  const [contryCode, setContryCode] = useState("us");
  const [address, setAddress] = useState([]);
  const [name, setName] = useState();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [role, setRole] = useState("serviceprovider");
  const auth = getAuth();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);
  const { user } = useSelector((state) => ({ ...state }));

  const [checked, setChecked] = useState(false);
  const HadnleERR = () => {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    // {console.log(num)}
    if (
      window.localStorage.getItem("Defaultcontry") === "undefined" &&
      !window.localStorage.getItem("contry")
    ) {
      toast.error("Please Select Contry!");
      setLoading(false);
      return;
    } else {
      if (
        !window.localStorage.getItem("contry") &&
        window.localStorage.getItem("Defaultcontry") === "Jordan"
      ) {
        toast.error("This country are not supported!");
        setLoading(false);
        return;
      } else {
        if (window.localStorage.getItem("contry") === "Jordan") {
          toast.error("This country are not supported!");
          setLoading(false);
          return;
        }
        if (contryCode.length < 4) {
          toast.error("Phone Number is Required!");
          setLoading(false);
          return;
        }
        if (
          !window.localStorage.getItem("Defaultcontry") &&
          !window.localStorage.getItem("contry")
        ) {
          toast.error("Please Select Contry!");
          setLoading(false);
          return;
        }

        // else{
        //   USERCHECK(email).then((res)=>{
        //     //
        //     if(res.data !== null){
        //       navigate("/login")
        //       toast.error("user alreary exisit !")
        //       return
        //     }else{
        //       HandleReq()
        //     }
        //   }
        //   )
        // }
        else {
          USERCHECK(email).then((res) => {
            // res.data !== null;
            console.log("res.data", res.data);
            if (res.data === "email does not exist") {
              HandleReq();
              toast.success("registeraation account success");
            } else {
              navigate("/login");
              toast.error("user alreary exist !");
              return;
            }
          });
        }
      }
    }
  };

  const HandleReq = async () => {
    const config = {
      url: `${
        window.location.href === "https://www.tathkeer.net/register"
          ? "https://www.tathkeer.net/register/complete"
          : "https://tathkeer.net/register/complete"
      }`,
      handleCodeInApp: true,
    };
    // const config = {
    //         url: 'https://www.tathkeer.net/register/complete',
    //         handleCodeInApp:true
    //     }
    window.localStorage.setItem("emailForSignIn", email);
    window.localStorage.setItem("phone", contryCode);
    window.localStorage.setItem("name", name);
    window.localStorage.setItem("street", street);
    window.localStorage.setItem("streetNumber", streetNumber);
    window.localStorage.setItem("zipCode", zipCode);
    window.localStorage.setItem("name", name);

    await sendSignInLinkToEmail(auth, email, config);
    toast.success(
      `The link has been sent successfully to ${email} Click on the link sent to complete the registration`
    );
    setEmail("");
    setName("");
    setPhone();
    setStreet("");
    setStreetNumber("");
    setZipCode("");
    setLoading(false);
  };
  useEffect(() => {
    setInterval(() => {
      (async () => {})();
    }, 360000);
  }, []);

  const LoadDefaultContry = () => {
    let interval;
    setTimeout(() => {
      if (!contryCode) {
        fetch("https://ipapi.co/json/")
          .then((res) => res.json())
          .then((res) => {
            setContryCode(res.country);
            setContryName(res.country_name);
            setCity(res.city);
            //    console.log("city is ",res.city)
          });
      }

      // .then(res => setCountry_calling_code(res.country_calling_code))
    }, 500000);
  };
  const Accept = (e) => {
    setChecked(e.target.checked);
  };
  return (
    <>
      <div className="container">
        <div>
          {" "}
          <h5 className="text-center mt-5  os">
            You're One Step Far for Creating Account!
          </h5>
        </div>
        <div className="  h-100 border  rerow mb-5 ">
          <div className="row d-flex    justify-content-center align-items-center h-100">
            <div className="row   ">
              <div className="col-lg-6  bg-light text-white  py-4">
                <form className="" onSubmit={handleSubmit}>
                  <div className=" pb-2">
                    <div className="form-outline form-white ">
                      <div className="form-group ">
                        <label className="text-dark">
                          Full Name <b className="star">*</b>
                        </label>
                        <input
                          type="text"
                          required
                          className="form-control "
                          autoFocus
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="form-group mt-1"></div>
                    </div>
                  </div>

                  <div className=" pb-2">
                    <div className="form-outline form-white">
                      <div className="form-group mt-2">
                        <label className="text-dark">
                          Email <b className="star">*</b>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12  ">
                      <div className="form-group">
                        <label className="text-dark">
                          Country <b className="star">*</b>
                        </label>
                        <Example
                          selected={selected}
                          setSelected={setSelected}
                          handleSubmit={handleSubmit}
                          contry={contry}
                        />
                      </div>
                    </div>
                    <div className="col-md-12  pb-2">
                      <div className="form-outline form-white pt-2">
                        <label className="text-dark">
                          Street <b className="star">*</b>
                        </label>
                        <div className="col ">
                          <input
                            value={street}
                            required
                            className="form-control"
                            placeholder=" "
                            type="text"
                            onChange={(e) => setStreet(e.target.value)}
                          />
                        </div>
                        <div className="col mt-2">
                          <label className="text-dark">
                            Street Number <b className="star">*</b>
                          </label>

                          <input
                            value={streetNumber}
                            required
                            className="form-control"
                            type="number"
                            onChange={(e) => setStreetNumber(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" pb-2">
                    <div className="form-outline form-white">
                      <h6>
                        Phone <b className="star">*</b>
                      </h6>
                      <div className="row">
                        <div className="col">
                          <PhoneInput
                            className="mb-2 w-100"
                            searchClass="search-class"
                            countryCodeEditable={false}
                            enableSearchField
                            disableSearchIcon
                            required
                            country={contryCode}
                            value={contryCode}
                            onChange={setContryCode}
                            enableAreaCodeStretch
                          />
                        </div>
                        <div className="col">
                          <div className="form-group ">
                            <input
                              className="form-control"
                              required
                              placeholder="Zip Code "
                              type="number"
                              value={zipCode}
                              onChange={(e) => setZipCode(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-check d-flex justify-content-start mb-4 pb-3">
                    <label
                      className="form-check-label text-white"
                      for="form2Example3"
                    >
                      <Checkbox required checked={checked} onChange={Accept}>
                        {" "}
                      </Checkbox>
                      <b className="text-dark"> I do accept the</b>
                      <u>
                        <Link
                          target="_blank"
                          to="/Privacy"
                          className="text-primary"
                        >
                          Terms and Conditions
                        </Link>
                      </u>{" "}
                      of your site.
                    </label>
                  </div>
                  {loading ? (
                    <Spin tip="Loading..."></Spin>
                  ) : (
                    <button
                      disabled={!phone && !checked && !zipCode}
                      type="submit"
                      id="sbtn"
                      className="btn w-100"
                    >
                      Register
                    </button>
                  )}
                </form>
              </div>
              <div className="col-lg-6 bgimg "></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
