import axios from "axios";

export const CREATEADMINCASHOUT=async(authtoken,amount)=>{
      return await axios.post(`${process.env.REACT_APP_API}/admincashout`,{amount},{
       headers: { 
            authtoken 
        } 
    })


}
export const CREATEADMINDOLLARCASHOUT = async (authtoken, amount) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admindollarcashout`,
    { amount },
    {
      headers: {
        authtoken,
      },
    }
  );
};


export const GETADMINCASHOUT=async(authtoken)=>{
    return await axios.get(`${process.env.REACT_APP_API}/admincashout`,{
        headers:{
            authtoken
        }
    })
}
export const GETADMINEXTERNAL = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/admincashout`, {
    headers: {
      authtoken,
    },
  });
};