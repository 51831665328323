import axios from "axios";

export const GETDEFAULTFEES = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/defaultfees`, {
    headers: {
      authtoken,
    },
  });
};

export const UPDATFEES = async (authtoken, defaultFees) => {
  // console.log("defaultFeeeeeeeees",defaultFees)
  return await axios.put(
    `${process.env.REACT_APP_API}/defaultfees/update`,
    { defaultFees },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const CREATEFEES = async (authtoken, defaultfees) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/defaultfees/createorupdate`,
    { defaultfees },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const CREATTRANSEFEES = async (authtoken, transactionfees) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/transactionfees/createorupdate`,
    { transactionfees },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const CREATCASHOUTEFEES = async (authtoken, cashoutfees) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/cashoutfees`,
    { cashoutfees },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const CREATEORUPDATEPAYMENTSSTATUS = async (
  authtoken,
  paypalstatus,
  cardstatus,
  bankstatus
) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/admin/paymentsstatus`,
    { paypalstatus, cardstatus, bankstatus },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const getVisiblity = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_API}/visibilty`, {});
    return res;
  } catch (error) {
    console.log(error);
  }
};
