import React, { useState } from 'react';
import '../css/code.css'
const CodeGenerator=()=>{
 const[nof,setNof]=useState(0)
  const Rn=()=>{


   const num = Math.floor(1000000000 + Math.random() * 9000000000)

   
    return(
        <>
          <h3 class="app__random-no-heading">{num }</h3>
        </>
    )
  }

const threeHeaders = Array.from({length: nof}, (_, index) => {
    return <Rn key={index} />;
  });

  
    return(
    <>
    
    <div className='container-fluid code'>
        <div className='row'>
            <div className='col'>
            <div class="app__random-no-container">
      <h3 class="app__random-no-heading">Your number:</h3>
      <input type='Number' value={nof} onChange={(e)=>setNof(e.target.value)}/>
      <div>{threeHeaders}</div>
    
    </div>
            </div>
        </div>
    </div>
    </>
    )

    }
export default CodeGenerator
