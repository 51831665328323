import React from "react";

import "../../css/history.css";
import { useState } from "react";

import {
  SettingOutlined,
  CopyOutlined,
  EditOutlined,
  UserOutlined,
  EnvironmentOutlined,
  MessageOutlined,
  PhoneOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  CloseOutlined,
  DollarCircleOutlined,
  CloseCircleOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  CalendarOutlined,
  CarOutlined,
  MailOutlined,
  ControlOutlined,
} from "@ant-design/icons";

import { toast } from "react-toastify";
import { Input, Tooltip, Button } from "antd";
import { Link, useParams } from "react-router-dom";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import { useEffect } from "react";
import { GETORDERS, UPDATORDER } from "../../../function/orders";
import { useSelector } from "react-redux";
import { GETCUSTOMERORDER } from "../../../function/customer";

const CustomersOrdersManage = () => {
  const [disabled, setDisabled] = useState(true);
  const [resllerid, setResllerid] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState();
  const [address, setAddress] = useState();
  let { _id } = useParams();
  const [order, setOrder] = useState([]);
  let { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    LoadUserOrder();
  }, []);

  const LoadUserOrder = () => {
    console.log({ _id });
    GETCUSTOMERORDER(user.token, _id).then((res) => {
      setResllerid(res.data.resllerid);
      setOrder(res.data);
      setName(res.data.name);
      setEmail(res.data.email);
      setAddress(res.data.address);
      setNote(res.data.phone);
      setPhone(res.data.phone);
    });
  };
  const handleCopyResellerId = () => {
    navigator.clipboard.writeText(order.resllerid);
    toast.success(` ${resllerid} Copied `);
  };
  const handleCopyEmail = () => {
    navigator.clipboard.writeText(order.email);
    toast.success(` ${email} Copied `);
  };
  const handleEdit = () => {
    setDisabled(false);
  };

  const handleSubmit = () => {
    setDisabled(true);
    UPDATORDER(user.token, _id, name, phone, address, note).then((res) => {
      toast.success("saved successuflly");
    });
  };
  return (
    <>
      <div className="container customerspage">
        <div className="row">
          <h1 className="text-center text-warning">
            {" "}
            order serial number <u>{order.serialnumber}</u>
          </h1>
          <form className="form" onSubmit={handleSubmit}>
            {" "}
            <div className="d-flex justify-content-center">
              <label className="text-white">Reseller Id :</label>
              <br />
              <Input
                disabled
                style={{
                  width: "calc(50% - 35px)",
                  marginBottom: "2%",
                }}
                value={resllerid}
              />
              <Tooltip title="copy reserller id">
                <Button
                  icon={<CopyOutlined />}
                  onClick={handleCopyResellerId}
                />
              </Tooltip>
            </div>
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-10 ">
                  <div className="mt-5 mb-2 ">
                    <h3 className="text-dark text-center pe-2">
                      Customer Details
                      {disabled ? (
                        <Tooltip title="Edit">
                          <Button
                            className="ms-5"
                            icon={<SettingOutlined />}
                            onClick={handleEdit}
                          />{" "}
                        </Tooltip>
                      ) : (
                        <>
                          <Tooltip title="save">
                            <Button
                              className="bg-success"
                              icon={<CheckCircleOutlined />}
                              onClick={handleSubmit}
                            >
                              save
                            </Button>
                          </Tooltip>
                          <br />
                          <Button
                            className="bg-danger"
                            icon={<CloseOutlined />}
                            onClick={() => setDisabled(true)}
                          >
                            cancel
                          </Button>
                        </>
                      )}
                    </h3>

                    <label className="text-white"> Email</label>
                    <br />
                    <Input
                      disabled
                      style={{
                        width: "calc(100% - 35px)",
                        marginBottom: "2%",
                        color: "blue",
                      }}
                      value={order.email}
                      prefix={<MailOutlined className="site-form-item-icon" />}
                    />
                    <Tooltip title="copy Email">
                      <Button
                        icon={<CopyOutlined />}
                        onClick={handleCopyEmail}
                      />
                    </Tooltip>

                    <label className="text-white"> name</label>
                    <br />
                    <Input
                      disabled={disabled}
                      style={{
                        width: "calc(100% - 35px)",
                        marginBottom: "2%",
                      }}
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      placeholder="click to edit user name"
                      prefix={
                        <UserOutlined className="site-form-item-icon text-primary" />
                      }
                    />

                    <label className="text-white"> Phone</label>
                    <br />
                    <Input
                      disabled={disabled}
                      style={{
                        width: "calc(100% - 35px)",
                        marginBottom: "2%",
                      }}
                      placeholder="click to edit user phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      prefix={
                        <PhoneOutlined className="site-form-item-icon text-success" />
                      }
                    />

                    <label className="text-white">Address</label>
                    <br />
                    <Input
                      disabled={disabled}
                      style={{
                        width: "calc(100% - 35px)",
                        marginBottom: "2%",
                      }}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="click to edit user address"
                      prefix={
                        <EnvironmentOutlined className="site-form-item-icon text-success" />
                      }
                    />
                    <br />
                    <label className="text-white">Note</label>
                    <br />
                    <Input
                      disabled={disabled}
                      style={{
                        width: "calc(100% - 35px)",
                        marginBottom: "2%",
                      }}
                      onChange={(e) => setNote(e.target.value)}
                      value={note}
                      placeholder="click to edit Note Field"
                      prefix={
                        <MessageOutlined className="site-form-item-icon text-success" />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default CustomersOrdersManage;
