import React from 'react';
import '../../css/analysis.css'



const Analysis=()=>{
    return(<>
    <div className='container-fluid analysispage'>
        <div className='row'>
            <div className='col-4'>
     <div className='card anacard'>
        <div className='cared-header'>
            
        </div>
    <div className='card-body'>
   <h1 className='text-center text-white'>Analysis Page</h1>
        <p className='text-center text-white'>Coming soon</p>
        </div>
        </div>
            </div>

        </div>
   
    </div>
   
</>)
}
export default Analysis