import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../css/history.css";
import { Link } from "react-router-dom";
import { CloudDownloadOutlined, EyeOutlined } from "@ant-design/icons";
import ModaImage from "react-modal-image";
import { useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input, Space } from "antd";
import { useEffect } from "react";
import {
  createandupdaterejectreason,
  GETORDERS,
  getorderscount,
  SENDCODES,
} from "../../../function/orders";
import { useSelector } from "react-redux";
import { UPDATEORDERSTATUS } from "../../../function/orders";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import ordersimg from "../../images/ordersimg.png";
import { Pagination } from "antd";

const Orders = () => {
  const [ordersCount, setOrdersCount] = useState(0);
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [disableCardNumber, setDisableCradNumber] = useState(true);
  const [cardNumber, setCardNumber] = useState(5646546464);
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [rejectreason, setRejectreason] = useState("");
  const [rejectId, setRejectId] = useState();
  const { user } = useSelector((state) => ({ ...state }));
  const { Search } = Input;
  const [keyword, setKeyword] = useState("");
 

  



  const handleSearch = (value) => {
     
setKeyword(value)
      console.log("result",value)
    
   
  }

  //   c.transactioncode.toLowerCase().includes(keyword);

  // const [paymentIntent, setPaymentIntent] = useState([]);

  const handlerejectreason = (e) => {
    setRejectreason(e.target.value);
  };

  const handleSeeCardNumber = () => {
    // console.log(show)
    setShow(true);
  };

  const PaymentDetails = (payment) => {
    return (
      <>
        {JSON.stringify(payment)}
        {payment.map((p) => {
          return <>{JSON.stringify(p.payment)}</>;
        })}
      </>
    );
  };

  const PaymentIntent = (orderPaymentIntent) => {
    //   orderPaymentIntent.map(({amount, paymenttype, cardsNumber})=>{
    // console.log(`${amount} with quantity ${paymenttype} with price ${cardsNumber}`)
    // });
  };

  const handleStatusChange = (_id, status, cards, email) => {
    var data = {
      codes: cards,
      email: email,
    };

    // console.log(JSON.stringify(cards),status,email)

    UPDATEORDERSTATUS(user.token, _id, status).then((res) => {
      // console.log(res.data)
      toast.success("status updated!");
      LoadOrders();
      // if (res.data.ok && status === "Completed") {
      //   axios
      //     .post(`${process.env.REACT_APP_API}/mail`, data)
      //     .then((res) => {
      //       console.log(res);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // }
    });
  };
  const LoadOrders = () => {
    getorderscount().then((res) => {
      setOrdersCount(res.data);
    });
    GETORDERS(user.token, "createdAt", "desc", page,keyword).then((res) => {
      //  console.log(res.data);
      setOrders(res.data);

      {
        res.data.map((or) => {
          setRejectreason(or.rejectreason);
        });
      }
    });
  };

  const handleOrderRejected = (_id) => {
    // console.log("_id",_id)
  };

  const handleRejectreasonf = () => {
    console.log(rejectId);
    console.log(rejectreason);
    createandupdaterejectreason(user.token, rejectId, rejectreason).then(
      (res) => {
        toast.success("Reason Created Success");
      }
    );
  };

  useEffect(() => {
 
    LoadOrders();

  }, [page,keyword]);

  return (
    <>
      <div className="row mt-5 p-2">
        {/* <OldTable/> */}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card servicceProvidersCard table-responsive">
                <div className="card-header">
                  <div className="row">
                    <div className="col-5">
                      <div>
                        <img width={"70px"} src={ordersimg} />
                        <b className="h4 ms-2">Orders</b>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="mt-2">

                      <Search
              placeholder="Write Here proper words"
              allowClear

              onChange={(e)=>handleSearch(e.target.value)}

              value={keyword}
          />
          {JSON.stringify(keyword)}
                      
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="float-end me-auto">
                        {" "}
                        <button className=" btn btn-light-info h5">
                          <CloudDownloadOutlined style={{ fontSize: "20px" }} />{" "}
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <table className="serviceTable table ">
                <thead>
                  <tr className="bg-light">
                    <th scope="col">order Number </th>
                    <th scope="col">Reseller Id</th>
                    <th scope="col">Orderd By</th>
                    <th scope="col">Subscription Type</th>
                    <th scope="col">payment status</th>

                    <th scope="col">order status</th>
                    <th scope="col"> payment Currnecy</th>
                    <th scope="col">total amount</th>
                    <th scope="col">amount After fees</th>
                    <th scope="col">share SP amount</th>

                    <th scope="col">payment Type</th>
                    <th scope="col">transfer Code </th>
                    <th scope="col">transfer img</th>
                    <th>name</th>
                    <th scope="col">Date </th>
                    {/* <th scope="col">Invoice </th> */}
                    <th className="text-danger">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orders
                    ? orders.map((order, index) => {
                        const handleSendCodes = (_id) => {
                          // console.log(res.data)

                          SENDCODES(user.token, _id).then((res) => {
                            res.data.ok == true
                              ? toast.success("codes sent Success")
                              : toast.error("somthing went wrong");
                          });
                        };
                        return (
                          <>
                            {/* {JSON.stringify(order.cards)} */}
                         
                          <tr >
                              <th>{order.serialnumber}</th>

                              <td >
                                {" "}
                                <Link
                                  key={order._id}
                                  to={`/user/${order.resllerid}`}
                                  className="text-danger"
                                >
                                  {order.resllerid}
                                </Link>
                              </td>
                              <td className={order.status === "Completed"?'statussuccess':order.status === "Not Completed"?'statusnotcompleted':order.status === "On Proccess"?'statusonprocess':'rejected'} >{order.email}</td>
                              <td>{order.subScriptionType}</td>

                              {/* <PaymentIntent orderPaymentIntent={order.PaymentIntent}/> */}
                              {/* {order.payment.map((p)=>{
       
      

       })} */}
       {/* {JSON.stringify(order.status)} */}

                              <td>{order.status}</td>
                              <td>
                                <select
                                  // disabled={order.status === "Completed"}
                                  class="form-select form-select-sm"
                                  aria-label=".form-select-sm example"
                                  onChange={(e) =>
                                    handleStatusChange(
                                      order._id,
                                      e.target.value,
                                      order.cards,
                                      order.email
                                    )
                                  }
                                  value={order.status}
                                >
                                  <option selected>{order.status}</option>
                                  {order.status === "Not Completed" ? (
                                    ""
                                  ) : (
                                    <option value={"Not Completed"}>
                                      Not Comleted
                                    </option>
                                  )}
                                  {order.status === "Completed" ? (
                                    ""
                                  ) : (
                                    <option value={"Completed"}>
                                      Completed
                                    </option>
                                  )}
                                  {order.status === "On Process" ? (
                                    ""
                                  ) : (
                                    <option value={"On Process"}>
                                      On Process
                                    </option>
                                  )}
                                  {order.status === "Rejected" ? (
                                    ""
                                  ) : (
                                    <option value={"Rejected"}>Rejected</option>
                                  )}
                                  {order.status === "Refound" ? (
                                    ""
                                  ) : (
                                    <option value={"Refound"}>Refound</option>
                                  )}
                                </select>
                              </td>
                              <td
                                className={
                                  order.currency === "USD"
                                    ? "text-success"
                                    : "text-primary"
                                }
                              >
                                {order.currency ? order.currency : ""}
                              </td>
                              {order.paymentIntent.map((opi) => {
                                return (
                                  <>
                                    <td className="text-dark">{Number(opi.amount)?Number(opi.amount).toFixed(2):opi.amount || ''}</td>
                                    <td>{Number(order.amountaf)?Number(order.amountaf).toFixed(2):order.amountaf || ''}</td>
                                    <td>
                                      {Number(order.amountaaf)?Number(order.amountaaf).toFixed(2):order.amountaaf || '' }
                                    </td>
                                    <td> {opi.paymenttype}</td>
                                  </>
                                );
                              })}
                              <td>
                                {order.transactioncode ? (
                                  <div className="text-primary">
                                    {order.transactioncode}
                                  </div>
                                ) : (
                                  <div className="text-danger">NO Code</div>
                                )}
                              </td>
                              <td style={{ width: "80px", height: "auto" }}>
                                {/* {order.transferimg?(<ModaImage  className='border border-warning rounded ' small={require(`${order.spath}/${order.transferimg}`)} large={`${order.path}/${order.transferimg}`} />):"No image"} */}
                                {order.transferimg ? (
                                  <a
                                    target="blank"
                                    href={`https://photo.tathkeer.net/${order.transferimg}`}
                                  >
                                    click heref
                                  </a>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {order.name ? (
                                  <div className="text-primary">
                                    {order.name}
                                  </div>
                                ) : (
                                  <div>No Name</div>
                                )}
                              </td>
                              {/* <td> {<img src={`${img}`}/>}</td> */}
                              <td>
                                <b className="text-dark">
                                  {moment(order.createdAt).format(
                                    "D/M/Y h:mm:ss A"
                                  )}
                                </b>
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle className="monthsdropdown"></Dropdown.Toggle>

                                  <Dropdown.Menu className="w-100">
                                    <Dropdown.Item
                                      className="text-danger"
                                      onClick={() => handleSendCodes(order._id)}
                                    >
                                      Send Codes
                                    </Dropdown.Item>

                                    <Dropdown.Item>
                                      {" "}
                                      <Link to={`/admin/invoice/${order._id}`}>
                                        Invoice
                                      </Link>
                                    </Dropdown.Item>
                                    {order.transactioncode &&
                                    order.status === "Rejected" ? (
                                      <Dropdown.Item
                                        data-bs-toggle="modal"
                                        data-bs-target="#reject"
                                        onClick={() => setRejectId(order._id)}
                                      >
                                        {" "}
                                        Reject reason
                                      </Dropdown.Item>
                                    ) : (
                                      ""
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              {/* <td> </td> */}
                            </tr>
                        
                          </>
                        );
                      })
                    : "no data for now"}
                </tbody>
              </table>
              <div className="d-flex justify-content-center">
                <Pagination
                  current={page}
                  total={Math.round((ordersCount / 12) * 10)}
                  onChange={(value) => setPage(value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Orders;
