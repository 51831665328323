import { combineReducers } from "redux";
import {userReducer} from './userReducer'
import { drawerReducer } from "./drawer";
import { drawer2Reducer } from "./drawer2";
import { searchReducer } from "./searchReducer";
const rootReducer = combineReducers({
user:userReducer ,
 drawer: drawerReducer,
   drawer2: drawer2Reducer,
    search: searchReducer,

})
export default rootReducer