import React from 'react';

import { useEffect } from 'react';
import { GETCUSTOMERORDERS } from '../../../function/customer';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


 import { DownOutlined,EditOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import { useState } from 'react';


const CustomerOrders=()=>{
  const [orders,setOrders]=useState([])
   const [cards,setCards]=useState([])
   let { user } = useSelector((state) => ({ ...state }));
 let { email } = useParams()

 useEffect(()=>{
GETCUSTOMERORDERS(user.token,email).then((res)=>{
setOrders(res.data)
{res.data.map((o)=>{
setCards(o.cards)
})}


}) 
 },[])
return(<>

 



<div className='container customerspage '>
        <div className='row '>
            <h1 className='text-dark text-center '>  customer Orders</h1>
            <h5 className='text-warning text-center mt-5'><u>{orders.length }</u> ORDERS</h5>
 <div class="table-responsive ">
       <table class="table  table-hover mt-3 table-responsive responsive" striped>
        
  <thead className=' bg-dark'>
    <tr className='text-white text-center'>
      <th scope="col">Order Number</th>
       <th scope="col">Customer</th>
      <th scope="col">SubScription</th>
      <th scope="col">number of cards</th>
 
    
  <th scope="col">payment status</th>
<th scope="col">Date of order</th>
<th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
      {orders.map((order,index)=>{
        return(<>
    <tr className='text-center' >
      <th scope="row">#{index+1}</th>
        <td> 
      
        
  
    
        {order.email}
     </td>
      <td>{order.subScriptionType}</td>
      <td>{order.cards.length}
             <div class="accordion" id="accordionExample">
 
 
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <Link to={`/admin/codes/${order._id}`}><button class="btn btn-outline-dark " type="button" data-bs-toggle="modal" data-bs-target="#codes" aria-expanded="false" aria-controls="collapseThree">
       show all cards
      </button></Link>
    </h2>
     
      
       </div>
</div>
      </td> 

  
    <td>{order.status}</td>
    <td>{order.createdAt}</td>
   <td>
 
           
                 
              
                  <Link className=' ' to={`/admin/Customerorders/Edit/${order._id}`}><EditOutlined className='h4 text-danger' /> </Link>
             
         
           
   </td>
     
    </tr>
      </>)
  })}
  </tbody>
  
</table>
    
</div>
        
      
        
    </div>
</div>


</>)
}

export default CustomerOrders