import React ,{useState}from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from 'react';
import { GETADMINCASHOUT } from '../../../function/admincashout';
import { useSelector } from 'react-redux';
import Card from "react-bootstrap/Card";
import { GETADMINTRANSACTION } from '../../../function/admintransaction';
import { GETEXTERNAL } from '../../../function/external';
const Financialproceduers=()=>{

const {user}=useSelector((state)=>({...state}))
const [cashout,setCashout]=useState([])
const[transaction,setTransaction]=useState([])
const [external, setExternal] = useState([]);
useEffect(()=>{
load()
},[])
const load=()=>{
    GETADMINCASHOUT(user.token).then((res)=>{
    setCashout(res.data)
    })
    GETADMINTRANSACTION(user.token).then((res)=>{
    setTransaction(res.data)
})

GETEXTERNAL(user.token).then((res)=>{
  setExternal(res.data)
})
  }

  const Admintransaction=()=>{
  return(<>
  <div className='row'>
{transaction.map((tr,i)=>{

   return (
     <>
       {transaction.length ? (
         <div className="col-4 ">
           <Card
             className="headCards"
             style={{ textAlign: "", marginTop: "15px" }}
           >
             <Card.Body>
               <Card.Title>
                 <div className=" text-capitalize">{i + 1}</div>{" "}
               </Card.Title>

               <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
               <Card.Text>
                 <div className="container ">
                   {/* {JSON.stringify(tr.createdAt)} */}
                   <h5>
                     {" "}
                     Date {moment(tr.createdAt).format("D/M/Y h:mm:ss A")}
                   </h5>
                   <div className="text-dark">
                     Source<b className="text-primary">{tr.source}</b>
                   </div>
                   <div className="text-dark">
                     Destination{" "}
                     <b className="text-primary">{tr.destination}</b>
                   </div>
                   <div className="text-dark">
                     Total Amount <b className="text-primary">{tr.amount}</b>
                   </div>
                   <div className="text-dark">
                     Fees <b className="text-primary">{tr.fees}</b>
                   </div>
                   <div className="text-dark">
                     Sent Amount <b className="text-primary">{tr.sentAmount}</b>
                   </div>
                 </div>
               </Card.Text>
             </Card.Body>
           </Card>
         </div>
       ) : (
         "no data for now"
       )}
     </>
   ); 
})}


  </div>

      </>)
}

    const Cashout=()=>{
        return(<>
       
       
          
       
<div className='row'>


       {cashout.map((cor,i)=>{
            return (
              <>
                {cashout.length ? (
                  <div className="col">
                    <Card
                      className="headCards"
                      style={{ textAlign: "", marginTop: "15px" }}
                    >
                      <Card.Body>
                        <Card.Title>
                          <div className=" text-capitalize">{i + 1}</div>{" "}
                        </Card.Title>

                        <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                        <Card.Text>
                          <div className="container ">
                           
                            <h5>
                              {" "}
                              Date{" "}
                              {moment(cor.createdAt).format("D/M/Y h:mm:ss A")}
                            </h5>
                            <div className="text-dark">
                              last balance
                              <b className="text-primary">
                                {Number(cor.lastbalance).toFixed(2)}
                              </b>
                            </div>
                            <div className="text-dark">
                              amount{" "}
                              <b className="text-primary">
                                {Number(cor.cashoutamount).toFixed(2)}
                              </b>
                            </div>
                            <div className="text-dark">
                              new balance{" "}
                              <b className="text-primary">
                                {Number(cor.newbalance).toFixed(2)}
                              </b>
                            </div>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ) : (
                  "no data for now"
                )}
              </>
            );  })}
             </div>
        
        </>)
   
      
     
    }


const Externals = () => {
  return (
    <>
      <div className="row">
        {external.map((cor, i) => {
          return (
            <>
              {external.length ? (
                <div className="col-md-4">
                  <Card
                    className="headCards"
                    style={{ textAlign: "", marginTop: "15px" }}
                  >
                    <Card.Body>
                      <Card.Title>
                        <div className=" text-capitalize">{cor.uniqueid}</div>{" "}
                      </Card.Title>

                      <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                      <Card.Text>
                        <div className="container ">
                          <h5>
                            {" "}
                            Date{" "}
                            {moment(cor.createdAt).format("D/M/Y h:mm:ss A")}
                          </h5>
                          <div className="text-dark">
                            sent Amount
                            <b className="text-primary">
                              {Number(cor.sentamount).toFixed(2)}
                            </b>
                          </div>
                          <div className="text-dark">
                            converted Amount{" "}
                            <b className="text-primary">
                              {Number(cor.convertedamount).toFixed(2)}
                            </b>
                          </div>
                          <div className="text-dark">
                            fees{" "}
                            <b className="text-primary">
                              {Number(cor.fees).toFixed(2)}
                            </b>
                          </div>
                          <div className="text-dark">
                            recived Amount{" "}
                            <b className="text-primary">
                              {Number(cor.recivedamount).toFixed(2)}
                            </b>
                          </div>
                          <div className="text-dark">
                            sender <b className="text-primary">{cor.sender}</b>
                          </div>
                          <div className="text-dark">
                            receiver{" "}
                            <b className="text-primary">{cor.receiver}</b>
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ) : (
                "no data for now"
              )}
            </>
          );
        })}
      </div>
    </>
  );
};


    
return (
  <>
    <div className="container">
      <div className="row">
        <h1>Admin Financial procedures</h1>
      </div>
      <div className="row">
        <Tabs defaultActiveKey="Cashout" className="mb-3" fill>
          
          <Tab
            className="bg-white "
            eventKey="Cashout"
            title={<h1>Cashout</h1>}
          >
            <Cashout />
          </Tab>
          <Tab
            eventKey="transaction"
            className="bg-white "
            title={<h1>transaction</h1>}
          >
            <Admintransaction />
          </Tab>
          <Tab
            eventKey="External"
            className="bg-white "
            title={<h1>External</h1>}
          >
            <Externals />
          </Tab>
        </Tabs>
      </div>
    </div>
  </>
);


}

export default Financialproceduers