import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "../stripe/stripe.css";
import StripeCheckout from "../stripe/stripecheckout";

// load stripe outside of components render to avoid recreating stripe object on every render
const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Payment = ({amount,orederDetails,threeHeaders2,threeHeaders,handlePaymentIntent}) => {
    console.log('amount in payment page ', amount);
    return (
        <div className="container p-5 text-center">
          
            <h4>Complete your purchase</h4>
            <Elements stripe={promise}>
                <div className="col-md-8 offset-md-2">
                    <StripeCheckout amount={amount} orederDetails={orederDetails}
                     threeHeaders2={threeHeaders2} threeHeaders={threeHeaders} handlePaymentIntent={handlePaymentIntent}/>
                </div>
            </Elements>
        </div>
    );
};

export default Payment;