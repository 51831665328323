import React, { useEffect,useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { GETONEORDER } from '../../../function/orders';
import moment from "moment";
const Codes=()=>{
    const [orderCodes,setOrderCodes]=useState([])
     let { user } = useSelector((state) => ({ ...state }));
 let { _id } = useParams()

useEffect(()=>{
loadOrder()
},[])
 const loadOrder=()=>{
    GETONEORDER(user.token,_id).then((res)=>{
        setOrderCodes(res.data)
    })
 }
    return(<>
    

<div className='container'>
    <h1 className='text-center'>    {JSON.stringify(orderCodes.length)} Code</h1>
  {orderCodes.map((c)=>{
        return(<>

        <div className='alert alert-warning '>
            <h3 className=''>code :<b className='text-danger'>{c.code}</b>  </h3>
            <div><h3>Expire:       <b className='text-danger'>  {moment(c.Expire).format('D/M/Y h:mm A')}</b></h3> </div>
             <div> <h3>subscripe:<b className='text-danger'>{c.subscribe}</b></h3></div>
        </div>
        </>)
    })}
</div>
  
    
    </>)
}
export default Codes