import React,{useState} from 'react';

import { Link, useParams } from 'react-router-dom';
const CustmerDetials=()=>{
       const [keyword, setKeyword] = useState("")
const search = (keyword) => (c) => c.transactioncode.toLowerCase().includes(keyword)
       let { email } = useParams()
return(
    <>
    <h1 className='text-center text-white'>Customer Details</h1>
        <h4 className='text-center'>
                      <b className='text-white text-center'>{email}</b>  page
        </h4>
           
        
    </>
)
}
export default CustmerDetials