import axios from 'axios'

export const createPaymentIntent=(amount)=>{
    console.log('amount in create payment intent',amount);
    return axios.post(`${process.env.REACT_APP_API}/create-payment-intent`,{amount})
}

export const createOrder = async (stripeResponse,orederDetails) =>
    await axios.post(`${process.env.REACT_APP_API}/user/order`, { stripeResponse,orederDetails }, {
       
    })