import Axios from "axios";
import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { USERCHECK, CHECKSERVICEPROVIDER } from "../../../function/auth";
import { toast } from "react-toastify";
import { CREATECAHSOUTREQ } from "../../../function/cashout";
import "../../css/cashout.css";
import { GETDEFAULTFEES } from "../../../function/settings";
import Card from "react-bootstrap/Card";
import cashoutimg from "../../images/cashoutimg.png";

const SPCacheOut = () => {
  const [cashoutAmount, setCashoutAmount] = useState();
  const [info, setInfo] = useState([]);
  const [from, setFrom] = useState("eur");
  const [to, setTo] = useState("usd");
  const [output, setOutput] = useState(0);
  const [totalCashoutAmountDollar, setTotalCashoutAmountDollar] = useState();
  const [currencyfees, setCurrencyfees] = useState(0.03);
  const [cashoutCrureencyFees, setCashoutCurrencyFees] = useState();
  const [cashoutbankname, setCashoutbankname] = useState("");
  const [cashoutbankiban, setCashoutbankiban] = useState("");
  const [cashoutcryptonumber, setCashoutcryptonumber] = useState("");
  const [cashoutBank, setCashoutBank] = useState(true);
  const [cashoutMethod, setCashoutMethod] = useState("banktransfer");
  const [checked, setChecked] = useState(false);
  const [disabledC, setDisabledC] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [balance, setBalance] = useState();
  const [cashoutfees, setCashoutfees] = useState();
  const [usdbalance, setUsdbalance] = useState();
  const { user } = useSelector((state) => ({ ...state }));

  // useEffect(() => {
  //   Axios.get(
  //     `https://open.er-api.com/v6/latest/${from}`
  //   ).then((res) => {
  //     setInfo(res.data[from]);
  //   });
  // }, [from]);

  const convert = () => {};

  // const handleInput=(e)=>{
  //     setInput(e.target.value)
  //     convert()
  //   }

  const load = async () => {
    const res = await CHECKSERVICEPROVIDER(user.token);
    console.log("chashout response", res);
    setBalance(res.data.balance.toFixed(2));
    setUsdbalance(res.data.usdbalance.toFixed(2));
  };

  const handlecashoutcryptonumber = (e) => {
    setCashoutcryptonumber(e.target.value);
  };

  const loadDefaultFees = () => {
    GETDEFAULTFEES(user.token).then((res) => {
      {
        res.data.map((res) => {
          console.log(res.cashoutfees);
          setCashoutfees(res.cashoutfees);
        });
      }
    });
  };
  const handlecashoutbankname = (e) => {
    setCashoutbankname(e.target.value);
  };
  const handlecashoutbankiban = (e) => {
    setCashoutbankiban(e.target.value);
  };

  const navigate = useNavigate();
  useEffect(() => {
    loadDefaultFees();
    load();
  }, [balance]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (checked === false) {
      toast.error(`You Must Accept privacy policy`);
    } else {
      if (!cashoutbankname && !cashoutbankiban && !cashoutcryptonumber) {
        toast.error("please Enter required info");
      } else {
        if (cashoutMethod === "banktransfer") {
          if (cashoutAmount <= balance) {
            CREATECAHSOUTREQ(
              user.token,
              cashoutAmount,
              cashoutMethod,
              cashoutbankname,
              cashoutbankiban,
              cashoutcryptonumber,
              output,
              currencyfees,
              cashoutCrureencyFees,
              totalCashoutAmountDollar
            ).then((res) => {
              load();
              navigate("/serviceprovider/history");
              toast.success(
                `request ${cashoutAmount}  has ben submited succcesfully! `
              );
            });
          } else {
            toast.error("check your balance");
          }
        } else {
          if (cashoutAmount <= usdbalance) {
            CREATECAHSOUTREQ(
              user.token,
              cashoutAmount,
              cashoutMethod,
              cashoutbankname,
              cashoutbankiban,
              cashoutcryptonumber,
              output,
              currencyfees,
              cashoutCrureencyFees,
              totalCashoutAmountDollar
            ).then((res) => {
              load();
              navigate("/serviceprovider/history");
              toast.success(
                `request ${cashoutAmount}  has ben submited succcesfully! `
              );
            });
          } else {
            toast.error("check your balance");
          }
        }
      }
    }
  };

  const handleCashoutMethod = (e) => {
    setCashoutMethod(e.target.value);
    setCashoutAmount(0);
    if (e.target.value === "banktransfer") {
      setCashoutBank(true);
    } else {
      setCashoutBank(false);
    }
  };

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleAmount = (e) => {
    setCashoutAmount(e.target.value);

    if (cashoutMethod === "banktransfer") {
      setTimeout(() => {
        if (e.target.value > Number(balance)) {
          setDisabledC(true);
          toast.error(`maximum value is ${balance}`);
        }
        if (e.target.value < 1) {
          setDisabledC(true);
          toast.error(`min value is 1`);
        }
        if (e.target.value > 1 && e.target.value < Number(balance)) {
          setDisabledC(false);
        }
      }, 3000);
    }

    if (cashoutMethod === "cryptocurrency") {
      setTimeout(() => {
        if (e.target.value > Number(usdbalance)) {
          setDisabledC(true);
          toast.error(`maximum value is ${usdbalance}`);
        }
        if (e.target.value < 1) {
          setDisabledC(true);
          toast.error(`min value is 1`);
        }
        if (e.target.value > 1 && e.target.value < Number(usdbalance)) {
          setDisabledC(false);
        }
      }, 3000);
    }
  };

  useEffect(() => {}, [cashoutAmount]);

  return (
    <>
      <div className="container-fluid ">
        <div className="row ">
          <h1 className="ms-5">Cache Out</h1>
          <h5 className="ms-5">
            For Done cashout requests{" "}
            <u>
              <Link className="" to="/serviceprovider/history">
                History
              </Link>
            </u>
          </h5>

          <div className=" col-10 col-sm-8  col-md-6   mt-5">
            <Card
              className="headCards"
              style={{ textAlign: "", marginTop: "15px" }}
            >
              <Card.Body>
                <Card.Title>
                  <div className=" text-capitalize">
                    <div className="float-start">
                      <img width={"70px"} src={cashoutimg} />
                    </div>
                  </div>
                  <h4 className="text-primary text-center">
                    €{balance} {"   "}${usdbalance}
                  </h4>{" "}
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                  <div className="container ">
                    <form
                      className="border rounded p-5  bg-light text-dark"
                      onSubmit={handleSubmit}
                    >
                      <h5 className="text-center  ">Create Request</h5>
                      <fieldset></fieldset>
                      <b>chose cashout Payment way</b> <br />
                      <select
                        class="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={handleCashoutMethod}
                        value={cashoutMethod}
                      >
                        <option selected value={"banktransfer"}>
                          Bank Transfer
                        </option>
                        <option value={"cryptocurrency"}>cryptocurrency</option>
                      </select>
                      {cashoutMethod === "cryptocurrency" ? (
                        <p className="text-danger">
                          * Note That cashout Amount with CryptoCurrency is
                          Taken from <b>usd balance</b>
                        </p>
                      ) : (
                        <p className="text-danger">
                          * Note That cashout Amount with Bank is Taken from{" "}
                          <b>Euro balance</b>
                        </p>
                      )}
                      <fieldset>
                        <input
                          placeholder="Amount to be cashe out in EURO"
                          type="number"
                          className="form-control mt-3"
                          onChange={handleAmount}
                          tabindex="1"
                          min={1}
                          value={cashoutAmount}
                          required
                          autofocus
                        />
                      </fieldset>
                      {cashoutBank ? (
                        <>
                          <input
                            type="text"
                            value={cashoutbankname}
                            onChange={handlecashoutbankname}
                            placeholder="Enter Your name"
                            className="form-control mt-3"
                          />
                          <input
                            type="text"
                            value={cashoutbankiban}
                            onChange={handlecashoutbankiban}
                            placeholder="Enter IBAN"
                            className="form-control mt-3"
                          />
                        </>
                      ) : (
                        <>
                          <div className=" mt-1">
                            *<b>USDT(TRC20)</b>
                          </div>

                          <div className=" fw-bolder   text-center">
                            {/* <button className='btn btn-outline-primary w-50 mt-5' onClick={()=>{convert()}}>Convert</button> */}
                            <h2 className="text-success mt-2">
                              ${cashoutAmount}
                            </h2>
                          </div>
                          <input
                            type="text"
                            placeholder="wallet Address"
                            value={cashoutcryptonumber}
                            onChange={handlecashoutcryptonumber}
                            className="form-control mt-1"
                          />
                        </>
                      )}
                      <fieldset className="mt-5">
                        <Checkbox
                          required
                          checked={checked}
                          disabled={disabled}
                          onChange={onChange}
                        >
                          <b className="text-primary">
                            {" "}
                            I have read the privacy policy and accept them
                          </b>
                        </Checkbox>
                      </fieldset>
                      <button
                        // disabled={disabledC === true}
                        name="submit"
                        type="submit"
                        className="btn btn-outline-dark"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                      <br /> <br />
                      {/* <p><b className="text-danger">*</b> our Fees is {cashoutfees} After Paypal Fees</p> */}
                    </form>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default SPCacheOut;
