
import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../css/history.css"
import { Pagination } from 'antd';
import {useState}from 'react'
import LocalSearch from "../../search/localsearch";
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input, Space } from 'antd';

import { useEffect } from "react";
import { GETORDERS } from "../../../function/orders";
import { useSelector } from "react-redux";
import { GETCUSTOMERS, GETCUSTOMERSCOUNT } from "../../../function/customer";
 import customer from '../../images/customer.png'
 import {CloudDownloadOutlined,EyeOutlined}from '@ant-design/icons';
const Customers=()=>{
    const [page, setPage] = useState(1)
      const[customersCount,setCustomersCount]=useState()
      const [keyword, setKeyword] = useState("")
const search = (keyword) => (c) => c._id.includes(keyword)
      const [customers,setCustomers]=useState([])
  let { user } = useSelector((state) => ({ ...state }));
    useEffect(()=>{
load()
  },[page])

  const load=()=>{
      GETCUSTOMERS(user.token,'createdAt', 'desc', page).then((res)=>{
 setCustomers(res.data)
 console.log("emails",res.data)
})
    
  GETCUSTOMERSCOUNT(user.token).then((res)=>{
  
   setCustomersCount(res.data)
  })
  }


    return(<>
   <div className="row mt-5 p-2">
  {/* <OldTable/> */}
 <div className="container">
        <div clasName="row">
          <div className="col-12">
            <div className="card servicceProvidersCard table-responsive">
              <div className="card-header">
                <div className="row">
                  <div className="col-5">
<div ><img width={'70px'}  src={customer} />
                <b className="h4 ms-2">Customers</b>
              
                </div>
                  </div>
                  <div className="col-5">
<div className="mt-2"><LocalSearch placeholder="search By Name"  keyword={keyword} setKeyword={setKeyword} /></div>
                  </div>
                  <div className="col-2">
 <div className="float-end me-auto"> <button className=" btn btn-light-info h5">
                <CloudDownloadOutlined style={{ fontSize: '20px'}} />  Export</button></div>
                  </div>
                </div>
                
              </div>
            
              <table className="serviceTable table  ">
                 <thead className="">
      <tr >
            <th>#</th>
    
         
          
    
            <th >Email</th>
    
            <th className="text-center">Action</th>
        </tr>
       
        </thead>
         <tbody >
          
        {/* {JSON.stringify(customers)} */}
         
            {customers?customers.filter(search(keyword)).map((email,index)=>
            <tr> 
         
       
     <td>{index+1}</td>
       <td>{email._id}</td>
          
  
           
            <td>
               <Link className='bg-light ' to={`/admin/Customerorders/${email}`}>
                    <button className="btn btn-light  ">Orders</button></Link> 
            
            </td>
        </tr>    
            ):'no data for now'}
        </tbody>
              </table>
              <div className="d-flex justify-content-center">
                        <Pagination current={page} total={Math.round((customersCount / 12) * 10)} onChange={(value) => setPage(value)} />

            </div>
            </div>
          </div>
        </div>
      </div>
</div>
    
    </>)


}
 
export default Customers