import axios from "axios";

export const UPDATESTATUS = async (authtoken, email, accountstatus) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/serviceprovider/status`,
    { email, accountstatus },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const UPDATEROLE = async (authtoken, email, role) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/serviceprovider/role`,
    { email, role },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const UPDATEFEES = async (authtoken, email, fees) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/serviceprovider/fees`,
    { email, fees },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const UPDATESPLEVEL = async (authtoken, email, splevel) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/serviceprovider/splevel`,
    { email, splevel },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const GETSPTOTALBALANCE = async (authtoken) => {
    return await axios.get(`${process.env.REACT_APP_API}/userbalance`,{
       headers: { 
            authtoken 
        } 
    })
}
////user/totalex


export const UPDATEBALANCE = async (authtoken,id,balanceType) => {
    return await axios.put(`${process.env.REACT_APP_API}/serviceprovider/updatebalance`, {id,balanceType},{
        headers:{
            authtoken
        }
    } )
}