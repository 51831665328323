import axios from "axios";
export const createOrder = async (orderDetails) =>
  await axios.post(`${process.env.REACT_APP_API}/order`, { orderDetails }, {});
export const createTransActionOrder = async (
  orderDetails,
  selectedBankAccount
) =>
  await axios.post(
    `${process.env.REACT_APP_API}/ordert`,
    { orderDetails, selectedBankAccount },
    {}
  );
export const GETORDERS = async (authtoken, sort, order, page,keyword) =>
  await axios.post(
    `${process.env.REACT_APP_API}/orderwp`,
    {
      sort,
      order,
      page,keyword
    },
    {
      headers: {
        authtoken,
      },
    }
  );
export const GETORDERSCOMPLETED = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/order/completed`, {
    headers: {
      authtoken,
    },
  });
export const GETORDERSONPROCCESS = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/order/onproccess`, {
    headers: {
      authtoken,
    },
  });
export const GETORDERSRejected = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/order/rejected`, {
    headers: {
      authtoken,
    },
  });
export const GETLAST = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/order/last`, {
    headers: {
      authtoken,
    },
  });
export const GETBO = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/order/bank`, {
    headers: {
      authtoken,
    },
  });
export const GETCO = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/order/card`, {
    headers: {
      authtoken,
    },
  });
export const GETPO = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/order/paypal`, {
    headers: {
      authtoken,
    },
  });
export const GETTOTALBANKPURCHASE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/order/totalbankpurchase`, {
    headers: {
      authtoken,
    },
  });
export const GETTOTALDOLLARBANKPURCHASE = async (authtoken) =>
  await axios.get(
    `${process.env.REACT_APP_API}/order/totaldollarbankpurchase`,
    {
      headers: {
        authtoken,
      },
    }
  );
export const GETTOTALCARDPURCHASE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/order/totalcardpurchase`, {
    headers: {
      authtoken,
    },
  });

export const GETTOTALPAYPALPURCHASE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/order/totalpaypalpurchase`, {
    headers: {
      authtoken,
    },
  });

export const getorderscount = async () =>
  await axios.get(`${process.env.REACT_APP_API}/order/total`, {});

export const getsporderscount = async (publicid) =>
  await axios.get(`${process.env.REACT_APP_API}/order/total/${publicid}`, {});

// 5-nov GETUSPORDERS = async (authtoken, publicid) to GETUSPORDERS = async (authtoken)
export const GETUSPORDERS = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/orders`, {
    headers: {
      authtoken,
    },
  });
};

export const GETSPLASTORDER = async (authtoken, publicid) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/order/lastsporder/${publicid}`,
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const GETUSPORDERSCOUNT = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/order/total`, {
    headers: {
      authtoken,
    },
  });
};

export const UPDATORDER = async (
  authtoken,
  _id,
  name,
  phone,
  address,
  note
) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/order/update`,
    { _id, name, phone, address, note },
    {
      headers: {
        authtoken,
      },
    }
  );
};

// add function to add otp
// export const GETORDERSTATUS = async (email) => {
//   return await axios.post(`${process.env.REACT_APP_API}/orderstatuscheck/${email}`);
// };

export const CREATEOTP = async (email) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API}/createotpfororderstatuscheck`,
    { email }
  );
  console.log(response);
  return response;
};

// update this function to be like the following it was like the previouse one
export const GETORDERSTATUS = async (email, otp) => {
  return await axios.post(`${process.env.REACT_APP_API}/orderstatuscheck`, {
    newEmail: email,
    otp: otp,
  });
};

export const UPDATEORDERSTATUS = async (authtoken, _id, status) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/order/updatestatus`,
    { _id, status },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const CONFIRMORDER = async (_id, image) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/uploadImage`,
    { image },
    {
      headers: {
        _id,
      },
    }
  );
};
export const GETSPCOMPLETEDORDRES = async (authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/order/spcompletedorders`,
    {
      headers: {
        authtoken,
      },
    }
  );
};
export const GETSPNOTCPMPLETEDORDERS = async (authtoken, publicid) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/order/spnotcompletedorders/${publicid}`,
    {
      headers: {
        authtoken,
      },
    }
  );
};

// 5-nov GETREJECTEDORDERS(authtoken, publicId) to GETREJECTEDORDERS = async (authtoken)
export const GETREJECTEDORDERS = async (authtoken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/order/sprejectedorders`,
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const GETONEORDER = async (authtoken, _id) => {
  return await axios.get(`${process.env.REACT_APP_API}/oneorder/${_id}`, {
    headers: {
      authtoken,
    },
  });
};
export const INVOICEORDER = async (authtoken, _id) => {
  return await axios.get(`${process.env.REACT_APP_API}/invoiceorder/${_id}`, {
    headers: {
      authtoken,
    },
  });
};
export const GETTOTALDOLLARPAYPALPURCHASEAF = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/totaldppapf`, {
    headers: {
      authtoken,
    },
  });
export const GETTOTALPAYPALPURCHASEAF = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/totalppapf`, {
    headers: {
      authtoken,
    },
  });
export const GETTOTALCARDPURCHASEAF = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/totalcpasf`, {
    headers: {
      authtoken,
    },
  });
export const GETNETTOTALAF = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/nettotalaf`, {
    headers: {
      authtoken,
    },
  });
export const GETWITHOLDINGBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/withholdingbalance`, {
    headers: {
      authtoken,
    },
  });
export const GETCASHOUTFEESBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/cashoutfeesbalance`, {
    headers: {
      authtoken,
    },
  });
export const GETDollarCASHOUTFEESBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/dollarcashoutfeesbalance`, {
    headers: {
      authtoken,
    },
  });
export const GETTRANSACTIONFEESBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/transactionfeesbalance`, {
    headers: {
      authtoken,
    },
  });
export const GETDOLLARTRANSACTIONFEESBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/dollartransactionfeesbalance`, {
    headers: {
      authtoken,
    },
  });
export const GETPAYPALFEESBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/paypalfeesbalance`, {
    headers: {
      authtoken,
    },
  });

export const GETDOLLARPAYPALFEESBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/dollarpaypalfeesbalance`, {
    headers: {
      authtoken,
    },
  });

export const getcardfeesbalance = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/cardfeesbalance`, {
    headers: {
      authtoken,
    },
  });
export const GETBANKTRANSFERFEESBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/bantransferfeesbalance`, {
    headers: {
      authtoken,
    },
  });

export const GETDOLLARBANKTRANSFERFEESBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/dollarbantransferfeesbalance`, {
    headers: {
      authtoken,
    },
  });
export const GETPAYPALBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/getpaypalbalance`, {
    headers: {
      authtoken,
    },
  });
export const GETPAYPALDOLLARBALANCE = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/getpaypaldollarbalance`, {
    headers: {
      authtoken,
    },
  });
export const getbanktrasnferbalance = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/getbanktreansferbalance`, {
    headers: {
      authtoken,
    },
  });
export const getdollarbanktrasnferbalance = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/getdollarbanktrasnferbalance`, {
    headers: {
      authtoken,
    },
  });
export const getcreditibalance = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/getcreditbalance`, {
    headers: {
      authtoken,
    },
  });

export const getcreditdollarbalance = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/getcreditdollarbalance`, {
    headers: {
      authtoken,
    },
  });
export const getcryptobalance = async (authtoken) =>
  await axios.get(`${process.env.REACT_APP_API}/getcryptobalance`, {
    headers: {
      authtoken,
    },
  });

export const confirmwithoutimg = async (_id) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/order/confirmwithoutimg`,
    { _id },
    {}
  );
};

export const createandupdaterejectreason = async (
  authtoken,
  _id,
  rejectreason
) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/rejectreason`,
    { _id, rejectreason },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const SENDCODES = async (authtoken, _id) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/sendcodes/${_id}`,

    {
      headers: {
        authtoken,
      },
    }
  );
};

export const MAKEPAYMENT = async (amount, orderDetails) => {
  return await axios.post(`${process.env.REACT_APP_API}/careatepayment`, {
    amount,
    orderDetails,
  });
};

export const MEPSRESPONSE = async () => {
  return await axios.post(`${process.env.REACT_APP_API}/mepsresonse`, {});
};
