import React, { useState } from "react";
import "../../css/dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import davatar from "../../images/davatar.png";
import { GETUSER, SERVICEPROVIDERDASHBOARDINFO } from "../../../function/auth";
import { CgProfile } from "react-icons/cg";
import {
  GETREJECTEDORDERS,
  GETSPCOMPLETEDORDRES,
  GETSPLASTORDER,
  GETSPNOTCPMPLETEDORDERS,
  getsporderscount,
} from "../../../function/orders";
import { GETDEFAULTFEES } from "../../../function/settings";
import { GETSPCUSTOMERSCOUNT } from "../../../function/customer";
import {
  GETSPCASHOUTREQCOUNT,
  GETSPLASTCASHOUTREQUEST,
} from "../../../function/cashout";
import {
  GETSPLASTTRANSACTION,
  GETSPTRANSCOUNT,
} from "../../../function/transaction";
import Button from "react-bootstrap/Button";
import cashoutimg from "../../images/cashoutimg.png";
import ordersimg from "../../images/ordersimg.png";
import transferimg from "../../images/transferimg.png";
import SpChart from "./spchart";
import Credit from "../credit";
import customer from "../../images/customer.png";
import walletimg from "../../images/walletimg.png";
import moment from "moment";
import { AiOutlineSetting } from "react-icons/ai";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Divider, Tooltip } from "antd";
import { BiLogOut } from "react-icons/bi";
import firebase from "firebase/compat/app";
import { useDispatch } from "react-redux";
const SPDashboard = () => {
  const [customers, setCustomers] = useState();
  const [spOrdesrCount, setSpOrdersCount] = useState();
  const [defaultFees, setDefaultFees] = useState();
  const [fees, setFees] = useState();
  const [transAction, setTransAction] = useState();
  const [cashOut, setCashOut] = useState();
  const [balance, setBalance] = useState();
  const [lastOrder, setLastOrder] = useState("");
  const [lastCash, setLastCash] = useState([]);
  const [completedOrders, setCompletedOrders] = useState();
  const [notCompletedOrders, setNotCompletedOrders] = useState();
  const [rejectedOrders, setRejectedOrders] = useState();
  const [sender, setSender] = useState();
  const [receiver, setReceiver] = useState();
  const [receivedTransAction, setReceivedTransaction] = useState([]);
  const [sentTransAction, setSentTransAction] = useState([]);
  const [usdbalance, setUsdbalance] = useState();
  let { user } = useSelector((state) => ({ ...state }));
  let publicid = user.pid;
  let resllerid = user.pid;
  useEffect(() => {
    LoadUser();
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    firebase.auth().signOut();
    dispatch({
      type: "LOGE_OUT",
      payload: null,
    });

    navigate("/login");
  };
  const LoadUser = () => {
    // GETSPLASTTRANSACTION(user.token, user._id).then((res) => {
    //  I have updated this one to become like the following send token as parameter
    GETSPLASTTRANSACTION(user.token).then((res) => {
      console.log("res.data finSpTransReciver", res.data);
      setReceivedTransaction(res.data.finSpTransReciver);
      {
        res.data.finSpTransReciver.map((ftr) => {
          setSender(ftr.sender);
        });
      }
      setSentTransAction(res.data.finSpTransSender);
      {
        res.data.finSpTransSender.map((fts) => {
          setReceiver(fts.receiver);
        });
      }
      // console.log("res.data",res.data)
    });

    // GETSPNOTCPMPLETEDORDERS(user.token, publicId).then((res) => {
    //5-nov I have updated this call here to become like the following
    GETSPNOTCPMPLETEDORDERS(user.token).then((res) => {
      setNotCompletedOrders(res.data);
    });

    //GETREJECTEDORDERS (user.token, publicid).then((res) => {
    //5-nov I have updated this call here to become like the following
    GETREJECTEDORDERS(user.token).then((res) => {
      setRejectedOrders(res.data);
    });

    //GETSPCOMPLETEDORDRES(user.token, publicId).then((res) => {
    // 5-nov I have updated this call here to become like the following
    GETSPCOMPLETEDORDRES(user.token).then((res) => {
      setCompletedOrders(res.data);
    });
    GETSPLASTCASHOUTREQUEST(user.token).then((res) => {
      setLastCash(res.data);
    });
    GETSPLASTORDER(user.token, publicid).then((res) => {
      setLastOrder(res.data);
    });
    // console.log(publicid)

    //  I have updated this call here from GETUSER(publicid) to SERVICEPROVIDERDASHBOARDINFO(token)
    SERVICEPROVIDERDASHBOARDINFO(user.token).then((res) => {
      console.log(res.data);
      setBalance(res.data.balance.toFixed(2));
      setUsdbalance(res.data.usdbalance.toFixed(2));
      setFees(res.data.fees);
    });

    getsporderscount(publicid).then((res) => {
      setSpOrdersCount(res.data);
    });

    GETDEFAULTFEES(user.token).then((res) => {
      res.data.map((df) => {
        setDefaultFees(df.defaultfees);
      });
    });
    GETSPCASHOUTREQCOUNT(user.token, user._id).then((res) => {
      setCashOut(res.data);
    });

    GETSPCUSTOMERSCOUNT(user.token, resllerid).then((res) => {
      setCustomers(res.data);
    });

    // GETSPTRANSCOUNT(user.token, user._id).then((res) => {
    //5-nov I updated this function to become like the following
    GETSPTRANSCOUNT(user.token).then((res) => {
      setTransAction(res.data);
    });
  };
  return (
    <>
      <div className="container-fluid admindashboard">
        <h1 className="pt-5 text-dark">Dashboard</h1>
        <div className="row">
          <div className="col-md-4 col-12">
            <Card
              className="headCards"
              style={{ textAlign: "", marginTop: "15px" }}
            >
              <Card.Body>
                <Card.Title>
                  <div className=" text-capitalize">
                    {user.name}{" "}
                    <div className="float-end">
                      <img width={"100px"} src={davatar} />
                    </div>
                  </div>{" "}
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                  <div className="container ">
                    <div className=" pt-1">
                      {user.email}
                      <br />
                      <small> publicid {user.pid} </small>
                      <br />
                      <small> SP Level {user.splevel} </small>
                    </div>

                    <div className=" mt-3">
                      <Link to="/profile">
                        {" "}
                        <CgProfile className="h3 ms-3 text-primary" />
                      </Link>
                      <BiLogOut
                        style={{ cursor: "pointer" }}
                        onClick={logout}
                        className="h3 text-danger ms-3"
                      />
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4 col-12">
            <Card
              className="headCards"
              style={{ textAlign: "", marginTop: "15px" }}
            >
              <Card.Body>
                <Card.Title>
                  <div className="float-start">
                    <img width={"70px"} src={walletimg} />
                  </div>
                  <div className=" text-capitalize">
                    My Wallet /<br /> Euro Balance:
                    <b className="text-primary">€{balance}</b> <br />
                  </div>
                  <div className=" text-capitalize mt-1">
                    USD Balance:
                    <b className="text-primary">${usdbalance}</b> <br />
                  </div>
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                  <div className="p-4">{/* <Credit/> */}</div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card
              className="headCards"
              style={{ textAlign: "", marginTop: "15px" }}
            >
              <Card.Body>
                <Card.Title>
                  <div className=" text-capitalize">
                    Customers{" "}
                    <div className="float-end">
                      <img width={"70px"} src={customer} />
                    </div>
                  </div>{" "}
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                  <div className="container ">
                    <h5 className=" mt-1">
                      Total :{" "}
                      <b className="text-primary">
                        {JSON.stringify(customers)}
                      </b>
                    </h5>

                    <div className=" mt-3">
                      <h6>
                        last purchase:
                        {lastOrder ? (
                          <b className="text-dark">
                            {moment(lastOrder).format("D/M/Y h:mm A")}
                          </b>
                        ) : (
                          "no data for now"
                        )}
                      </h6>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-5">
            <Card
              className="OrdersSummary "
              style={{ textAlign: "", marginTop: "15px" }}
            >
              <Card.Body>
                <Card.Title>
                  <div className=" text-capitalize">
                    Orders Summary{" "}
                    <div className="float-end">
                      <img width={"100px"} src={ordersimg} />
                    </div>
                  </div>{" "}
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                  <div className="container ">
                    <h5 className=" mt-1">
                      Total Orders:{" "}
                      <b className="text-primary">{spOrdesrCount}</b>
                    </h5>
                    <hr className="text-danger w-50" />

                    <h6>
                      {" "}
                      Completed Orders:{" "}
                      <b className="text-primary">{completedOrders || 0}</b>
                    </h6>
                    <h6>
                      {" "}
                      Not Completed Orders:{" "}
                      <b className="text-primary">{notCompletedOrders || 0}</b>
                    </h6>
                    <h6>
                      {" "}
                      Rejected Orders:{" "}
                      <b className="text-primary">{rejectedOrders || 0}</b>
                    </h6>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-5">
            <Card
              className="primaryCard"
              style={{ textAlign: "", marginTop: "15px" }}
            >
              <Card.Body>
                <Card.Title>
                  <div className=" text-capitalize">
                    Cashout Requests <b className="text-primary"> {cashOut}</b>
                    <b className="text-primary">{/* {casheout} */}</b>{" "}
                    <div className="float-end">
                      <img width={"70px"} src={cashoutimg} />
                    </div>
                  </div>{" "}
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                  <div className="container ">
                    <div className=" mt-3">
                      <h5>Last Request</h5>
                    </div>
                    <div className="row">
                      {lastCash.length ? (
                        lastCash.map((lc) => {
                          return (
                            <>
                              <div className="">
                                <Avatar
                                  style={{
                                    color: "#f56a00",
                                    backgroundColor: "#fde3cf",
                                  }}
                                  size="large"
                                >
                                  {user.name.slice(0, 1)}
                                </Avatar>{" "}
                                <b>{user.name}</b>
                                <b className="ms-2 me-3 text-success">
                                  {lc.currency === "usd" ? "$" : "€"}
                                  {lc.totalamount}
                                </b>{" "}
                                Req Date :
                                <b className=" text-danger">
                                  {moment(lc.createdAt).format("D/M/Y h:mm A")}
                                </b>
                                <h6 className="ms-5">
                                  Status:{" "}
                                  <b
                                    className={
                                      lc.requeststatus === "Completed"
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {lc.requeststatus}
                                  </b>
                                </h6>
                              </div>
                              <div></div>
                            </>
                          );
                        })
                      ) : (
                        <div>no data for now</div>
                      )}
                    </div>
                    <div className="row mt-2 ">
                      <div className="d-flex justify-content-center">
                        {/* <Link to="/cacheout">  <Button  className='btn btn-light'>Details</Button>
                  </Link> */}
                      </div>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row"></div>
        <div className="row">
          <div className="col-md-7">
            <Card
              className="headCards"
              style={{ textAlign: "", marginTop: "15px" }}
            >
              <Card.Body>
                <Card.Title>
                  <div className=" text-capitalize text-center">
                    TransActions
                    <b className="text-primary">
                      {/* {transActionsCount} */}
                    </b>{" "}
                    <div className="float-end">
                      <img width={"120px"} src={transferimg} />
                    </div>
                  </div>{" "}
                </Card.Title>

                <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                <Card.Text>
                  <div className="container ">
                    <h6 className="text-dark  "> Last Sent Transfer</h6>
                    {sentTransAction.length ? (
                      sentTransAction.map((st) => {
                        return (
                          <>
                            <div className="col-6">
                              <h6 className="text-danger">Sender</h6>
                              <Avatar
                                style={{
                                  color: "#f56a00",
                                  backgroundColor: "#fde3cf",
                                }}
                                size="large"
                              >
                                {st.sender?.name.slice(0, 1)}
                              </Avatar>{" "}
                              <b>{st.sender?.name}</b>{" "}
                              <b className="ms-2 me-3 text-success">
                                {st.currency === "usd" ? "$" : "€"}
                                {st.sentamount}
                              </b>{" "}
                              Req Date :
                              <b className=" text-danger">
                                {moment(st.createdAt).format("D/M/Y h:mm A")}
                              </b>
                            </div>

                            <div className="col-6">
                              <h6 className="text-primary">Reciever</h6>
                              <Avatar
                                style={{
                                  color: "#f56a00",
                                  backgroundColor: "#fde3cf",
                                }}
                                size="large"
                              >
                                {st.receiver?.name.slice(0, 1)}
                              </Avatar>{" "}
                              <b>{st.receiver?.name}</b>{" "}
                              <b className="ms-2 me-3 text-success">
                                {st.currency === "usd" ? "$" : "€"}{" "}
                                {st.receivedamount}
                              </b>{" "}
                              Status :
                              <b className=" text-success">
                                {st.requeststatus}
                              </b>
                            </div>
                            <div></div>
                          </>
                        );
                      })
                    ) : (
                      <div>no data for now</div>
                    )}

                    <div className="row">
                      <h6>Last Received Transfer</h6>
                      {receivedTransAction.map((st) => {
                        return (
                          <>
                            <div className="col-6">
                              <h6 className="text-danger">Sender</h6>
                              <Avatar
                                style={{
                                  color: "#f56a00",
                                  backgroundColor: "#fde3cf",
                                }}
                                size="large"
                              >
                                {st.sender.name.slice(0, 1)}
                              </Avatar>{" "}
                              <b>{st.sender.name}</b>{" "}
                              <b className="ms-2 me-3 text-success">
                                {st.sentamount}
                              </b>{" "}
                              Req Date :
                              <b className=" text-danger">
                                {moment(st.createdAt).format("D/M/Y h:mm A")}
                              </b>
                            </div>

                            <div className="col-6">
                              <h6 className="text-primary">Reciever</h6>
                              <Avatar
                                style={{
                                  color: "#f56a00",
                                  backgroundColor: "#fde3cf",
                                }}
                                size="large"
                              >
                                {st?.receiver?.name.slice(0, 1)}
                              </Avatar>{" "}
                              <b>{st?.receiver?.name}</b>{" "}
                              <b className="ms-2 me-3 text-success">
                                {st.receivedamount}
                              </b>{" "}
                              Status :
                              <b className=" text-success">
                                {st.requeststatus}
                              </b>
                            </div>
                            <div></div>
                          </>
                        );
                      })}
                    </div>
                    <div className="row mt-3 ">
                      <div className="d-flex justify-content-center">
                        <Link to="/serviceprovider/history">
                          {" "}
                          <Button className="btn btn-light">Details</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row"></div>
      </div>
    </>
  );
};
export default SPDashboard;
