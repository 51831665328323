import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GETSPLOGS, getlogscount, getsplogscount } from "../../../function/adminlogs";
import { Table, Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { Pagination } from "antd";
import Loader from "../../loader";

const SpLogs = () => {

    const {_id}=useParams()
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const { user } = useSelector((state) => ({ ...state }));
  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
const [sp,setSp]=useState('')
  const Loade = () => {
    getsplogscount(_id).then((res) => {
      setCount(res.data);
    });
    GETSPLOGS(user.token, _id,"createdAt", "desc", page).then((res) => {
      setLogs(res.data);
    

      {res.data.map((r)=>{
        console.log(r.user.name)
                setSp(r.user.name)
      })}
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };
  useEffect(() => {
    Loade();
    //get all logs and save them into contant
  }, [page]);
  return (
    <>
      {/* {JSON.stringify(logs)} */}
      {/* {JSON.stringify(_id)} */}
      {loading && <Loader />}
      {!loading && (
        <Container className="container-fluid mb-5">
          <Row className="mt-5">
            <Col>
              <h1 className="text-center text-primary">{sp} Logs</h1>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Date</th>
                
                    <th>Action</th>
                    <th className="text-center text-danger"> Description</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log) => (
                    <tr key={log._id}>
                      <td>{log.uniqueid}</td>
                      <td>
                        <b className="text-dark">
                          {moment(log.createdAt).format("D/M/Y h:mm:ss A")}
                        </b>
                      </td>

                     
                      <td>{log.action}</td>
                      <td className="text-dark fw-6">{log.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-center">
                <Pagination
                  current={page}
                  total={Math.round((count / 12) * 10)}
                  onChange={(value) => setPage(value)}
                />
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
export default SpLogs;
