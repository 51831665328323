import axios from "axios";
export const CREATEOOSPB = async (authtoken, _id, operationamount, note) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/createoospb`,
    {
      _id,
      operationamount,
      note,
    },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const CREATEOOSPDB = async (authtoken, _id, operationamount, note) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/createoospdb`,
    {
      _id,
      operationamount,
      note,
    },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const GETOOSPB = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/oospb`, {
    headers: {
      authtoken,
    },
  });
};

/* ----ahmad I have updated this function to become like this it
 was send the id in the header now we actaully no need to send id at all
*/
export const GETONEOOSPB = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/oneoospb`, {
    headers: {
      authtoken,
    },
  });
};

// export const GETONEOOSPB = async (authtoken, _id) => {
//   return await axios.get(`${process.env.REACT_APP_API}/oneoospb`, {
//     headers: {
//       authtoken,
//       _id,
//     },
//   });
// };
